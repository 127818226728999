export const DeletePageFlags = {
  SELF_ONLY: 'self_only',
  SELF_WITH_CHILDREN: 'with_children',
  SELF_WITH_PRODUCT: 'with_products',
  SELF_WITH_CHILDREN_AND_PRODUCTS: 'with_children_and_products',
};

export const AttributeFilterType = {
  INCLUDE: 'in',
  NOT_INCLUDE: 'nin',
  EQUAL: 'eq',
  NOT_EQUAL: 'neq',
  MORE_THAN: 'mth',
  LESS_THAN: 'lth',
  EXIST: 'exs',
  NOT_EXIST: 'nexs',
  PATTERN: 'pat',
  SAME: 'same',
};

export const SearchType = {
  PRODUCTS: 'products',
  PAGES: 'pages',
  BLOCKS: 'blocks',
};

export const filterCondition = (translations) => [
  {
    id: 1,
    sign: '1 A @',
    label: translations.includes,
    value: AttributeFilterType.INCLUDE,
  },
  {
    id: 2,
    sign: '1 A @',
    label: translations.not_includes,
    value: AttributeFilterType.NOT_INCLUDE,
  },
  { id: 3, sign: '1 A =', label: translations.equals, value: AttributeFilterType.EQUAL },
  {
    id: 4,
    sign: '1 A ≠',
    label: translations.not_equals,
    value: AttributeFilterType.NOT_EQUAL,
  },
  { id: 5, sign: '1 2 3', label: translations.more, value: AttributeFilterType.MORE_THAN },
  { id: 6, sign: '1 2 3', label: translations.less, value: AttributeFilterType.LESS_THAN },
  {
    id: 7,
    sign: 'Σ √ %',
    label: translations.contains_any_value,
    value: AttributeFilterType.EXIST,
  },
  {
    id: 8,
    sign: 'Σ √ %',
    label: translations.not_contains_value,
    value: AttributeFilterType.NOT_EXIST,
  },
  {
    id: 9,
    sign: '* * *',
    label: translations.condition_pattern_value,
    value: AttributeFilterType.PATTERN,
  },
  {
    id: 10,
    sign: 'A = A',
    label: translations.same_value,
    value: AttributeFilterType.SAME,
  },
];
