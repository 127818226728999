import {
  post, get, put, remove
} from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';

export const FETCH_PRODUCT_STATUSES_SUCCESS = 'FETCH_PRODUCT_STATUSES_SUCCESS';
export const FETCH_PRODUCT_STATUSES_FAIL = 'FETCH_PRODUCT_STATUSES_FAIL';
export const OPERATION_PRODUCT_STATUSES_START = 'OPERATION_PRODUCT_STATUSES_START';
export const OPERATION_PRODUCT_STATUS_SUCCESS = 'OPERATION_PRODUCT_STATUS_SUCCESS';
export const OPERATION_PRODUCT_STATUS_FAIL = 'OPERATION_PRODUCT_STATUS_SUCCESS';

export const DELETE_PRODUCT_STATUS_SUCCESS = 'DELETE_PRODUCT_STATUS_SUCCESS';

export const fetchProductStatusesSuccess = (data) => ({
  type: FETCH_PRODUCT_STATUSES_SUCCESS,
  payload: data
});

const operationProductStatusesFail = (err) => ({
  type: OPERATION_PRODUCT_STATUS_FAIL,
  payload: err
});

const operationProductStatusesStart = () => ({
  type: OPERATION_PRODUCT_STATUSES_START,
});

const operationProductStatusesSuccess = () => ({
  type: OPERATION_PRODUCT_STATUS_SUCCESS
});

const deleteProductStatusesSuccess = (id) => ({
  type: DELETE_PRODUCT_STATUS_SUCCESS,
  payload: id
});

export const fetchProductsStatuses = () => async (dispatch) => {
  try {
    dispatch(operationProductStatusesStart());
    const result = await get('/product-statuses');
    dispatch(fetchProductStatusesSuccess(result.data));
  } catch (e) {
    dispatch(operationProductStatusesFail(e));
  }
};

export const addProductsStatus = (data, translations, setNewItemProcess) => async (dispatch) => {
  try {
    dispatch(operationProductStatusesStart());
    await post('/product-statuses', data);
    dispatch(fetchProductsStatuses());
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    setNewItemProcess((prev) => prev.filter((elId) => elId !== data.identifier));
    dispatch(operationProductStatusesSuccess());
  } catch (e) {
    dispatch(operationProductStatusesFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const deleteProductsStatus = (id, translations, setDeleteProcess) => async (dispatch) => {
  try {
    dispatch(operationProductStatusesStart());
    await remove(`/product-statuses/${id}`);
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
    dispatch(deleteProductStatusesSuccess(id));
  } catch (e) {
    dispatch(operationProductStatusesFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const updateProductsStatus = (data, translations) => async (dispatch) => {
  try {
    dispatch(operationProductStatusesStart());
    await put(`/product-statuses/${data.id}`, data);
    dispatch(operationProductStatusesSuccess());
    dispatch(fetchProductsStatuses());
    return true;
  } catch (e) {
    dispatch(operationProductStatusesFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const getProductStatusPage = (id) => async () => {
  try {
    const result = await get(`/product-statuses/${id}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};
