/* eslint-disable no-case-declarations */
import {
  ADD_USER_FAIL,
  ADD_USER_START,
  ADD_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  FETCH_USER_LIST_START,
  FETCH_USER_LIST_SUCCESS,
} from './action';

const initialState = {
  data: [],
  isLoading: true,
  isInitial: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_USER_LIST_START:
  case UPDATE_USER_START:
  case ADD_USER_START:
    return {
      ...state,
      isLoading: true
    };

  case FETCH_USER_LIST_SUCCESS:
    return {
      ...state,
      data: payload,
      isLoading: false,
      isInitial: true,
    };

  case ADD_USER_SUCCESS:
    return {
      ...state,
      data: [
        ...state.data,
        payload
      ],
      isLoading: false,
      isInitial: true,
    };
  case DELETE_USER_SUCCESS:
    return {
      ...state,
      data: state.data.filter((item) => item.id !== payload),
      isLoading: false,
      isInitial: true,
    };
  case UPDATE_USER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  case UPDATE_USER_FAIL:
  case ADD_USER_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  default:
    return { ...state };
  }
};
