/**
 * Имя сущности, которая содержит сущности с группами товаров
 */
export const defaultCategoryEntityName = 'Группы';
/**
 * Имя сущности, которая содержит сущность с группой товаров
 */
export const defaultOneCategoryEntityName = 'Группа';
/**
 * Имя сущности, которая содержит сущности со справочниками
 */
export const defaultPropertiesEntityName = 'Свойства';
/**
 * Имя сущности, которая содержит сущности со товарами
 */
export const defaultProductEntityName = 'Товары';
/**
 * Имя сущности, которая содержит сущности со предложениями цен
 */
export const defaultOfferEntityName = 'Предложения';
/**
 * Имя свойства, в котором содержится информация о наименовании
 */
export const defaultNodePropertyName = 'Наименование';
/**
 * Имя свойства, в котором содержится информация о значении
 */
export const defaultNodePropertyValueName = 'Значение';
/**
 * Имя свойства, в котором содержится информация об индентификаторе
 */
export const defaultNodePropertyIdName = 'Ид';
/**
 * Имя сущности, которая содержит сущности со справочниками
 */
export const defaultPropertiesListName = 'ЗначенияСвойств';
/**
 * Имя сущности, которая содержит сущности со реквизитами
 */
export const defaultRequisitesListName = 'ЗначенияРеквизитов';
/**
 * Имя сущности, которая содержит сущности со свойствами
 */
export const defaultPropertiesName = 'Свойства';
/**
 * Имя атрибута - ключа для товара
 */
export const defaultAttributeKeyName = 'Артикул';
/**
 * значение для других типов (общих)
 */
export const typeCommonValue = 91;
/**
 * значение для типа "список"
 */
export const typeListValue = 92;
/**
 * значение для типа "составной атрибут"
 */
export const typeCompoundValue = 93;
/**
 * значение для типа "чекбокс и радиобатон"
 */
export const typeRadioValue = 94;
/**
 * значение для типа "изображение и группа изображений"
 */
export const typeImageValue = 95;
/**
 * название категории для не найденных товаров
 */
export const defaultEmptyCategoryName = 'Товары без категории';
/**
 * Имя свойства, в котором содержится информация об изображении товара
 */
export const defaultNodePropertyImageName = 'Картинка';
/**
 * Имя свойства, в котором содержится информация о цене товара
 */
export const defaultNodePropertyPriceName = 'Цена';
/**
 * Имя свойства, в котором содержится информация о цен товаров
 */
export const defaultNodePropertyPricesName = 'Цены';
/**
 * Имя свойства, в котором содержится информация о цене за единицу
 */
export const defaultNodePropertyPricePerAmount = 'ЦенаЗаЕдиницу';
/**
 * Имя свойства, в котором содержится информация о валюте цены
 */
export const defaultNodePropertyPriceCurrency = 'Валюта';
/**
 * Имя свойства, в котором содержится информация о единице цены товара
 */
export const defaultNodePropertyPriceAmount = 'Единица';
/**
 * Имя свойства, в котором содержится информация о ценовом коэффициента
 */
export const defaultNodePropertyPriceRatio = 'Коэффициент';
/**
 * Маскимальное количество элементов значений в списке
 */
export const optionsLength = 100;
/**
 * Маскимальное количество обрабатываемых продуктов для получения optionsLength (для предпросмотра)
 */
export const maxProductIterationsPreview = 30;
/**
 * Маскимальное количество обрабатываемых продуктов для получения optionsLength (для импорта)
 */
export const maxProductIterationsImport = 1000;
/**
 * Разделитель для значений атрибутов
 */
export const attributeSchemaSeparator = '_id';
/**
 * Название атрибута для группы изображений
 */
export const attributeGroupOfImagesName = 'Картинки';
