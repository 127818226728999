import {
  SET_ATTRIBUTE_AS_CHANGING,
  UPDATE_CHANGING_ATTRIBUTES,
  MARK_ATTRIBUTES_SET_AS_CHANGING,
  UNMARK_ATTRIBUTES_SET_AS_CHANGING,
  SET_ATTRIBUTES_SET_CHANGING_STATUS,
  SET_PRODUCTS_INDEX_STATUS,
  SET_PRODUCTS_DELETE_STATUS,
  SET_IMPORT_CATALOG_STATUS,
  SET_DATA_INDEX_STATUS,
  SET_ADDING_NEW_DATA_STATUS,
  SET_UPLOADING_FILES_STATUS,
  SET_ADDING_NEW_ADMIN_STATUS,
  SET_IMPORT_SOMEONE_CATALOG_STATUS
} from './action';

const initialState = {
  attributesInSets: [],
  attributeSets: [],
  // индексация товаров
  isRunningIndex: false,
  // индексация данных
  isRunningDataIndex: false,
  isChangingAttributes: false,
  // импорт каталог
  isRunningImportCatalog: false,
  // импорт каталог
  isSomeoneRunningImportCatalog: false,
  // блокировки по операциям
  addingNewDataIsBlocked: false,
  uploadingFilesIsBlocked: false,
  addingNewAdminBlocked: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_ATTRIBUTE_AS_CHANGING:
    // eslint-disable-next-line no-case-declarations
    const c = state.attributesInSets.filter((c) => c.id !== payload?.id);
    return {
      ...state,
      attributesInSets: c.concat([payload])
    };
  case UPDATE_CHANGING_ATTRIBUTES:
    return {
      ...state,
      attributesInSets: payload
    };
  case MARK_ATTRIBUTES_SET_AS_CHANGING:
    return {
      ...state,
      attributeSets: payload && !state.attributeSets.includes(payload)
        ? state.attributeSets.concat([payload])
        : state.attributeSets
    };
  case UNMARK_ATTRIBUTES_SET_AS_CHANGING:
    return {
      ...state,
      attributeSets: payload && state.attributeSets.includes(payload)
        ? state.attributeSets.filter((c) => c !== payload)
        : state.attributeSets
    };
  case SET_ATTRIBUTES_SET_CHANGING_STATUS:
    return {
      ...state,
      isChangingAttributes: payload
    };
  case SET_PRODUCTS_INDEX_STATUS:
    return {
      ...state,
      isRunningIndex: payload
    };
  case SET_PRODUCTS_DELETE_STATUS:
    return {
      ...state,
      isRunningIndex: payload
    };
  case SET_DATA_INDEX_STATUS:
    return {
      ...state,
      isRunningDataIndex: payload
    };
  case SET_ADDING_NEW_DATA_STATUS:
    return {
      ...state,
      addingNewDataIsBlocked: payload
    };
  case SET_ADDING_NEW_ADMIN_STATUS:
    return {
      ...state,
      addingNewAdminBlocked: payload
    };
  case SET_UPLOADING_FILES_STATUS:
    return {
      ...state,
      uploadingFilesIsBlocked: payload
    };
  case SET_IMPORT_CATALOG_STATUS:
    return {
      ...state,
      isRunningImportCatalog: payload
    };
  case SET_IMPORT_SOMEONE_CATALOG_STATUS:
    return {
      ...state,
      isSomeoneRunningImportCatalog: payload
    };
  default:
    return { ...state };
  }
};
