/**
 * получение данных cookie
 *
 * @param {string} cName
 * @returns {*}
 */
export const getCookie = (cName) => {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(document.cookie); // to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};