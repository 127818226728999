import { get, post, remove } from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';
// eslint-disable-next-line import/no-extraneous-dependencies
import fileDownload from 'js-file-download';

export const FETCH_CERTS_START = 'FETCH_CERTS_START';
export const FETCH_CERTS_SUCCESS = 'FETCH_CERTS_SUCCESS';
export const FETCH_CERTS_FAIL = 'FETCH_CERTS_FAIL';

export const CREATE_CERT_START = 'CREATE_CERT_START';
export const CREATE_CERT_SUCCESS = 'CREATE_CERT_SUCCESS';
export const CREATE_CERT_FAIL = 'CREATE_CERT_FAIL';

export const DELETE_CERT_START = 'DELETE_CERT_START';
export const DELETE_CERT_SUCCESS = 'DELETE_CERT_SUCCESS';
export const DELETE_CERT_FAIL = 'DELETE_CERT_FAIL';

const createCertStart = () => ({
  type: CREATE_CERT_START,
});

const createCertSuccess = (payload) => ({
  type: CREATE_CERT_SUCCESS,
  payload,
});

const createCertFail = (error) => ({
  type: CREATE_CERT_FAIL,
  payload: error,
});

export const deleteCertStart = () => ({
  type: DELETE_CERT_START,
});

export const deleteCertSuccess = (payload) => ({
  type: DELETE_CERT_SUCCESS,
  payload,
});

export const deleteCertFail = (error) => ({
  type: DELETE_CERT_FAIL,
  payload: error,
});

const fetchCertsStart = () => ({
  type: FETCH_CERTS_START,
});

export const fetchCertsSuccess = (payload) => ({
  type: FETCH_CERTS_SUCCESS,
  payload,
});

const fetchCertsFail = (payload) => ({
  type: FETCH_CERTS_FAIL,
  payload,
});

export const createCert = (name, type, translations, setNewItemProcess = () => {}, silent = false) => async (dispatch) => {
  dispatch(createCertStart());
  try {
    const result = await post('/system/certs/generate', { name, type });
    if (result?.data?.id) {
      dispatch(createCertSuccess());
      dispatch(fetchAllCerts());
      if (!silent) {
        store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      }
      setNewItemProcess((prev) => prev.filter((elId) => elId !== name));
      return result.data.id;
    }
    setNewItemProcess((prev) => prev.filter((elId) => elId !== name));
    dispatch(createCertFail(result?.data));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  } catch (e) {
    dispatch(createCertFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const deleteCert = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteCertStart());
  try {
    await remove(`/system/certs/${id}`);
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
  } catch (e) {
    dispatch(deleteCertFail(e));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const downloadCert = (id) => async () => {
  try {
    get(`/system/certs/${id}`, { responseType: 'blob' })
      .then((response) => {
        const fileName = response.headers['file-name'] || 'certs.zip';
        fileDownload(response.data, fileName);
      });
  } catch (e) {
    console.error(e);
  }
};

export const fetchAllCerts = () => async (dispatch) => {
  dispatch(fetchCertsStart());
  try {
    const result = await get('/system/certs?type=content');
    dispatch(fetchCertsSuccess(result?.data || []));
  } catch (e) {
    dispatch(fetchCertsFail(e));
  }
};
