export const SET_ATTRIBUTE_AS_CHANGING = 'SET_ATTRIBUTE_AS_CHANGING';
export const UPDATE_CHANGING_ATTRIBUTES = 'UPDATE_CHANGING_ATTRIBUTES';
export const MARK_ATTRIBUTES_SET_AS_CHANGING = 'MARK_ATTRIBUTES_SET_AS_CHANGING';
export const UNMARK_ATTRIBUTES_SET_AS_CHANGING = 'UNMARK_ATTRIBUTES_SET_AS_CHANGING';

export const SET_ATTRIBUTES_SET_CHANGING_STATUS = 'SET_ATTRIBUTES_SET_CHANGING_STATUS';
export const SET_PRODUCTS_INDEX_STATUS = 'SET_PRODUCTS_INDEX_STATUS';
export const SET_PRODUCTS_DELETE_STATUS = 'SET_PRODUCTS_DELETE_STATUS';
export const SET_IMPORT_CATALOG_STATUS = 'SET_IMPORT_CATALOG_STATUS';
export const SET_IMPORT_SOMEONE_CATALOG_STATUS = 'SET_IMPORT_SOMEONE_CATALOG_STATUS';
export const SET_DATA_INDEX_STATUS = 'SET_DATA_INDEX_STATUS';

export const SET_ADDING_NEW_DATA_STATUS = 'SET_ADDING_NEW_DATA_STATUS';
export const SET_ADDING_NEW_ADMIN_STATUS = 'SET_ADDING_NEW_ADMIN_STATUS';
export const SET_UPLOADING_FILES_STATUS = 'SET_UPLOADING_FILES_STATUS';

export const setAttributeAsChanging = (payload) => ({
  type: SET_ATTRIBUTE_AS_CHANGING,
  payload
});

export const updateChangingAttributes = (payload) => ({
  type: UPDATE_CHANGING_ATTRIBUTES,
  payload
});

export const markAttributesSetAsChanging = (id) => ({
  type: MARK_ATTRIBUTES_SET_AS_CHANGING,
  payload: id,
});

export const unmarkAttributesSetAsChanging = (id) => ({
  type: UNMARK_ATTRIBUTES_SET_AS_CHANGING,
  payload: id,
});

export const setAttributesSetChangingStatus = (status) => ({
  type: SET_ATTRIBUTES_SET_CHANGING_STATUS,
  payload: status,
});

export const setProductsIndexStatus = (status) => ({
  type: SET_PRODUCTS_INDEX_STATUS,
  payload: status,
});

export const setProductsDeleteStatus = (status) => ({
  type: SET_PRODUCTS_DELETE_STATUS,
  payload: status,
});

export const setDataIndexStatus = (status) => ({
  type: SET_DATA_INDEX_STATUS,
  payload: status,
});

export const setImportCatalogWorkerStatus = (status) => ({
  type: SET_IMPORT_CATALOG_STATUS,
  payload: status,
});

export const setImportCatalogStatus = (status) => ({
  type: SET_IMPORT_SOMEONE_CATALOG_STATUS,
  payload: status,
});

export const setAddingNewDataStatus = (status) => ({
  type: SET_ADDING_NEW_DATA_STATUS,
  payload: status,
});

export const setAddingNewAdminStatus = (status) => ({
  type: SET_ADDING_NEW_ADMIN_STATUS,
  payload: status,
});

export const setUploadingFilesStatus = (status) => ({
  type: SET_UPLOADING_FILES_STATUS,
  payload: status,
});
