import {
  get,
} from '../../api';

export const FETCH_GET_AUTH_PERMISSIONS_START = 'FETCH_GET_AUTH_PERMISSIONS_START';
export const FETCH_GET_AUTH_PERMISSIONS_SUCCESS = 'FETCH_GET_AUTH_PERMISSIONS_SUCCESS';
export const FETCH_GET_AUTH_PERMISSIONS_FAIL = 'FETCH_GET_AUTH_PERMISSIONS_FAIL';

export const UPDATE_AUTH_PERMISSIONS_START = 'UPDATE_AUTH_PERMISSIONS_START';
export const UPDATE_AUTH_PERMISSIONS_SUCCESS = 'UPDATE_AUTH_PERMISSIONS_SUCCESS';
export const UPDATE_AUTH_PERMISSIONS_FAIL = 'UPDATE_AUTH_PERMISSIONS_FAIL';

const fetchGetAuthPermissionsStart = () => ({
  type: FETCH_GET_AUTH_PERMISSIONS_START
});

export const fetchGetAuthPermissionsSuccess = (data) => ({
  type: FETCH_GET_AUTH_PERMISSIONS_SUCCESS,
  payload: data
});

const fetchGetAuthPermissionsFail = (err) => ({
  type: FETCH_GET_AUTH_PERMISSIONS_FAIL,
  payload: err
});

const updateAuthPermissionsStart = () => ({
  type: UPDATE_AUTH_PERMISSIONS_START
});

export const fetchUpdateAuthPermissionsSuccess = (data) => {
  return {
    type: UPDATE_AUTH_PERMISSIONS_SUCCESS,
    payload: data
  };
};

const fetchUpdateAuthPermissionsFail = (err) => ({
  type: UPDATE_AUTH_PERMISSIONS_FAIL,
  payload: err
});

export const fetchGetAuthPermissions = (userId) => async (dispatch) => {
  dispatch(fetchGetAuthPermissionsStart());
  try {
    const result = await get(`/admins/${userId}`);
    dispatch(fetchGetAuthPermissionsSuccess(result.data.permissions));
  } catch (e) {
    dispatch(fetchGetAuthPermissionsFail(e));
  }
};

export const fetchUpdateAuthPermissions = (permissions) => async (dispatch) => {
  dispatch(updateAuthPermissionsStart());
  try {
    dispatch(fetchUpdateAuthPermissionsSuccess(permissions));
  } catch (e) {
    dispatch(fetchUpdateAuthPermissionsFail(e));
  }
};
