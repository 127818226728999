import React, {
  createContext, useContext, useEffect, useState, useRef
} from 'react';
import { io } from 'socket.io-client';
import {
  IS_DEV,
  IS_EMIT_VALUES,
  RAISE_ERROR_ON_EMPTY_INFORM_ID,
  SOCKET_URL,
} from '../constants/settings';
import { I18n } from 'i18n-js';
import EventEmitter from 'eventemitter3';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { fetchLogout } from '../redux/auth/action';
import { localesSelectors } from '../redux/locales/selectors';
import { getCookie } from '@utils/getCookie/getCookie';

const i18n = new I18n();
export const EE = new EventEmitter();

const AppContext = createContext({
  socket: null,
  isHighPing: false,
  isBrowserTabChanged: false,
  isRouterChanged: false,
  isConnected: undefined,
  lang: 'en_US',
  setLang: () => {},
  i18n,
  appId: undefined,
});

export const AppContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const appId = getCookie('appId');
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(undefined);
  const [isHighPing, setIsHighPing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cachedInterfaceLanguage, setCachedInterfaceLanguage] = useState(
    localStorage.getItem('activeLanguage')
  );
  const [sideBarRoutes, setSideBarRoutes] = useState([]);
  const [isBrowserTabChanged, setIsBrowserTabChanged] = useState(false);
  const pingLimit = parseInt(process.env.REACT_APP_WS_PING, 10);
  const [dir, setDir] = useState('ltr');
  const socketRef = useRef(null);
  const { translations } = i18n;
  // язык контента
  const lang = useSelector(localesSelectors.getSelectedLocale);

  const pingDetector = (msg) => {
    setIsHighPing(msg.ping <= pingLimit);
  };

  const setListeners = () => {
    socket.on('ping', pingDetector);
    socket.on('system', (msg) => {
      if (msg?.action === 'logout') {
        dispatch(fetchLogout());
        window.location = '/';
      }
    });
  };

  useEffect(() => {
    try {
      if (!IS_DEV) {
        // sort out error caused by zone.js
        // ./node_modules/zone.js/fesm2015/zone.js 44:11
        // Module parse failed: Unexpected token (44:11)
        // initializeGrafana();
      }
    } catch (e) {
      console.log(`Grafana connection error: ${e}`);
    }
    if (!socket) {
      const socketIo = io(SOCKET_URL, { transports: ['websocket'] });
      setSocket(socketIo);
    }
    if (
      localStorage.getItem('activeLanguage') === 'ae_AE'
      || localStorage.getItem('activeLanguage') === 'he_HE'
    ) {
      setDir('rtl');
    }
    if (localStorage.getItem('activeLanguage') === 'ar_AR') {
      setDir('rtl');
    }
    return () => {
      if (socket) {
        socket.removeAllListeners();
        socket.disconnect();
        socketRef.current = null;
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      // проверка пинга
      setInterval(() => {
        const start = Date.now();
        socket.volatile.emit('ping', { time: start });
      }, 60 * 1000);
      setListeners();
      // проверка индексации товаров
      socket.emit('checkIndexProducts');
      // отслеживаем соединение
      socket.on('connect', () => {
        setIsConnected(true);
      });
      socket.on('disconnect', () => {
        setIsConnected(false);
      });
      socketRef.current = socket;
    }
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      setIsBrowserTabChanged(document.visibilityState !== 'visible');
    });
    if (IS_EMIT_VALUES) {
      EE.on('keyup', (data) => {
        const {
          id, value, lang, appId
        } = data;
        if (socketRef.current && id) {
          socketRef.current.emit('attributeChangingValue', {
            id, value, lang, date: new Date(), appId
          });
        }
        // @todo: @deprecated soon
        if (!id && RAISE_ERROR_ON_EMPTY_INFORM_ID) {
          console.error('InformId пустой', data);
        }
      });
    }
    return () => {
      document.removeEventListener('visibilitychange');
      if (IS_EMIT_VALUES) {
        EE.removeAllListeners();
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AppContext.Provider
      value={{
        socket,
        isHighPing,
        isBrowserTabChanged,
        isConnected,
        lang,
        // setLang,
        setLoading,
        loading,
        i18n,
        translations,
        dir,
        setDir,
        cachedInterfaceLanguage,
        setCachedInterfaceLanguage,
        sideBarRoutes,
        setSideBarRoutes,
        appId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
