import {
  FETCH_ORDERS_START,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
  FETCH_ORDER_FAIL,
  FETCH_ORDER_START,
  FETCH_ORDER_SUCCESS,
  CREATE_ORDER_START,
  CREATE_ORDER_FAIL,
  UPDATE_ORDER_START,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_START,
  DELETE_ORDER_SUCCESS,
  CREATE_ORDER_SUCCESS,
} from './action';

const initialState = {
  list: [],
  selected: {},
  isLoading: true,
  isInitial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_ORDERS_START:
  case FETCH_ORDER_START:
  case CREATE_ORDER_START:
  case UPDATE_ORDER_START:
  case DELETE_ORDER_START:
    return {
      ...state,
      isLoading: true,
    };

  case FETCH_ORDERS_SUCCESS:
    return {
      ...state,
      list: payload,
      isLoading: false,
      isInitial: true,
    };

  case FETCH_ORDERS_FAIL:
  case FETCH_ORDER_FAIL:
  case CREATE_ORDER_FAIL:
  case UPDATE_ORDER_FAIL:
  case DELETE_ORDER_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };

  case FETCH_ORDER_SUCCESS:
    return {
      ...state,
      selected: payload,
      isLoading: false,
      isInitial: true,
    };

  case CREATE_ORDER_SUCCESS:
    return {
      ...state,
      list: [...state.list, { ...payload }],
      isLoading: false,
    };

  // case UPDATE_ORDER_SUCCESS:
  //   return {
  //     ...state,
  //     blocks: {
  //       ...state.blocks,
  //       items: state.blocks.items.map((item) => (item?.id === payload?.id ? { ...payload } : item)),
  //     },
  //     isLoading: false,
  //   };

  case DELETE_ORDER_SUCCESS:
    return {
      ...state,
      list: state.list.filter((o) => o.id !== payload),
      isLoading: false,
    };

  default:
    return { ...state };
  }
};
