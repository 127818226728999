import {
  FETCH_TYPES_START,
  FETCH_TYPES_SUCCESS,
  FETCH_TYPES_FAIL,
} from './action';

const initialState = {
  data: {},
  isLoading: true,
  isInitial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_TYPES_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_TYPES_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
      data: payload
    };
  case FETCH_TYPES_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  default:
    return { ...state };
  }
};
