/* eslint-disable no-case-declarations */
import {
  FETCH_USER_GROUPS_SUCCESS,
  FETCH_USER_GROUPS_FAIL,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUP_START,
  FETCH_USER_GROUP_SUCCESS,
  FETCH_USER_GROUP_FAIL,
  WRITE_USER_GROUP_START,
  WRITE_USER_GROUP_FAIL,
  WRITE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP_SUCCESS,
  CHANGE_USER_GROUP_SUCCESS
} from './action';
import { cloneDeep } from 'lodash';

const initialState = {
  list: [],
  selected: {},
  isLoading: true,
  isInitial: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_USER_GROUPS_START:
    return {
      ...state,
      isLoading: true,
      err: null,
    };

  case FETCH_USER_GROUP_START:
  case WRITE_USER_GROUP_START:
    return {
      ...state,
      err: null,
    };

  case FETCH_USER_GROUPS_SUCCESS:
    const showChildrenIds = state.list.filter((item) => !!item?.showChildren).map((item) => item.id);
    return {
      ...state,
      list: payload.map((item) => ({
        ...item,
        showChildren: showChildrenIds.includes(item.id) || false,
      })),
      isLoading: false,
      isInitial: true,
    };

  case FETCH_USER_GROUP_SUCCESS:
    return {
      ...state,
      selected: payload,
      isLoading: false,
      isInitial: true,
    };

  case FETCH_USER_GROUP_FAIL:
  case FETCH_USER_GROUPS_FAIL:
  case WRITE_USER_GROUP_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  case CHANGE_USER_GROUP_SUCCESS:
    let modifiedData = cloneDeep(state.list);
    modifiedData = modifiedData.map((page) => (page.id === payload.id ? payload : page));
    return {
      ...state,
      list: modifiedData,
      isLoading: false,
      selected: state.selected && state.selected.id === payload.id ? payload : state.selected
    };

  case WRITE_USER_GROUP_SUCCESS:
    if (state.list[state.list.length - 1]?.id === payload.id) {
      return { ...state };
    }
    if (!payload.parentId) {
      return {
        ...state,
        list: [...state.list, payload],
        isLoading: false,
        isInitial: true,
      };
    }
    let listCopy = state.list.map((page) => (page.id === payload.parentId
      ? { ...page, childrenCount: page.childrenCount + 1 }
      : page));
    listCopy = [...listCopy, payload];
    return {
      ...state,
      list: [...listCopy],
      isLoading: false,
      isInitial: true,
    };

  case DELETE_USER_GROUP_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
      list: state.list.filter((page) => !payload?.includes(page.id)),
      selected: payload?.includes(state.selected?.id) ? {} : state.selected,
    };

  default:
    return { ...state };
  }
};
