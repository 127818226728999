import {
  post, get, put, remove
} from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';
import { DeleteUserGroupFlags } from '../../constants/user.constants';

export const SET_OPENED_PAGE_ID = 'SET_OPENED_PAGE_ID';

export const FETCH_USER_GROUPS_START = 'FETCH_USER_GROUPS_START';
export const FETCH_USER_GROUPS_SUCCESS = 'FETCH_USER_GROUPS_SUCCESS';
export const FETCH_USER_GROUPS_FAIL = 'FETCH_USER_GROUPS_FAIL';
export const FETCH_USER_GROUP_FAIL = 'FETCH_USER_GROUP_FAIL';
export const FETCH_USER_GROUP_START = 'FETCH_USER_GROUP_START';
export const FETCH_USER_GROUP_SUCCESS = 'FETCH_USER_GROUP_SUCCESS';

export const WRITE_USER_GROUP_START = 'WRITE_USER_GROUP_START';
export const WRITE_USER_GROUP_SUCCESS = 'WRITE_USER_GROUP_SUCCESS';
export const WRITE_USER_GROUP_FAIL = 'WRITE_USER_GROUP_FAIL';
export const CHANGE_USER_GROUP_SUCCESS = 'CHANGE_USER_GROUP_SUCCESS';
export const DELETE_USER_GROUP_START = 'DELETE_USER_GROUP_START';
export const DELETE_USER_GROUP_FAIL = 'DELETE_USER_GROUP_FAIL';
export const DELETE_USER_GROUP_SUCCESS = 'DELETE_USER_GROUP_SUCCESS';

const fetchUserGroupsStart = () => ({
  type: FETCH_USER_GROUPS_START,
});

export const fetchUserGroupsSuccess = (payload) => ({
  type: FETCH_USER_GROUPS_SUCCESS,
  payload,
});

const fetchUserGroupsFail = (err) => ({
  type: FETCH_USER_GROUPS_FAIL,
  payload: err,
});

const deleteUserGroupStart = (id) => ({
  type: DELETE_USER_GROUP_START,
  payload: id
});

export const deleteUserGroupSuccess = (id) => ({
  type: DELETE_USER_GROUP_SUCCESS,
  payload: id,
});

const deleteUserGroupFail = (err) => ({
  type: DELETE_USER_GROUP_FAIL,
  payload: err,
});

const fetchUserGroupStart = () => ({
  type: FETCH_USER_GROUP_START,
});

const fetchUserGroupSuccess = (payload) => ({
  type: FETCH_USER_GROUP_SUCCESS,
  payload
});

const fetchUserGroupFail = () => ({
  type: FETCH_USER_GROUP_FAIL,
});

export const writeUserGroupStart = () => ({
  type: WRITE_USER_GROUP_START,
});

export const writeUserGroupSuccess = (payload) => ({
  type: WRITE_USER_GROUP_SUCCESS,
  payload,
});

const writeUserGroupFail = (err) => ({
  type: WRITE_USER_GROUP_FAIL,
  payload: err,
});

export const changeUserGroupSuccess = (payload) => ({
  type: CHANGE_USER_GROUP_SUCCESS,
  payload,
});

export const setOpenedUserGroupId = (payload) => ({
  type: SET_OPENED_PAGE_ID,
  payload
});

export const fetchAllUserGroups = (langCode) => async (dispatch) => {
  dispatch(fetchUserGroupsStart());
  try {
    const result = await get(`/user-groups?langCode=${langCode}`);
    dispatch(fetchUserGroupsSuccess(result.data));
  } catch (e) {
    dispatch(fetchUserGroupsFail(e));
  }
};

export const fetchUserGroupById = (id, lang) => async (dispatch) => {
  dispatch(fetchUserGroupStart());
  try {
    const result = await get(`/user-groups/${id}?langCode=${lang}`);
    dispatch(fetchUserGroupSuccess(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchUserGroupFail(e));
    return false;
  }
};

export const writeUserGroup = (group, translations, silent = true) => async (dispatch) => {
  dispatch(writeUserGroupStart());
  try {
    const result = await post('/user-groups', group);
    dispatch(writeUserGroupSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return result.data.id;
  } catch (e) {
    console.log(e);
    dispatch(writeUserGroupFail(e));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return null;
  }
};

export const changeUserGroup = (changedGroup, translations, silent = true) => async (dispatch) => {
  dispatch(writeUserGroupStart());
  try {
    const result = await put(`/user-groups/${changedGroup.id}`, changedGroup);
    dispatch(changeUserGroupSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    dispatch(writeUserGroupFail(e));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return false;
  }
};

export const deleteUserGroup = (id, flag = DeleteUserGroupFlags.SELF_ONLY, lang, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteUserGroupStart(id));
  try {
    const res = await remove(`/user-groups/${id}`, { data: { flag } });
    if (res?.data === true) {
      dispatch(deleteUserGroupSuccess([id]));
      dispatch(fetchAllUserGroups(lang));
      store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
    } else {
      store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
    }
      setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
  } catch (e) {
    console.log('e', e);
    dispatch(deleteUserGroupFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const changeVisible = (group) => async (dispatch) => {
  try {
    const result = await put(`/user-groups/${group.id}/change-visibility`, {});
    if (result?.data === true) {
      dispatch(changeUserGroupSuccess({ ...group, isVisible: !group.isVisible }));
    }
    return !!result?.data;
  } catch (e) {
    return false;
  }
};
