import {
  post,
  get,
  remove,
  put,
} from '../../api';

import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const CREATE_USERS_AUTH_PROVIDER_START = 'CREATE_USERS_AUTH_PROVIDER_START';
export const CREATE_USERS_AUTH_PROVIDER_SUCCESS = 'CREATE_USERS_AUTH_PROVIDER_SUCCESS';
export const CREATE_USERS_AUTH_PROVIDER_FAIL = 'CREATE_USERS_AUTH_PROVIDER_FAIL';

export const FETCH_USERS_AUTH_PROVIDER_LIST_START = 'FETCH_USERS_AUTH_PROVIDER_LIST_START';
export const FETCH_USERS_AUTH_PROVIDER_LIST_SUCCESS = 'FETCH_USERS_AUTH_PROVIDER_LIST_SUCCESS';
export const FETCH_USERS_AUTH_PROVIDER_LIST_FAIL = 'FETCH_USERS_AUTH_PROVIDER_LIST_FAIL';

export const DELETE_USERS_AUTH_PROVIDER_START = 'DELETE_USERS_AUTH_PROVIDER_START';
export const DELETE_USERS_AUTH_PROVIDER_SUCCESS = 'DELETE_USERS_AUTH_PROVIDER_SUCCESS';
export const DELETE_USERS_AUTH_PROVIDER_FAIL = 'DELETE_USERS_AUTH_PROVIDER_FAIL';

export const UPDATE_USERS_AUTH_PROVIDER_START = 'UPDATE_USERS_AUTH_PROVIDER_START';
export const UPDATE_USERS_AUTH_PROVIDER_SUCCESS = 'UPDATE_USERS_AUTH_PROVIDER_SUCCESS';
export const UPDATE_USERS_AUTH_PROVIDER_FAIL = 'UPDATE_USERS_AUTH_PROVIDER_FAIL';

const createUsersAuthProviderStart = () => ({
  type: CREATE_USERS_AUTH_PROVIDER_START
});

export const createUsersAuthProviderSuccess = (data) => ({
  type: CREATE_USERS_AUTH_PROVIDER_SUCCESS,
  payload: data
});
const createUsersAuthProviderFail = (err) => ({
  type: CREATE_USERS_AUTH_PROVIDER_FAIL,
  payload: err
});

const fetchUsersAuthProviderListStart = () => ({
  type: FETCH_USERS_AUTH_PROVIDER_LIST_START
});

export const fetchUsersAuthProviderListSuccess = (data) => ({
  type: FETCH_USERS_AUTH_PROVIDER_LIST_SUCCESS,
  payload: data
});

const fetchUsersAuthProviderListFail = (err) => ({
  type: FETCH_USERS_AUTH_PROVIDER_LIST_FAIL,
  payload: err
});

const deleteUsersAuthProviderStart = () => ({
  type: DELETE_USERS_AUTH_PROVIDER_START
});

export const deleteUsersAuthProviderSuccess = (id) => ({
  type: DELETE_USERS_AUTH_PROVIDER_SUCCESS,
  payload: id
});

const deleteUsersAuthProviderFail = (err) => ({
  type: DELETE_USERS_AUTH_PROVIDER_FAIL,
  payload: err
});

const updateUsersAuthProviderStart = () => ({
  type: UPDATE_USERS_AUTH_PROVIDER_START
});

const updateUsersAuthProviderSuccess = (data) => ({
  type: UPDATE_USERS_AUTH_PROVIDER_SUCCESS,
  payload: data
});

const updateUsersAuthProviderFail = (err) => ({
  type: UPDATE_USERS_AUTH_PROVIDER_FAIL,
  payload: err
});

export const createUsersAuthProvider = (data, LOCALE, setNewItemProcess) => async (dispatch) => {
  dispatch(createUsersAuthProviderStart());
  try {
    const result = await post('/users-auth-providers', data);
    dispatch(createUsersAuthProviderSuccess(result.data));
    setNewItemProcess((prev) => prev.filter((elId) => elId !== data.id));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(LOCALE));
    return true;
  } catch (e) {
    dispatch(createUsersAuthProviderFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(LOCALE));
    return false;
  }
};

export const fetchUsersAuthProviderData = (id) => async () => {
  try {
    const result = await get(`/users-auth-providers/${id}`);
    return result.data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const fetchUsersAuthProviderList = () => async (dispatch) => {
  dispatch(fetchUsersAuthProviderListStart());
  try {
    const result = await get('/users-auth-providers');
    dispatch(fetchUsersAuthProviderListSuccess(result.data));
  } catch (e) {
    dispatch(fetchUsersAuthProviderListFail(e));
  }
};

export const deleteUsersAuthProvider = (id, LOCALE, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteUsersAuthProviderStart());
  try {
    await remove(`/users-auth-providers/${id}`);
    dispatch(deleteUsersAuthProviderSuccess(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(LOCALE));
  } catch (e) {
    dispatch(deleteUsersAuthProviderFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(LOCALE));
  }
};

export const updateUsersAuthProvider = (data, LOCALE) => async (dispatch) => {
  dispatch(updateUsersAuthProviderStart());
  try {
    await put(`/users-auth-providers/${data.id}`, data);
    dispatch(updateUsersAuthProviderSuccess());
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(LOCALE));
    return true;
  } catch (e) {
    dispatch(updateUsersAuthProviderFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(LOCALE));
    return false;
  }
};
