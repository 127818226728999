import {
  FETCH_BLOCKS_START,
  FETCH_BLOCKS_SUCCESS,
  FETCH_BLOCKS_FAIL,
  FETCH_BLOCK_START,
  FETCH_BLOCK_SUCCESS,
  FETCH_BLOCK_FAIL,
  CREATE_BLOCK_SUCCESS,
  CREATE_BLOCK_START,
  CREATE_BLOCK_FAIL,
  UPDATE_BLOCK_START,
  UPDATE_BLOCK_SUCCESS,
  UPDATE_BLOCK_FAIL,
  DELETE_BLOCK_START,
  DELETE_BLOCK_SUCCESS,
  DELETE_BLOCK_FAIL,
} from './action';

const initialState = {
  blocks: {
    items: [],
    err: null,
  },
  block: {
    item: null,
    err: null,
  },
  isLoading: true,
  isInitial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_BLOCKS_START:
    return {
      ...state,
      blocks: {
        ...state.blocks,
      },
      isLoading: true,
    };

  case FETCH_BLOCKS_SUCCESS:
    return {
      ...state,
      blocks: {
        ...state.blocks,
        items: [...payload],
      },
      isLoading: false,
      isInitial: true,
    };

  case FETCH_BLOCKS_FAIL:
    return {
      ...state,
      blocks: {
        ...state.blocks,
        err: payload,
      },
      isLoading: false,
      isInitial: true,
    };

  case FETCH_BLOCK_START:
    return {
      ...state,
      block: { item: null },
      isLoading: true,
    };

  case FETCH_BLOCK_SUCCESS:
    return {
      ...state,
      block: { item: payload },
      isLoading: false,
      isInitial: true,
    };

  case FETCH_BLOCK_FAIL:
    return {
      ...state,
      block: {
        ...state.block,
        err: payload,
      },
      isLoading: false,
    };

  case CREATE_BLOCK_START:
    return {
      ...state,
      isLoading: true,
    };

  case CREATE_BLOCK_SUCCESS:
    return {
      ...state,
      blocks: {
        items: [...state.blocks.items, { ...payload }],
        err: null,
      },
      isLoading: false,
    };

  case CREATE_BLOCK_FAIL:
    return {
      ...state,
      block: {
        ...state.block,
        err: payload,
      },
      isLoading: false,
    };

  case UPDATE_BLOCK_START:
    return {
      ...state,
      isLoading: true,
    };

  case UPDATE_BLOCK_SUCCESS:
    return {
      ...state,
      blocks: {
        ...state.blocks,
        items: state.blocks.items.map((item) => (item?.id === payload?.id ? { ...payload } : item)),
      },
      isLoading: false,
    };

  case UPDATE_BLOCK_FAIL:
    return {
      ...state,
      block: {
        ...state.block,
        err: payload,
      },
      isLoading: false,
    };

  case DELETE_BLOCK_START:
    return {
      ...state,
      isLoading: true,
    };

  case DELETE_BLOCK_SUCCESS:
    return {
      ...state,
      blocks: {
        ...state.blocks,
        items: state.blocks.items.filter((b) => b.id !== payload),
      },
      isLoading: false,
    };

  case DELETE_BLOCK_FAIL:
    return {
      ...state,
      block: {
        ...state.block,
        err: payload,
      },
      isLoading: false,
    };

  default:
    return { ...state };
  }
};
