import { get } from '@api';
import { setTypeAdditionalData, setTypeDescriptions } from '../../utils/system/type.utils';

/**
 * Типы для приложения
 */
export const AppTypeNames = {
  modules: 'modules',
  general: 'general-types',
  attributes: 'index-attributes',
  attributeSets: 'attributes-sets'
};

export const FETCH_TYPES_START = 'FETCH_TYPES_START';
export const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS';
export const FETCH_TYPES_FAIL = 'FETCH_TYPES_FAIL';

const fetchTypesStart = () => ({
  type: FETCH_TYPES_START
});

const fetchTypesSuccess = (payload) => ({
  type: FETCH_TYPES_SUCCESS,
  payload
});

const fetchTypesFail = (payload) => ({
  type: FETCH_TYPES_FAIL,
  payload
});

export const fetchTypes = (translations) => async (dispatch) => {
  try {
    dispatch(fetchTypesStart());
    const types = [];
    for (const type of Object.values(AppTypeNames)) {
      const url = type === AppTypeNames.general ? `/${type}` : `/${type}/types`;
      // eslint-disable-next-line no-await-in-loop
      const result = await get(url);
      if (Array.isArray(result?.data)) {
        types[type] = setTypeDescriptions(result?.data, false, translations);
      }
      if (type === AppTypeNames.attributes && Array.isArray(types[type])) {
        types[type] = setTypeAdditionalData(types[type]);
      }
    }
    dispatch(fetchTypesSuccess(types));
  } catch (e) {
    dispatch(fetchTypesFail(e));
  }
};
