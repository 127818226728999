import { uniqBy } from 'lodash';

import {
  FETCH_JOURNAL_START,
  FETCH_JOURNAL_SUCCESS,
  FETCH_JOURNAL_FAIL,
} from './action';

const initialState = {
  journal: {
    items: [],
    err: null,
  },
  isLoading: true,
  isInitial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_JOURNAL_START:
    return {
      ...state,
      journal: {
        ...state.journal,
      },
      isLoading: true,
    };
  case FETCH_JOURNAL_SUCCESS:
  {
    let items = [];
    if (payload.params?.page > 1) {
      items = uniqBy([...state.journal.items, ...payload.data.items], 'id');
    } else {
      items = payload.data.items;
    }
    return {
      ...state,
      journal: {
        ...state.journal,
        items,
        total: payload.data.total,
      },
      isLoading: false,
      isInitial: true,
    };
  }
  case FETCH_JOURNAL_FAIL:
    return {
      ...state,
      journal: {
        ...state.journal,
        err: payload,
      },
      isLoading: false,
      isInitial: true,
    };
  default:
    return { ...state };
  }
};
