import {
  post,
  get,
  remove,
  put,
} from '../../api';

import { fetchUpdateAuthPermissionsSuccess } from '../auth-permissions/action';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL, COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';
import { DEFAULT_CONTENT_LANG, DEFAULT_UI_THEME } from '../../constants/settings';
import { setLocaleActive } from '../locales/action';

export const FETCH_ADD_ADMIN_START = 'FETCH_ADD_ADMIN_START';
export const FETCH_ADD_ADMIN_SUCCESS = 'FETCH_ADD_ADMIN_SUCCESS';
export const FETCH_ADD_ADMIN_FAIL = 'FETCH_ADD_ADMIN_FAIL';

export const FETCH_ADMIN_LIST_START = 'FETCH_ADMIN_LIST_START';
export const FETCH_ADMIN_LIST_SUCCESS = 'FETCH_ADMIN_LIST_SUCCESS';
export const FETCH_ADMIN_LIST_FAIL = 'FETCH_ADMIN_LIST_FAIL';

export const FETCH_DELETE_ADMIN_START = 'FETCH_DELETE_ADMIN_START';
export const FETCH_DELETE_ADMIN_SUCCESS = 'FETCH_DELETE_ADMIN_SUCCESS';
export const FETCH_DELETE_ADMIN_FAIL = 'FETCH_DELETE_ADMIN_FAIL';
export const FETCH_ADD_ADMINS_MODULES = 'FETCH_ADD_ADMINS_MODULES';

export const FETCH_ADMIN_DATA_START = 'FETCH_ADMIN_DATA_START';
export const FETCH_ADMIN_DATA_SUCCESS = 'FETCH_ADMIN_DATA_SUCCESS';
export const FETCH_ADMIN_DATA_FAIL = 'FETCH_ADMIN_DATA_FAIL';

export const FETCH_UPDATE_ADMIN_START = 'FETCH_UPDATE_ADMIN_START';
export const FETCH_UPDATE_ADMIN_SUCCESS = 'FETCH_UPDATE_ADMIN_SUCCESS';
export const FETCH_UPDATE_ADMIN_FAIL = 'FETCH_UPDATE_ADMIN_FAIL';

export const UPDATE_ADMIN_PERMISSIONS_START = 'UPDATE_ADMIN_PERMISSIONS_START';
export const UPDATE_ADMIN_PERMISSIONS_SUCCESS = 'UPDATE_ADMIN_PERMISSIONS_SUCCESS';
export const UPDATE_ADMIN_PERMISSIONS_FAIL = 'UPDATE_ADMIN_PERMISSIONS_FAIL';

export const FETCH_ADMIN_LANGUAGE_SUCCESS = 'FETCH_ADMIN_LANGUAGE_SUCCESS';

export const FETCH_SET_THEME_SUCCESS = 'FETCH_SET_THEME_SUCCESS';

export const SET_SELECTED_ADMIN = 'SET_SELECTED_ADMIN';

export const UPDATE_ADMIN_POSITIONS = 'UPDATE_ADMIN_POSITIONS';

/**
 * @deprecated
 */
export const HIGH_PING = 'HIGH_PING';

const fetchAddAdminsModules = (data) => ({
  type: FETCH_ADD_ADMINS_MODULES,
  payload: data
});

export const updateAdminPositions = (payload) => ({
  type: UPDATE_ADMIN_POSITIONS,
  payload
});

/**
 * @deprecated
 */
export const updateUserPing = (isHighPing) => ({
  type: HIGH_PING,
  payload: isHighPing
});

const fetchAddAdminStart = () => ({
  type: FETCH_ADD_ADMIN_START
});
export const fetchAddAdminSuccess = (data) => ({
  type: FETCH_ADD_ADMIN_SUCCESS,
  payload: data
});
const fetchAddAdminFail = (err) => ({
  type: FETCH_ADD_ADMIN_FAIL,
  payload: err
});

const fetchAdminListStart = () => ({
  type: FETCH_ADMIN_LIST_START
});
export const fetchAdminListSuccess = (data) => ({
  type: FETCH_ADMIN_LIST_SUCCESS,
  payload: data
});
const fetchAdminListFail = (err) => ({
  type: FETCH_ADMIN_LIST_FAIL,
  payload: err
});

const fetchDeleteAdminStart = () => ({
  type: FETCH_DELETE_ADMIN_START
});
export const fetchDeleteAdminSuccess = (id) => ({
  type: FETCH_DELETE_ADMIN_SUCCESS,
  payload: id
});
const fetchDeleteAdminFail = (err) => ({
  type: FETCH_DELETE_ADMIN_FAIL,
  payload: err
});

const fetchAdminDataStart = () => ({
  type: FETCH_ADMIN_DATA_START
});

const fetchAdminDataSuccess = (data) => ({
  type: FETCH_ADMIN_DATA_SUCCESS,
  payload: data
});

const fetchAdminDataFail = (err) => ({
  type: FETCH_ADMIN_DATA_FAIL,
  payload: err
});

const fetchUpdateAdminStart = () => ({
  type: FETCH_UPDATE_ADMIN_START
});

const fetchUpdateAdminSuccess = (data) => ({
  type: FETCH_UPDATE_ADMIN_SUCCESS,
  payload: data
});

const fetchUpdateAdminFail = (err) => ({
  type: FETCH_UPDATE_ADMIN_FAIL,
  payload: err
});

const updateAdminPermissionsStart = () => ({
  type: UPDATE_ADMIN_PERMISSIONS_START
});

const updateAdminPermissionsSuccess = (data) => ({
  type: UPDATE_ADMIN_PERMISSIONS_SUCCESS,
  payload: data
});

const updateAdminPermissionsFail = (err) => ({
  type: UPDATE_ADMIN_PERMISSIONS_FAIL,
  payload: err
});

export const fetchAdminLanguageSuccess = (data) => ({
  type: FETCH_ADMIN_LANGUAGE_SUCCESS,
  payload: data
});

export const fetchSetThemeSuccess = (data) => ({
  type: FETCH_SET_THEME_SUCCESS,
  payload: data
});

export const setSelectedAdmin = (payload) => ({
  type: SET_SELECTED_ADMIN,
  payload,
});

export const fetchAdminsModules = ({ listModules }) => async (dispatch) => {
  dispatch(fetchAddAdminsModules(listModules));
};

export const fetchAddAdmin = (data, translations) => async (dispatch) => {
  dispatch(fetchAddAdminStart());
  try {
    const result = await post('/admins', data);
    dispatch(fetchAddAdminSuccess(result.data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(fetchAddAdminFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const fetchAdminList = ({ limit, offset, lang }) => async (dispatch, getState) => {
  dispatch(fetchAdminListStart());
  const authState = getState().authReducer;

  try {
    const result = await get(`/admins?limit=${limit}&offset=${offset}&langCode=${lang}`);
    dispatch(fetchAdminListSuccess(result.data));
    /** Находим текущего админа */
    const admin = result.data.find((item) => +item.id === +authState.data.id);
    /** Сохраняем выбранный язык пользователя и выбранную тему в authReducer */
    if (admin) {
      dispatch(fetchAdminLanguageSuccess(admin.selectedLanguage));
      dispatch(fetchSetThemeSuccess(admin?.selectedUITheme || DEFAULT_UI_THEME));
      dispatch(setLocaleActive(admin?.selectedContentLanguage || DEFAULT_CONTENT_LANG));
      // @todo: нужно подумать о корректном месте для этого кода, AVM
      localStorage.setItem('activeTheme', admin.selectedUITheme);
    }
  } catch (e) {
    dispatch(fetchAdminListFail(e));
  }
};

export const fetchDeleteAdmin = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(fetchDeleteAdminStart());
  try {
    await remove(`/admins/${id}`);
    dispatch(fetchDeleteAdminSuccess(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(fetchDeleteAdminFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const getAdminData = (id) => async (dispatch) => {
  dispatch(fetchAdminDataStart());
  try {
    const result = await get(`/admins/${id}`);
    dispatch(fetchAdminDataSuccess(result.data));
    dispatch(setSelectedAdmin(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchAdminDataFail(e));
  }
};

export const fetchUpdateAdminData = (data, translations) => async (dispatch) => {
  dispatch(fetchUpdateAdminStart());
  try {
    let result = await put(`/admins/${data.id}`, data);
    if (result?.data === true) {
      // eslint-disable-next-line no-plusplus
      dispatch(setSelectedAdmin({ ...data, version: ++data.version }));
    }
    if (result.config.data) {
      result = JSON.parse(result.config.data);
    }
    dispatch(fetchUpdateAdminSuccess({ ...result, id: +data.id }));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(fetchUpdateAdminFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const fetchCheckAdminLogin = (login) => async () => {
  try {
    const result = await post('/admins/check-login-existence', { login });
    return result.data.exist;
  } catch (e) {
    console.error(e);
  }
};

export const fetchCheckAdminEmail = (email) => async () => {
  try {
    const result = await post('/admins/check-email-existence', { email });
    return result.data.exist;
  } catch (e) {
    console.error(e);
  }
};

/**
 * @deprecated
 */
export const updateAdminPermissions = ({ id }, permissions, translations) => async (dispatch, getState) => {
  dispatch(updateAdminPermissionsStart());
  const authState = getState().authReducer;
  if (authState.data.userId === id) {
    dispatch(fetchUpdateAuthPermissionsSuccess(permissions));
  }
  try {
    await put(`/admins/${id}/permissions`, { permissions });
    dispatch(updateAdminPermissionsSuccess({ permissions, id }));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (err) {
    dispatch(updateAdminPermissionsFail(err));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};
