import { post, get, put, remove } from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';
import { getSettings } from '../settings/action';

export const FETCH_TEMPLATES_PREVIEW_START = 'FETCH_TEMPLATES_PREVIEW_START';
export const FETCH_TEMPLATES_PREVIEW_SUCCESS = 'FETCH_TEMPLATES_PREVIEW_SUCCESS';
export const FETCH_TEMPLATES_PREVIEW_FAIL = 'FETCH_TEMPLATES_PREVIEW_FAIL';
export const FETCH_TEMPLATES_PREVIEW_ALL_SUCCESS = 'FETCH_TEMPLATES_PREVIEW_ALL_SUCCESS';
export const WRITE_TEMPLATE_PREVIEW_START = 'WRITE_TEMPLATE_PREVIEW_START';
export const WRITE_TEMPLATE_PREVIEW_SUCCESS = 'WRITE_TEMPLATE_PREVIEW_SUCCESS';
export const WRITE_TEMPLATE_PREVIEW_FAIL = 'WRITE_TEMPLATE_PREVIEW_FAIL';
export const DELETE_TEMPLATE_PREVIEW_START = 'DELETE_TEMPLATE_PREVIEW_START';
export const DELETE_TEMPLATE_PREVIEW_SUCCESS = 'DELETE_TEMPLATE_PREVIEW_SUCCESS';
export const DELETE_TEMPLATE_PREVIEW_FAIL = 'DELETE_TEMPLATE_PREVIEW_FAIL';
export const CHANGE_TEMPLATE_PREVIEW_SUCCESS = 'CHANGE_TEMPLATE_PREVIEW_SUCCESS';
export const UPDATE_TEMPLATES_PREVIEW_POSITIONS = 'UPDATE_TEMPLATES_PREVIEW_POSITIONS';
export const WRITE_TEMPLATE_PREVIEW_SET_START = 'WRITE_TEMPLATE_PREVIEW_SET_START';
export const WRITE_TEMPLATE_PREVIEW_SET_SUCCESS = 'WRITE_TEMPLATE_PREVIEW_SET_SUCCESS';
export const WRITE_TEMPLATE_PREVIEW_SET_FAIL = 'WRITE_TEMPLATE_PREVIEW_SET_FAIL';
export const CHANGE_TEMPLATE_PREVIEW_SET_SUCCESS = 'CHANGE_TEMPLATE_PREVIEW_SET_SUCCESS';
export const UPDATE_TEMPLATES_PREVIEW_SET_START = 'UPDATE_TEMPLATES_PREVIEW_SET_START';
export const UPDATE_TEMPLATES_PREVIEW_SET_FAIL = 'UPDATE_TEMPLATES_PREVIEW_SET_FAIL';

const writeAttributesSetStart = () => ({
  type: WRITE_TEMPLATE_PREVIEW_SET_START,
});

const writeAttributesSetFail = (err) => ({
  type: WRITE_TEMPLATE_PREVIEW_SET_FAIL,
  payload: err,
});

const changeAttributesSetSuccess = (changedSet) => ({
  type: CHANGE_TEMPLATE_PREVIEW_SET_SUCCESS,
  payload: changedSet,
});

const fetchTemplatesStart = () => ({
  type: FETCH_TEMPLATES_PREVIEW_START,
});

const fetchTemplatesSuccess = (data, dataType) => ({
  type: FETCH_TEMPLATES_PREVIEW_SUCCESS,
  payload: { data, dataType },
});

const fetchAllTemplatesSuccess = (data) => ({
  type: FETCH_TEMPLATES_PREVIEW_ALL_SUCCESS,
  payload: data,
});

const fetchTemplatesFail = (err) => ({
  type: FETCH_TEMPLATES_PREVIEW_FAIL,
  payload: err,
});

const writeTemplateStart = () => ({
  type: WRITE_TEMPLATE_PREVIEW_START,
});

const writeTemplateSuccess = (newTemplate) => ({
  type: WRITE_TEMPLATE_PREVIEW_SUCCESS,
  payload: newTemplate,
});

const writeTemplateFail = (err) => ({
  type: WRITE_TEMPLATE_PREVIEW_FAIL,
  payload: err,
});

const deleteTemplateStart = () => ({
  type: DELETE_TEMPLATE_PREVIEW_START,
});

const deleteTemplateSuccess = (payload) => {
  return {
    type: DELETE_TEMPLATE_PREVIEW_SUCCESS,
    payload,
  };
};

const deleteTemplateFail = (err) => ({
  type: DELETE_TEMPLATE_PREVIEW_FAIL,
  payload: err,
});

export const updateTemplatePreviewsPositions = (payload) => ({
  type: UPDATE_TEMPLATES_PREVIEW_POSITIONS,
  payload,
});

const updateTemplatePreviewSetStart = () => ({
  type: UPDATE_TEMPLATES_PREVIEW_SET_START,
});

const updateTemplatePreviewSetFail = (error) => ({
  type: UPDATE_TEMPLATES_PREVIEW_SET_FAIL,
  payload: error,
});

export const fetchAllTemplatePreviews = () => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  try {
    const result = await get('/template-previews');
    dispatch(fetchAllTemplatesSuccess(result.data));
  } catch (e) {
    dispatch(fetchTemplatesFail(e));
  }
};

export const fetchTemplatePreviews = (type) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  try {
    const result = await get(`/template-previews?type=${type}`);
    dispatch(fetchTemplatesSuccess(result.data, type));
  } catch (e) {
    dispatch(fetchTemplatesFail(e));
  }
};

// eslint-disable-next-line consistent-return
export const writeTemplatePreviews = (newTemplate, translations, setNewItemProcess) => async (dispatch) => {
    dispatch(writeTemplateStart());
    try {
      const result = await post('/template-previews', newTemplate);
      dispatch(writeTemplateSuccess(result.data));
      setNewItemProcess((prev) => prev.filter((elId) => elId !== newTemplate.identifier));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      // @todo: улучшить
      dispatch(fetchAllTemplatePreviews());
      return result.data;
    } catch (e) {
      dispatch(writeTemplateFail(e));
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
  };

export const deleteTemplatePreviews = (payload, translations, setDeleteProcess) => async (dispatch) => {
    dispatch(deleteTemplateStart());
    try {
      await remove(`/template-previews/${payload}`);
      dispatch(deleteTemplateSuccess(payload));
      setDeleteProcess((prev) => prev.filter((elId) => elId !== payload));
      store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
    } catch (e) {
      dispatch(deleteTemplateFail(e));
      store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
    }
  };

export const changeTemplatePreviews = (payload, translations, silent = true) =>
  async (dispatch) => {
    dispatch(writeTemplateStart());
    try {
      await put(`/template-previews/${payload.id}`, payload);
      // @todo: улучшить
      dispatch(fetchAllTemplatePreviews());
      if (!silent) {
        store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      }
      return true;
    } catch (e) {
      dispatch(writeTemplateFail(e));
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
  };

export const getTemplatePreviewsPage = (id) => async () => {
  try {
    const result = await get(`/template-previews/${id}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const updateTemplatePreviewSet = (locale, translations, silent = true) =>
  async (dispatch) => {
    dispatch(updateTemplatePreviewSetStart());
    try {
      const result = await put(`/locales/${locale.id}`, locale);
      if (!silent && !!result?.data) {
        store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(updateTemplatePreviewSetFail(e));
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
      return false;
    }
  };

export const getTemplatePreviewSet = (id) => async () => {
  try {
    const result = await get(`/template-preview/schema/${id}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};

export const changeTemplatePreview = (pageObject, translations, deleted, deletedId) => async (dispatch) => {
    dispatch(writeAttributesSetStart());
    try {
      await put(`/template-previews/${pageObject.id}`, {
        ...pageObject,
        deleted,
        deletedId,
      });
      dispatch(changeAttributesSetSuccess(pageObject));
      dispatch(getSettings());
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      return true;
    } catch (e) {
      dispatch(writeAttributesSetFail(e));
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
      return false;
    }
  };
