import {
  post, get, put, remove
} from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';
import { DeletePageFlags } from '../../constants/page.constants';

export const FETCH_PAGES_START = 'FETCH_PAGES_START';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAIL = 'FETCH_PAGES_FAIL';

export const FETCH_CHILDRENPAGES_START = 'FETCH_CHILDRENPAGES_START';
export const FETCH_CHILDRENPAGES_SUCCESS = 'FETCH_CHILDRENPAGES_SUCCESS';
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS';
export const FETCH_CHILDRENPAGES_FAIL = 'FETCH_CHILDRENPAGES_FAIL';

export const DELETE_PAGE_START = 'DELETE_PAGE_START';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAIL = 'DELETE_PAGE_FAIL';

export const WRITE_PAGE_START = 'WRITE_PAGE_START';
export const WRITE_PAGE_SUCCESS = 'WRITE_PAGE_SUCCESS';
export const WRITE_PAGE_FAIL = 'WRITE_PAGE_FAIL';

export const UPDATE_MODE_PAGE = 'UPDATE_MODE_PAGE';

export const UPDATE_PAGE_POSITION_SUCCESS = 'UPDATE_PAGE_POSITION_SUCCESS';

export const CHANGE_PAGE_START = 'CHANGE_PAGE_START';
export const CHANGE_PAGE_SUCCESS = 'CHANGE_PAGE_SUCCESS';
export const CHANGE_PAGES_POSITIONS = 'CHANGE_PAGES_POSITIONS';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const CHANGE_PAGE_STATUS = 'CHANGE_PAGE_STATUS';

export const FETCH_COPY_PAGE_START = 'FETCH_COPY_PAGE_START';
export const FETCH_COPY_PAGE_SUCCESS = 'FETCH_COPY_PAGE_SUCCESS';
export const FETCH_COPY_PAGE_FAIL = 'FETCH_COPY_PAGE_FAIL';
export const CLEAR_PAGES_ERROR = 'CLEAR_PAGES_ERROR';

export const SUCCESS_UPDATE_PAGES = 'SUCCESS_UPDATE_PAGES';

export const UPDATE_PAGE_PRODUCT_COUNTER = 'UPDATE_PAGE_PRODUCT_COUNTER';

export const SET_PAGE_ID_FOR_DELETION_PRODUCTS = 'SET_PAGE_ID_FOR_DELETION_PRODUCTS';
export const CLEAR_PAGE_IDS_FOR_DELETION_PRODUCTS = 'CLEAR_PAGE_IDS_FOR_DELETION_PRODUCTS';
export const SET_OPENED_PAGE_ID = 'SET_OPENED_PAGE_ID';
export const SET_PAGE_VALIDATION_STATUS = 'SET_PAGE_VALIDATION_STATUS';

export const UPDATE_PAGE_STATE = 'UPDATE_PAGE_STATE';

const fetchCopyPageStart = (data) => ({
  type: FETCH_COPY_PAGE_START,
  payload: data
});

export const fetchCopyPageSuccess = (data) => ({
  type: FETCH_COPY_PAGE_SUCCESS,
  payload: data,
});

const fetchCopyPageFail = (err) => ({
  type: FETCH_COPY_PAGE_FAIL,
  payload: err,
});

const fetchPagesStart = () => ({
  type: FETCH_PAGES_START,
});

export const fetchPagesSuccess = (data) => ({
  type: FETCH_PAGES_SUCCESS,
  payload: data,
});

const fetchPagesFail = (err) => ({
  type: FETCH_PAGES_FAIL,
  payload: err,
});

const fetchChildrenPagesStart = () => ({
  type: FETCH_CHILDRENPAGES_START,
});

const fetchChildrenPagesSuccess = (data) => ({
  type: FETCH_CHILDRENPAGES_SUCCESS,
  payload: data,
});

const fetchChildrenPagesFail = (err) => ({
  type: FETCH_CHILDRENPAGES_FAIL,
  payload: err,
});

const deletePageStart = (id) => ({
  type: DELETE_PAGE_START,
  payload: id
});

export const deletePageSuccess = (id) => ({
  type: DELETE_PAGE_SUCCESS,
  payload: id,
});

export const deletePageFail = (err) => ({
  type: DELETE_PAGE_FAIL,
  payload: err,
});

export const successUpdatePages = (payload) => ({
  type: SUCCESS_UPDATE_PAGES,
  payload
});

const writePageStart = () => ({
  type: WRITE_PAGE_START,
});

export const writePageSuccess = (newPage) => ({
  type: WRITE_PAGE_SUCCESS,
  payload: newPage,
});

const writePageFail = (err) => ({
  type: WRITE_PAGE_FAIL,
  payload: err,
});

export const changePageStart = (data) => ({
  type: CHANGE_PAGE_START,
  payload: data,
});

export const changePageSuccess = (changedPage) => ({
  type: CHANGE_PAGE_SUCCESS,
  payload: changedPage,
});

export const changePagesPositions = (changedPage) => ({
  type: CHANGE_PAGES_POSITIONS,
  payload: changedPage,
});

export const updatePageProductCounter = (payload) => ({
  type: UPDATE_PAGE_PRODUCT_COUNTER,
  payload,
});

export const changePageStatus = (id) => ({
  type: CHANGE_PAGE_STATUS,
  payload: id,
});

export const updateMode = (payload) => ({
  type: UPDATE_MODE_PAGE,
  payload
});

export const updatePageSuccess = (payload) => ({
  type: UPDATE_PAGE_SUCCESS,
  payload
});

export const clearPagesErrors = () => ({
  type: CLEAR_PAGES_ERROR,
});

const setPageSuccess = (payload) => ({
  type: FETCH_PAGE_SUCCESS,
  payload
});

/**
 * @deprecated
 */
export const updatePagePosition = (positions) => ({
  type: UPDATE_PAGE_POSITION_SUCCESS,
  payload: positions
});

export const setPageIdForDeletionProducts = (payload) => ({
  type: SET_PAGE_ID_FOR_DELETION_PRODUCTS,
  payload
});

export const setOpenedPageId = (payload) => ({
  type: SET_OPENED_PAGE_ID,
  payload
});

export const clearPageIdsForDeletionProducts = () => ({
  type: CLEAR_PAGE_IDS_FOR_DELETION_PRODUCTS
});

export const updatePageState = () => ({
  type: UPDATE_PAGE_STATE
});

export const setPageValidationStatus = (payload) => ({
  type: SET_PAGE_VALIDATION_STATUS,
  payload
});

export const fetchAllPages = (langCode) => async (dispatch) => {
  dispatch(fetchPagesStart());
  try {
    const result = await get(`/pages?langCode=${langCode}`);
    dispatch(fetchPagesSuccess(result.data));
  } catch (e) {
    dispatch(fetchPagesFail(e));
  }
};

export const changePageConfig = (itemId, page) =>
  async (dispatch) => {
    dispatch(writePageStart());
    try {
      // eslint-disable-next-line no-unused-vars
      const result = await put(`/pages/${itemId}/config`, page.config);
      dispatch(changePageSuccess(page));
      return true;
    } catch (e) {
      dispatch(writePageFail(e));
      return false;
    }
  };

export const setAllPageInfo = (id, langCode) => async (dispatch) => {
  dispatch(fetchPagesStart());
  try {
    const result = await get(`pages/${id}?langCode=${langCode}`);
    dispatch(setPageSuccess(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchPagesFail(e));
  }
};

/**
 * @deprecated
 */
export const fetchPages = () => async (dispatch) => {
  dispatch(fetchPagesStart());
  try {
    const result = await get('/pages/root');
    dispatch(fetchPagesSuccess(result.data.sort((a, b) => a.position - b.position)));
  } catch (e) {
    dispatch(fetchPagesFail(e));
  }
};

/**
 * @deprecated
 */
export const fetchChildrenPages = (id) => async (dispatch) => {
  dispatch(fetchChildrenPagesStart());
  try {
    const result = await get(`/pages/${id}/children`);
    dispatch(fetchChildrenPagesSuccess({ data: result.data, id }));
  } catch (e) {
    dispatch(fetchChildrenPagesFail(e));
  }
};

export const deletePage = (id, flag = DeletePageFlags.SELF_ONLY, lang, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deletePageStart(id));
  try {
    const res = await remove(`/pages/${id}`, { data: { flag } });
    if (res?.data === true) {
      setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
      dispatch(deletePageSuccess([id]));
      dispatch(fetchAllPages(lang));
      store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
    } else {
      store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
    }
  } catch (e) {
    console.log('e', e);
    dispatch(deletePageFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const writePage = (newPage, translations, silent = true) => async (dispatch) => {
  dispatch(writePageStart());
  try {
    const result = await post('/pages', newPage);
    dispatch(writePageSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return result.data.id;
  } catch (e) {
    console.log(e);
    dispatch(writePageFail(e));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return null;
  }
};

/**
 * @deprecated
 */
export const updatePosition = (changedPage, translations) => async (dispatch) => {
  dispatch(writePageStart());
  try {
    const updatedPage = await put('/pages/position', changedPage);
    dispatch(updatePagePosition(updatedPage.data.payload));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (e) {
    dispatch(writePageFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const changePage = (changedPage, translations, silent = true) => async (dispatch) => {
  dispatch(writePageStart());
  try {
    const result = await put(`/pages/${changedPage.id}`, changedPage);
    dispatch(changePageSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    dispatch(writePageFail(e));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return false;
  }
};

export const changeVisible = (page) => async (dispatch) => {
  try {
    dispatch(changePageStart(page.id));
    const result = await put(`/pages/${page.id}/change-visibility`, {});
    if (result?.data === true) {
      dispatch(changePageSuccess({ ...page, isVisible: !page.isVisible }));
    }
    return !!result?.data;
  } catch (e) {
    return false;
  }
};

export const fetchCopyPage = (id, setCopyProcess) => async (dispatch, getState) => {
  dispatch(fetchCopyPageStart(id));
  try {
    const data = await post(`pages/copy/${id}`);
    const copiedPage = data.data;
    const pages = getState().pagesReducer.list.map((item) => {
      if ((item.parentId === copiedPage.parentId && item.position >= copiedPage.position && item.id !== id)) {
        item.position += 1;
      }
      return item;
    });
    const updatedPages = [...pages, copiedPage].sort((a, b) => a.position - b.position);
    setCopyProcess((prev) => [...prev].filter((elId) => elId !== id));
    dispatch(fetchCopyPageSuccess(updatedPages));
  } catch (e) {
    dispatch(fetchCopyPageFail(e));
  }
};
