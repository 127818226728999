import { post, get, put } from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const FETCH_USER_PERMISSIONS_START = 'FETCH_USER_PERMISSIONS_START';
export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS';
export const FETCH_USER_PERMISSIONS_FAIL = 'FETCH_USER_PERMISSIONS_FAIL';
export const FETCH_USER_PERMISSION_FAIL = 'FETCH_USER_PERMISSION_FAIL';
export const FETCH_USER_PERMISSION_START = 'FETCH_USER_PERMISSION_START';
export const FETCH_USER_PERMISSION_SUCCESS = 'FETCH_USER_PERMISSION_SUCCESS';

export const WRITE_USER_PERMISSION_START = 'WRITE_USER_GROUP_START';
export const WRITE_USER_PERMISSION_SUCCESS = 'WRITE_USER_GROUP_SUCCESS';
export const WRITE_USER_PERMISSION_FAIL = 'WRITE_USER_GROUP_FAIL';
export const CHANGE_USER_PERMISSION_SUCCESS = 'CHANGE_PERMISSION_GROUP_SUCCESS';
export const DELETE_USER_PERMISSION_SUCCESS = 'DELETE_USER_PERMISSION_SUCCESS';

const fetchUserPermissionsStart = () => ({
  type: FETCH_USER_PERMISSIONS_START,
});

export const fetchUserPermissionsSuccess = (payload) => ({
  type: FETCH_USER_PERMISSIONS_SUCCESS,
  payload,
});

const fetchUserPermissionsFail = (err) => ({
  type: FETCH_USER_PERMISSIONS_FAIL,
  payload: err,
});

export const deleteUserPermissionSuccess = (id) => ({
  type: DELETE_USER_PERMISSION_SUCCESS,
  payload: id,
});

const fetchUserPermissionStart = () => ({
  type: FETCH_USER_PERMISSION_START,
});

const fetchUserPermisionSuccess = (payload) => ({
  type: FETCH_USER_PERMISSION_SUCCESS,
  payload
});

const fetchUserPermissionFail = () => ({
  type: FETCH_USER_PERMISSION_FAIL,
});

export const writeUserPermissionStart = () => ({
  type: WRITE_USER_PERMISSION_START,
});

export const writeUserPermissionSuccess = (payload) => ({
  type: WRITE_USER_PERMISSION_SUCCESS,
  payload,
});

const writeUserPermissionFail = (err) => ({
  type: WRITE_USER_PERMISSION_FAIL,
  payload: err,
});

export const changeUserPermissionSuccess = (payload) => ({
  type: CHANGE_USER_PERMISSION_SUCCESS,
  payload,
});

export const fetchAllUserPermissions = () => async (dispatch) => {
  dispatch(fetchUserPermissionsStart());
  try {
    const result = await get('/user-permissions');
    dispatch(fetchUserPermissionsSuccess(result.data));
  } catch (e) {
    dispatch(fetchUserPermissionsFail(e));
  }
};

export const fetchUserPermissionById = (id) => async (dispatch) => {
  dispatch(fetchUserPermissionStart());
  try {
    const result = await get(`/user-permissions/${id}`);
    dispatch(fetchUserPermisionSuccess(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchUserPermissionFail(e));
    return false;
  }
};

export const writeUserPermission = (data, translations, silent = true) => async (dispatch) => {
  dispatch(writeUserPermissionStart());
  try {
    const result = await post('/user-permissions', data);
    dispatch(writeUserPermissionSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return result.data.id;
  } catch (e) {
    console.log(e);
    dispatch(writeUserPermissionFail(e));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return null;
  }
};

export const changeUserPermission = (data, translations, silent = true) => async (dispatch) => {
  dispatch(writeUserPermissionStart());
  try {
    const result = await put(`/user-permissions/${data.id}`, data);
    dispatch(changeUserPermissionSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    dispatch(writeUserPermissionFail(e));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return false;
  }
};
