export const PAYMENTS_LIMIT = 10;
export const PAYMENTS_MIN_COUNT = 0;
export const PAYMENTS_MIN_PAGE = 1;

export const FieldNames = {
  SORT_BY_CONDITION: 'sortByCondition',
  SORT_BY_DIRECTION: 'sortByDirection',
};

export const DISABLED_VALUE = 'disabled';

export const LAST_ELEMENT_INDEX = -1;

export const INDEX_INCREMENT = 1;

export const INITIAL_PAGE = 1;
export const PAGE_INCREMENT = 1;

export const DATE_FROM_HOURS = 0;
export const DATE_FROM_MUNITES = 0;
export const DATE_FROM_SECONDS = 0;

export const DATE_TO_HOURS = 23;
export const DATE_TO_MUNITES = 59;
export const DATE_TO_SECONDS = 59;

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const INITIAL_VALUES = {
  [FieldNames.SORT_BY_CONDITION]: undefined,
  [FieldNames.SORT_BY_DIRECTION]: undefined,
};

export const PARAMS_KEYS = [
  FieldNames.SORT_BY_CONDITION,
  FieldNames.SORT_BY_DIRECTION,
];

export const COLUMN_KEYS = [];

export const SORTING_COLUMN_KEYS = [];
