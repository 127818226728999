import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Reducers from './Reducers';

export function configureStore(InitialState) {
  const Store = createStore(
    Reducers,
    InitialState,
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(applyMiddleware(thunk))
      : applyMiddleware(thunk)
  );
  return Store;
}
