import { UPDATE_HELPER_TYPE } from './action';

const initialState = {
  isHelperOpen: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case UPDATE_HELPER_TYPE:
    return {
      isHelperOpen: payload
    };
  default:
    return state;
  }
};