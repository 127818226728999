import {
  FETCH_PERMISSIONS_START,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAIL,
} from './action';

const initialState = {
  permissions: [],
  isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_PERMISSIONS_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_PERMISSIONS_SUCCESS:
    return {
      ...state,
      permissions: payload,
      isLoading: false
    };
  case FETCH_PERMISSIONS_FAIL:
    return {
      ...state,
      isLoading: false
    };
  default:
    return { ...state };
  }
};
