import update from 'immutability-helper';
import {
  FETCH_ATTRIBUTES_SETS_START,
  FETCH_ATTRIBUTES_SETS_SUCCESS,
  FETCH_ATTRIBUTES_SETS_FAIL,
  WRITE_ATTRIBUTES_SET_START,
  WRITE_ATTRIBUTES_SET_SUCCESS,
  WRITE_ATTRIBUTES_SET_FAIL,
  DELETE_ATTRIBUTES_SET_START,
  DELETE_ATTRIBUTES_SET_SUCCESS,
  DELETE_ATTRIBUTES_SET_FAIL,
  CHANGE_ATTRIBUTES_SET_SUCCESS,
  UPDATE_ATTRIBUTES_SET_SUCCESS,
  UPDATE_ATTRIBUTES_POSITION,
  UPDATE_ATTRIBUTES_SCHEMA_WITH_POSITIONS,
} from './action';

const initialState = {
  attributes: {
    items: [],
    total: 0
  },
  isLoading: false,
  isInitial: false,
  err: null,
};

let modifiedData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_ATTRIBUTES_SETS_START:
  case WRITE_ATTRIBUTES_SET_START:
  case DELETE_ATTRIBUTES_SET_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_ATTRIBUTES_SETS_SUCCESS:
    return {
      ...state,
      attributes: { ...payload },
      isLoading: false,
      isInitial: true,
    };
  case WRITE_ATTRIBUTES_SET_SUCCESS:
    if (state.attributes.items[state.attributes.items.length - 1]?.id === payload.id) {
      return { ...state };
    }
    return {
      ...state,
      attributes: {
        ...state.attributes,
        items: [...state.attributes.items, { ...payload }],
        total: state.attributes.total + 1
      },
      isLoading: false
    };
  case DELETE_ATTRIBUTES_SET_SUCCESS:
    return {
      ...state,
      attributes: { ...state.attributes, items: state.attributes.items.filter((item) => item.id !== payload) },
      isLoading: false
    };
  case CHANGE_ATTRIBUTES_SET_SUCCESS:
    return {
      ...state,
      attributes: { ...state.attributes, items: state.attributes.items.map((item) => (item.id === payload.id ? { ...payload } : item)) },
      isLoading: false
    };
  case UPDATE_ATTRIBUTES_SET_SUCCESS:
    modifiedData = update(state.attributes.items, {
      $splice: [
        [payload.dragIndex, 1],
        [payload.hoverIndex, 0, payload.dragCard],
      ],
    });
    return {
      ...state,
      attributes: { ...state.attributes, items: modifiedData }
    };
  case FETCH_ATTRIBUTES_SETS_FAIL:
  case WRITE_ATTRIBUTES_SET_FAIL:
  case DELETE_ATTRIBUTES_SET_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  // case UPDATE_ATTRIBUTES_POSITION:
  //   return {
  //     ...state,
  //     attributes: {
  //       ...state.attributes,
  //       items: state.attributes.items
  //         .map((item) => (item.id === payload.id ? ({ ...item, position: payload.position }) : item))
  //         .sort((a, b) => b.position.localeCompare(a.position)),
  //     },
  //   };
  case UPDATE_ATTRIBUTES_POSITION:
    modifiedData = update(state.attributes.items, {
      $splice: [
        [payload.fromIndex, 1],
        [payload.toIndex, 0, payload.currentItem],
      ],
    });
    return {
      ...state,
      attributes: {
        ...state.attributes,
        items: modifiedData,
        total: payload.length
      },
    };

  case UPDATE_ATTRIBUTES_SCHEMA_WITH_POSITIONS:
    return {
      ...state,
      attributes: {
        ...state.attributes,
        items: state.attributes.items.map((item) => (item.id === payload.id ? { ...item, schema: payload.schema } : item))
      },
    };

  // @deprecated
  // case UPDATE_ATTRIBUTES_ITEM_POSITION:
  //   modifiedData = state.attributes.items.map((item) => {
  //     if (payload.a.id === item.id) {
  //       item.schema = payload.items.reduce((a, v, i) => ({ ...a, [`attribute${i + 1}`]: v }), {});
  //       return item;
  //     }
  //     return item;
  //   });
  //
  //   return {
  //     ...state,
  //     attributes: {
  //       ...state.attributes,
  //       items: modifiedData,
  //     },
  //     isLoading: false
  //   };
  default:
    return { ...state };
  }
};
