import {
  post, get, put, remove
} from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAIL = 'FETCH_TEMPLATES_FAIL';
export const FETCH_TEMPLATES_ALL_SUCCESS = 'FETCH_TEMPLATES_ALL_SUCCESS';
export const WRITE_TEMPLATE_START = 'WRITE_TEMPLATE_START';
export const WRITE_TEMPLATE_SUCCESS = 'WRITE_TEMPLATE_SUCCESS';
export const WRITE_TEMPLATE_FAIL = 'WRITE_TEMPLATE_FAIL';
export const DELETE_TEMPLATE_START = 'DELETE_TEMPLATE_START';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAIL = 'DELETE_TEMPLATE_FAIL';
export const CHANGE_TEMPLATE_SUCCESS = 'CHANGE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATES_POSITIONS = 'UPDATE_TEMPLATES_POSITIONS';

const fetchTemplatesStart = () => ({
  type: FETCH_TEMPLATES_START
});

const fetchTemplatesSuccess = (data, dataType) => ({
  type: FETCH_TEMPLATES_SUCCESS,
  payload: { data, dataType }
});

const fetchAllTemplatesSuccess = (data) => ({
  type: FETCH_TEMPLATES_ALL_SUCCESS,
  payload: data
});

const fetchTemplatesFail = (err) => ({
  type: FETCH_TEMPLATES_FAIL,
  payload: err
});

const writeTemplateStart = () => ({
  type: WRITE_TEMPLATE_START
});

const writeTemplateSuccess = (newTemplate) => ({
  type: WRITE_TEMPLATE_SUCCESS,
  payload: newTemplate
});

const writeTemplateFail = (err) => ({
  type: WRITE_TEMPLATE_FAIL,
  payload: err
});

const deleteTemplateStart = () => ({
  type: DELETE_TEMPLATE_START
});

const deleteTemplateSuccess = (payload) => {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    payload
  };
};

const deleteTemplateFail = (err) => ({
  type: DELETE_TEMPLATE_FAIL,
  payload: err
});

const changeTemplateSuccess = (payload) => ({
  type: CHANGE_TEMPLATE_SUCCESS,
  payload
});

export const updateTemplatesPositions = (payload) => ({
  type: UPDATE_TEMPLATES_POSITIONS,
  payload
});

export const fetchAllTemplates = (lang) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  try {
    const result = await get(`/templates/all?langCode=${lang}`);
    dispatch(fetchAllTemplatesSuccess(result.data));
  } catch (e) {
    dispatch(fetchTemplatesFail(e));
  }
};

export const fetchTemplates = (type, lang) => async (dispatch) => {
  dispatch(fetchTemplatesStart());
  try {
    const result = await get(`/templates?type=${type}&langCode=${lang}`);
    dispatch(fetchTemplatesSuccess(result.data, type));
  } catch (e) {
    dispatch(fetchTemplatesFail(e));
  }
};

// eslint-disable-next-line consistent-return
export const writeTemplate = (newTemplate, translations) => async (dispatch) => {
  dispatch(writeTemplateStart());
  try {
    const result = await post('/templates', newTemplate);
    dispatch(writeTemplateSuccess(result.data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return result.data;
  } catch (e) {
    dispatch(writeTemplateFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const deleteTemplate = (payload, translations) => async (dispatch) => {
  dispatch(deleteTemplateStart());
  try {
    await remove(`/templates/${payload.id}`);
    console.log('type', payload);
    dispatch(deleteTemplateSuccess(payload));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    console.log('e', e);
    dispatch(deleteTemplateFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const changeTemplate = (payload, translations, lang, silent = true) => async (dispatch) => {
  dispatch(writeTemplateStart());
  try {
    await put(`/templates/${payload.id}`, payload);
    dispatch(changeTemplateSuccess(payload));
    // @todo: улучшить
    dispatch(fetchAllTemplates(lang));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    dispatch(writeTemplateFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const getTemplatePage = (id) => async () => {
  try {
    const result = await get(`/templates/${id}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};
