import {
  FETCH_GET_AUTH_PERMISSIONS_SUCCESS,
  FETCH_GET_AUTH_PERMISSIONS_FAIL,
  FETCH_GET_AUTH_PERMISSIONS_START,
  UPDATE_AUTH_PERMISSIONS_START,
  UPDATE_AUTH_PERMISSIONS_SUCCESS,
  UPDATE_AUTH_PERMISSIONS_FAIL,
} from './action';

const initialState = {
  permissions: null,
  isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_GET_AUTH_PERMISSIONS_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_GET_AUTH_PERMISSIONS_SUCCESS:
    return {
      ...state,
      permissions: payload,
      isLoading: false
    };
  case FETCH_GET_AUTH_PERMISSIONS_FAIL:
    return {
      ...state,
      isLoading: false
    };
  case UPDATE_AUTH_PERMISSIONS_START:
    return {
      ...state,
      isLoading: true
    };
  case UPDATE_AUTH_PERMISSIONS_SUCCESS:
    return {
      ...state,
      permissions: { ...payload },
    };
  case UPDATE_AUTH_PERMISSIONS_FAIL:
    return {
      ...state,
      isLoading: false
    };
  default:
    return { ...state };
  }
};