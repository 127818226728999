import {
  FETCH_MARKERS_START,
  FETCH_MARKERS_SUCCESS,
  FETCH_MARKERS_FAIL,
  CREATE_MARKER_START,
  CREATE_MARKER_SUCCESS,
  CREATE_MARKER_FAIL,
  UPDATE_MARKER_START,
  UPDATE_MARKER_SUCCESS,
  UPDATE_MARKER_FAIL,
  DELETE_MARKER_START,
  DELETE_MARKER_SUCCESS,
  DELETE_MARKER_FAIL,
  UPDATE_MARKER_POSITION,
} from './action';

const initialState = {
  markers: [],
  isLoading: false,
  isInitial: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_MARKERS_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_MARKERS_SUCCESS:
    return {
      ...state,
      markers: [...payload.items],
      isLoading: false,
      isInitial: true,
    };
  case FETCH_MARKERS_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };
  case CREATE_MARKER_START:
    return {
      ...state,
      isLoading: true,
    };
  case CREATE_MARKER_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case CREATE_MARKER_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  case UPDATE_MARKER_START:
    return {
      ...state,
      isLoading: true,
    };
  case UPDATE_MARKER_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };
  case UPDATE_MARKER_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  case DELETE_MARKER_START:
    return {
      ...state,
      isLoading: true,
    };
  case DELETE_MARKER_SUCCESS:
    return {
      markers: state.markers.filter((marker) => marker.id !== payload),
      isLoading: false,
    };
  case DELETE_MARKER_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };
  case UPDATE_MARKER_POSITION:
    return {
      ...state,
      markers: [...payload],
      isLoading: false
    };
  default:
    return { ...state };
  }
};
