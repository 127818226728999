import React, { useEffect, useState } from 'react';
import { MISSING_LANG_COLOR } from '@constants/settings';
import { Tooltip } from 'reactstrap';
// eslint-disable-next-line import/no-cycle
import { uuidv4 } from '@utils/system/system.utils';
// eslint-disable-next-line import/no-cycle
import { useApp } from '@hooks/useApp';

export default function LangNotPresent({ isLangPresent, existedLangCode, className = '' }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [id, setId] = useState(false);
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
    setSelectedTooltip(false);
  };
  const [selectedTooltip, setSelectedTooltip] = useState(false);
  const { translations } = useApp();

  useEffect(() => setId(uuidv4()), []);

  return (
    <>
      {id && (
        <>
          {!isLangPresent && (
            <sub
              className={className}
              onMouseEnter={() => {
                setSelectedTooltip(id);
                setTooltipOpen(true);
              }}
              id={`tooltip-${id}`}
              style={{ color: MISSING_LANG_COLOR }}
            >
              {existedLangCode}
            </sub>
          )}
          {id === selectedTooltip && (
            <Tooltip
              isOpen={tooltipOpen}
              placement="right"
              toggle={toggle}
              target={`tooltip-${id}`}
            >
              {`${translations.lang_not_present} ${
                translations[existedLangCode]
                  ? `(${translations[existedLangCode]})` : ''
              }`}
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}
