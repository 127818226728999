import {
  FETCH_PRODUCT_STATUSES_SUCCESS,
  OPERATION_PRODUCT_STATUS_SUCCESS,
  OPERATION_PRODUCT_STATUS_FAIL,
  FETCH_PRODUCT_STATUSES_FAIL,
  OPERATION_PRODUCT_STATUSES_START, DELETE_PRODUCT_STATUS_SUCCESS,
} from './action';

const initialState = {
  items: [],
  isLoading: true,
  isInitial: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case OPERATION_PRODUCT_STATUSES_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_PRODUCT_STATUSES_SUCCESS:
    return {
      items: [...payload],
      isLoading: false,
      isInitial: true,
      error: null,
    };

  case DELETE_PRODUCT_STATUS_SUCCESS:
    return {
      items: state.items.filter((item) => item.id !== payload),
      isLoading: false,
      isInitial: true,
      error: null
    };

  case OPERATION_PRODUCT_STATUS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
      error: null,
    };

  case OPERATION_PRODUCT_STATUS_FAIL:
  case FETCH_PRODUCT_STATUSES_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
      error: payload,
    };
  default:
    return { ...state };
  }
};
