import {
  post,
  get,
  remove,
  put,
} from '../../api';

import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const FETCH_USER_LIST_START = 'FETCH_USER_LIST_START';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

const addUserStart = () => ({
  type: ADD_USER_START
});

export const addUserSuccess = (data) => ({
  type: ADD_USER_SUCCESS,
  payload: data
});
const addUserFail = (err) => ({
  type: ADD_USER_FAIL,
  payload: err
});

const fetchUserListStart = () => ({
  type: FETCH_USER_LIST_START
});
export const fetchUserListSuccess = (data) => ({
  type: FETCH_USER_LIST_SUCCESS,
  payload: data
});
const fetchUserListFail = (err) => ({
  type: FETCH_USER_LIST_FAIL,
  payload: err
});

const deleteUserStart = () => ({
  type: DELETE_USER_START
});

export const deleteUserSuccess = (id) => ({
  type: DELETE_USER_SUCCESS,
  payload: id
});

const deleteUserFail = (err) => ({
  type: DELETE_USER_FAIL,
  payload: err
});

const updateUserStart = () => ({
  type: UPDATE_USER_START
});

const updateUserSuccess = (data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: data
});

const updateUserFail = (err) => ({
  type: UPDATE_USER_FAIL,
  payload: err
});

export const addUser = (authProviderId, data, LOCALE) => async (dispatch) => {
  dispatch(addUserStart());
  try {
    const result = await post(`/users-auth-providers/${authProviderId}/users/sign-up`, data);
    dispatch(addUserSuccess(result.data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(LOCALE));
    return true;
  } catch (e) {
    dispatch(addUserFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(LOCALE));
    return false;
  }
};

export const fetchUserData = (id) => async () => {
  try {
    const result = await get(`/users/${id}`);
    return result.data;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const fetchUserList = () => async (dispatch) => {
  dispatch(fetchUserListStart());
  try {
    const result = await get('/users');
    dispatch(fetchUserListSuccess(result.data));
  } catch (e) {
    dispatch(fetchUserListFail(e));
  }
};

export const deleteUser = (id, LOCALE, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteUserStart());
  try {
    await remove(`/users/${id}`);
    dispatch(deleteUserSuccess(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(LOCALE));
  } catch (e) {
    dispatch(deleteUserFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(LOCALE));
  }
};

export const updateUser = (data, LOCALE) => async (dispatch) => {
  dispatch(updateUserStart());
  try {
    await put(`/users/${data.id}`, data);
    dispatch(updateUserSuccess());
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(LOCALE));
    return true;
  } catch (e) {
    dispatch(updateUserFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(LOCALE));
    return false;
  }
};

export const checkUserLogin = (login) => async () => {
  try {
    const result = await post('/users/check-login-existence', { login });
    return result.data.exist;
  } catch (e) {
    console.error(e);
  }
};
