import { cloneDeep } from 'lodash';
import {
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAIL,
  WRITE_TEMPLATE_START,
  WRITE_TEMPLATE_SUCCESS,
  WRITE_TEMPLATE_FAIL,
  DELETE_TEMPLATE_START,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAIL,
  CHANGE_TEMPLATE_SUCCESS,
  FETCH_TEMPLATES_ALL_SUCCESS,
  UPDATE_TEMPLATES_POSITIONS
} from './action';

const initialState = {
  isLoading: true,
  isInitial: false,
  err: null,
};

let modifiedData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_TEMPLATES_START:
  case WRITE_TEMPLATE_START:
  case DELETE_TEMPLATE_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_TEMPLATES_SUCCESS:
    // eslint-disable-next-line no-case-declarations
    const { data, dataType } = payload;
    return {
      ...state,
      [dataType]: [...data],
      isLoading: false,
      isInitial: true,
    };
  case FETCH_TEMPLATES_ALL_SUCCESS:
    return {
      ...initialState,
      ...payload,
      isInitial: true,
      isLoading: false,
    };
  case WRITE_TEMPLATE_SUCCESS:
    if (!state[payload.type]) {
      return {
        ...state,
        isLoading: false,
        [payload.type]: [payload],
      };
    }

    return {
      ...state,
      isLoading: false,
      [payload.type]: [...state[payload.type], payload]
    };
  case DELETE_TEMPLATE_SUCCESS:
    modifiedData = state[payload.type].filter((item) => item.id !== payload.id);

    if (!modifiedData.length) {
      const clonedState = cloneDeep(state);
      delete clonedState[payload.type];

      return { ...clonedState };
    }

    return {
      ...state,
      [payload.type]: state[payload.type].filter((item) => item.id !== payload.id)
    };
  case CHANGE_TEMPLATE_SUCCESS:
    // @todo: особо в этом нет смысла имхо, AVM
    Object.entries(state).forEach(([key]) => {
      if (!['isLoading', 'err'].includes(key)) {
        state[key] = Array.isArray(state[key])
          ? state[key].map((item) => (item.id === payload.id ? payload : item))
          : state[key];
      }
    });
    return {
      ...state
    };

  case UPDATE_TEMPLATES_POSITIONS:
    Object.entries(state).forEach(([key]) => {
      if ([payload.typeName].includes(key)) {
        state[payload.typeName] = payload.items;
      }
    });
    return {
      ...state
    };
  case FETCH_TEMPLATES_FAIL:
  case WRITE_TEMPLATE_FAIL:
  case DELETE_TEMPLATE_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };
  default:
    return { ...state };
  }
};
