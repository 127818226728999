import {
  FETCH_EVENTS_START,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_FAIL,
  FETCH_EVENT_START,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAIL,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_START,
  CREATE_EVENT_FAIL,
  DELETE_EVENT_START,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  UPDATE_EVENT,
  FETCH_FIREBASE_START,
  FETCH_FIREBASE_SUCCESS,
  FETCH_FIREBASE_FAIL,
  FETCH_EMAIL_SETTINGS_START, FETCH_EMAIL_SETTINGS_SUCCESS, FETCH_EMAIL_SETTINGS_FAIL,
} from './event';

const initialState = {
  events: {
    items: [],
    err: null,
  },
  event: {
    item: null,
    err: null,
  },
  firebase: {
    data: null,
    loading: true,
    err: null,
  },
  emailSettings: {
    data: null,
    loading: true,
    err: null,
  },
  isLoading: true,
  isInitial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_EVENTS_START:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          items: [...payload],
        },
        isLoading: false,
        isInitial: true,
      };

    case FETCH_EVENTS_FAIL:
      return {
        ...state,
        events: {
          ...state.events,
          err: payload,
        },
        isLoading: false,
        isInitial: true,
      };

    case FETCH_EVENT_START:
      return {
        ...state,
        event: { item: null },
        isLoading: true,
      };

    case FETCH_EVENT_SUCCESS:
      return {
        ...state,
        event: { item: payload },
        isLoading: false,
        isInitial: true,
      };

    case FETCH_EVENT_FAIL:
      return {
        ...state,
        event: {
          ...state.action,
          err: payload,
        },
        isLoading: false,
        isInitial: true,
      };

    case CREATE_EVENT_START:
      return {
        ...state,
        isLoading: true,
      };

    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        events: {
          items: [...state.events.items, { ...payload }],
          err: null,
        },
        isLoading: false,
        isInitial: true,
      };

    case CREATE_EVENT_FAIL:
      return {
        ...state,
        event: {
          ...state.event,
          err: payload,
        },
        isLoading: false,
        isInitial: true,
      };

    case DELETE_EVENT_START:
      return {
        ...state,
        isLoading: true,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: {
          ...state.events,
          items: state.events.items.filter((b) => b.id !== payload),
        },
        isLoading: false,
        isInitial: true,
      };

    case DELETE_EVENT_FAIL:
      return {
        ...state,
        event: {
          ...state.event,
          err: payload,
        },
        isLoading: false,
        isInitial: true,
      };

    case UPDATE_EVENT:
      return {
        ...state,
        event: {
          item: payload
        },
      };

    case FETCH_FIREBASE_START:
      return {
        ...state,
        firebase: {
          ...state.firebase,
          loading: true,
        }
      };

    case FETCH_FIREBASE_SUCCESS:
      return {
        ...state,
        firebase: {
          ...state.firebase,
          data: payload,
          loading: false
        }
      };

    case FETCH_FIREBASE_FAIL:
      return {
        ...state,
        firebase: {
          ...state.firebase,
          err: payload,
        }
      };

    case FETCH_EMAIL_SETTINGS_START:
      return {
        ...state,
        emailSettings: {
          ...state.emailSettings,
          loading: true
        }
      };

    case FETCH_EMAIL_SETTINGS_SUCCESS:
      return {
        ...state,
        emailSettings: {
          ...state.emailSettings,
          loading: false,
          data: payload
        }
      };

    case FETCH_EMAIL_SETTINGS_FAIL:
      return {
        ...state,
        emailSettings: {
          ...state.emailSettings,
          loading: false,
          err: payload
        }
      };

    default:
      return { ...state };
  }
};
