let BASE_URL = '';
let SYNC_URL = '';
/**
 * если мы работаем из под воркера
 */
if (typeof window !== 'undefined') {
  BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : window.location.origin;
  const baseUrlObject = new URL(BASE_URL);
  SYNC_URL = process.env.REACT_APP_SYNC_SERVER_URL || `wss://${baseUrlObject.hostname}/socket`;
}
export const API_URL = `${BASE_URL}/api/admin`;
export const SOCKET_URL = BASE_URL;
export const LANGUAGES = [
  'ru_RU',
  'ae_AE',
  'hy_HY',
  'ka_KA',
  'az_AZ',
  'uz_UZ',
  'kk_KK',
  'en_US',
  'fr_FR',
  'es_ES',
  'he_HE',
  'tr_TR',
  'de_DE',
  'it_IT'
];
export const AUTH_PATH = '/authentication/login';
export const IS_DEV = !!process.env.REACT_APP_IS_DEV;
export const DETECT_LANG_URL = 'https://ipapi.co/json';
export const IS_EMIT_VALUES = !!process.env.REACT_APP_IS_EMIT_VALUES;
export const RAISE_ERROR_ON_EMPTY_INFORM_ID = !!process.env.REACT_APP_RAISE_ERROR_ON_EMPTY_INFORM_ID;
export const BASE_API_URL = BASE_URL;
export const GRAFANA_URL = process.env.REACT_APP_GRAFANA_URL;
export const GRAFANA_APIKEY = process.env.REACT_APP_GRAFANA_APIKEY;
export const DEFAULT_OFFSET = 30;
export const IS_LOCAL_HOST = BASE_URL && BASE_URL.includes('localhost');

/**
 * Время, в течении которого мы ожидаем, что данные для пользователя
 * при инициализации будут полностью загружены (сек)
 */
export const REASONABLE_LOADING_TIME = 190;

/**
 * Время (по умолчанию) для функицй debounce
 */
export const DEFAULT_DEBOUNCE_TIME = 300;

/**
 * Время (по умолчанию) для автосохранения (сек)
 */
export const DEFAULT_AUTO_SAVE_TIME = 30;

/**
 * Название разрешения для хранения доступных модулей для админа
 */
export const ADMIN_MODULES_PERMISSION_NAME = 'admins.modules';

/**
 * Маркер (по умолчанию) для молуля Администраторы
 */
export const ADMIN_MODULE_DEFAULT_MARKER = 'admins';

/**
 * Идентификатор дефолтного админа (задается через сиды и всегда = 1)
 */
export const ADMIN_DEFAULT_ID = 1;

/**
 * Максимальное количество товаров, разрешенных для импорта
 */
export const SYSTEM_MAX_IMPORT_ALLOWED_PRODUCTS = 3000;

/**
 * Время, в течении которого актуальна блокировка
 */
export const ATTRIBUTE_CHANGE_TIME = 5000; // 5 сек

export const THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT'
};

export const DEFAULT_UI_LANG = navigator.language?.replace('-', '_') || 'en_US';
export const DEFAULT_UI_THEME = THEMES.LIGHT;
export const DEFAULT_CONTENT_LANG = 'en_US';
export const MISSING_LANG_COLOR = 'orange';

export const SYNC_SERVER_URL = SYNC_URL;

export const CATALOG_CONFIG_MIN_VALUE = 1;
export const CATALOG_CONFIG_MAX_VALUE = 100;
