/* eslint-disable no-case-declarations */
import {
  FETCH_ADMIN_LIST_SUCCESS,
  FETCH_ADD_ADMINS_MODULES,
  FETCH_ADD_ADMIN_SUCCESS,
  FETCH_DELETE_ADMIN_SUCCESS,
  FETCH_ADMIN_LIST_START,
  FETCH_ADMIN_DATA_START,
  FETCH_ADMIN_DATA_SUCCESS,
  FETCH_ADMIN_DATA_FAIL,
  HIGH_PING,
  FETCH_UPDATE_ADMIN_START,
  FETCH_UPDATE_ADMIN_SUCCESS,
  FETCH_UPDATE_ADMIN_FAIL,
  UPDATE_ADMIN_PERMISSIONS_SUCCESS,
  SET_SELECTED_ADMIN,
  UPDATE_ADMIN_POSITIONS,
} from './action';

const initialState = {
  data: [],
  isLoading: true,
  isInitial: false,
  err: null,
  adminData: {},
  selectedAdmin: {},
  isHighPing: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case SET_SELECTED_ADMIN:
    return {
      ...state,
      selectedAdmin: payload,
    };
  case HIGH_PING:
    return {
      ...state,
      isHighPing: payload
    };
  case FETCH_ADMIN_DATA_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_ADMIN_LIST_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_ADMIN_DATA_SUCCESS:
    return {
      ...state,
      adminData: payload,
      isLoading: false,
      isInitial: true,
    };
  case FETCH_ADMIN_DATA_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  case FETCH_ADMIN_LIST_SUCCESS:
    return {
      ...state,
      data: payload,
      isLoading: false,
      isInitial: true,
    };
  case FETCH_ADD_ADMINS_MODULES:
    return {
      ...state,
      listModules: payload,
      isLoading: false,
      isInitial: true,
    };
  case FETCH_ADD_ADMIN_SUCCESS:
    return {
      ...state,
      data: [...state.data, payload],
      isLoading: false,
      isInitial: true,
    };
  case FETCH_DELETE_ADMIN_SUCCESS:
    return {
      ...state,
      data: state.data.filter((item) => item.id !== payload),
      isLoading: false,
      isInitial: true,
    };
  case FETCH_UPDATE_ADMIN_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_UPDATE_ADMIN_SUCCESS:
    const updatedAdmins = state.data.map((item) => {
      if (+item.id === +payload.id) {
        return payload;
      }
      return item;
    });
    return {
      ...state,
      data: updatedAdmins,
      isLoading: false,
      isInitial: true,
    };
  case UPDATE_ADMIN_PERMISSIONS_SUCCESS:
    const updatedAdminsPermissions = state.data.map((item) => {
      if (item.id === Number(payload.id)) {
        item.permissions = { ...payload.permissions };
        return item;
      }
      return item;
    });
    return {
      ...state,
      data: updatedAdminsPermissions,
      isLoading: false,
      isInitial: true,
    };
  case FETCH_UPDATE_ADMIN_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  case UPDATE_ADMIN_POSITIONS: {
    return {
      ...state,
      data: state.data
        .map((item) => (item.id === payload.id ? ({ ...item, position: payload.position }) : item))
        .sort((a, b) => b.position.localeCompare(a.position)),
    };
  }
  default:
    return { ...state };
  }
};
