import { createSelector } from 'reselect';

export const blockSelectors = {
  getAll: (state) => state.blocksReducer.blocks?.items,
  getBlock: (state) => state.blocksReducer.block?.item,
  isLoading: (state) => state.blocksReducer.isLoading,
};

/**
 * Блоки, сгруппированные по типам
 */
export const selectGroupedBlocks = createSelector([
  (state) => state.blocks?.items,
  (state, types) => types], (blocks, types = []) => {
  const result = {};
  if (Array.isArray(blocks)) {
    blocks.sort((a, b) => a.id - b.id).forEach((b) => {
      const type = types.find((t) => +t?.id === +b?.generalTypeId);
      if (type?.id) {
        if (Object.prototype.hasOwnProperty.call(result, type.type)) {
          result[type.type].push(b);
        } else {
          result[type.type] = [];
          result[type.type].push(b);
        }
      }
    });
  }
  return result;
});
