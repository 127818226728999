import axios from 'axios';
import {
  API_URL, AUTH_PATH, IS_DEV
} from '../constants/settings';
import { TOTAL_NUMBER_OF_ADMINS_EXCEEDS_ERROR, TOTAL_NUMBER_OF_RECORDS_EXCEEDS_ERROR } from '../constants/erros';
// eslint-disable-next-line import/no-cycle
import { emitMessage, trimObject } from '../utils/system/system.utils';

class Api {
  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
    });
    this.accessToken = '';
    this.refreshToken = '';
    this.langCode = 'en_US';
  }

  getToken = () => this.accessToken;

  setToken = (accessToken, refreshToken) => {
    if (accessToken) {
      this.client.defaults.headers.common.authorization = `Bearer ${accessToken}`;
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    } else {
      delete this.client.defaults.headers.common.authorization;
      this.accessToken = '';
      this.refreshToken = '';
    }
  };

  post = async (url, data, params, trimStrings = true) => {
    const trimmedData = trimStrings ? trimObject(data) : data;
    return this.client.post(url, trimmedData, params);
  };

  get = async (url, params = {}) => {
    return this.client.get(url, params);
  };

  put = async (url, data, params, trimStrings = true) => {
    const trimmedData = trimStrings ? trimObject(data) : data;
    return this.client.put(url, trimmedData, params);
  };

  remove = async (url, params = {}) => {
    return this.client.delete(url, params);
  };

  patch = async (url, data, params = {}) => {
    return this.client.patch(url, data, params);
  };
}

const api = new Api();

api.client.interceptors.response.use(
  (response) => {
    if (typeof window !== 'undefined' && ['post', 'delete'].includes(response.config.method.toLocaleLowerCase())) {
      emitMessage('update-config', true, 'update-config');
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 && api.accessToken) {
      const originalRequest = error.config;
      return axios.post(`${API_URL}/auth/refresh`, {
        refreshToken: api.refreshToken
      }).then((res) => {
        if (res.status === 201) {
          api.setToken(res.data.accessToken, res.data.refreshToken);
          api.accessToken = res.data.accessToken;
          api.refreshToken = res.data.refreshToken;
          document.cookie = `accessToken=${res.data.accessToken}; path=/`;
          document.cookie = `refreshToken=${res.data.refreshToken}; path=/`;
          document.cookie = `userId=${res.data.userId}; path=/`;
          originalRequest.headers.authorization = `Bearer ${res.data.accessToken}`;
          return axios(originalRequest);
        }
        document.cookie = 'login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'userId=; path=/';
        document.cookie = 'appId=; path=/';
        window.location = `${window.origin}${AUTH_PATH}`;
      }).catch(() => {
        document.cookie = 'login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'userId=; path=/';
        document.cookie = 'appId=; path=/';
        window.location = `${window.origin}${AUTH_PATH}`;
      });
    }

    if (error?.response?.status === 405) {
      if (error?.response?.data?.message.toLowerCase().startsWith(TOTAL_NUMBER_OF_RECORDS_EXCEEDS_ERROR)) {
        emitMessage('records-exceeds', true, 'records-exceeds');
      }
      if (error?.response?.data?.message.toLowerCase().startsWith(TOTAL_NUMBER_OF_ADMINS_EXCEEDS_ERROR)) {
        emitMessage('admins-exceeds', true, 'admins-exceeds');
      }
    }

    if (error?.response?.status === 403) {
      emitMessage('forbidden-resource', null, 'forbidden-resource');
    }

    if (+error?.response?.status >= 500 && typeof window !== 'undefined') {
      if (IS_DEV) {
        console.error(error?.response?.status, error?.response?.data);
      } else {
        // window.location = `${window.origin}/errors/50x`;
        // @todo: temporally
        console.error(error?.response?.status, error?.response?.data);
      }
    } else {
      console.error(error?.response?.status, error?.response?.data);
    }

    /**
     * Если ошибка не содержит response или не является объектом Axios
     */
    if (!error.response && !axios.isCancel(error)) {
      console.log('Check your Internet connection.');
    }

    return Promise.reject(error);
  }
);

export const {
  post,
  get,
  put,
  remove,
  setToken,
  patch,
  getToken
} = api;

export default api;
