import { get, post, remove } from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';

export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL';

export const FETCH_FIREBASE_START = 'FETCH_FIREBASE_START';
export const FETCH_FIREBASE_SUCCESS = 'FETCH_FIREBASE_SUCCESS';
export const FETCH_FIREBASE_FAIL = 'FETCH_FIREBASE_FAIL';

export const FETCH_EMAIL_SETTINGS_START = 'FETCH_EMAIL_SETTINGS_START';
export const FETCH_EMAIL_SETTINGS_SUCCESS = 'FETCH_EMAIL_SETTINGS_SUCCESS';
export const FETCH_EMAIL_SETTINGS_FAIL = 'FETCH_EMAIL_SETTINGS_FAIL';

export const FETCH_EVENT_START = 'FETCH_EVENT_START';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAIL = 'FETCH_EVENT_FAIL';

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const UPDATE_EVENT = 'UPDATE_EVENT';

export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const UPDATE_EVENTS_POSITION = 'UPDATE_EVENTS_POSITION';

export const fetchFirebase = () => async (dispatch) => {
  dispatch({ type: FETCH_FIREBASE_START });
  try {
    const result = await get('/events/settings/firebase');

    if (result.status === 204) {
      dispatch({ type: FETCH_FIREBASE_SUCCESS, payload: undefined });
    } else {
      dispatch({ type: FETCH_FIREBASE_SUCCESS, payload: result.data });
    }
  } catch (e) {
    dispatch({ type: FETCH_FIREBASE_FAIL, payload: e });
  }
};

export const fetchEmailSettings = () => async (dispatch) => {
  dispatch({ type: FETCH_EMAIL_SETTINGS_START });
  try {
    const result = await get('/events/settings/sender-settings');
    dispatch({ type: FETCH_EMAIL_SETTINGS_SUCCESS, payload: result.data });
  } catch (e) {
    dispatch({ type: FETCH_EMAIL_SETTINGS_FAIL, payload: e });
  }
};

const fetchEventsStart = () => ({
  type: FETCH_EVENTS_START,
});

export const fetchEventsSuccess = (payload) => ({
  type: FETCH_EVENTS_SUCCESS,
  payload,
});

const fetchEventsFail = (error) => ({
  type: FETCH_EVENTS_FAIL,
  payload: error,
});

export const fetchEvents = ({ limit, offset, lang }) => async (dispatch) => {
    dispatch(fetchEventsStart());
    try {
      const result = await get(`/events?limit=${limit}&offset=${offset}&langCode=${lang}`);
      dispatch(fetchEventsSuccess(result.data.items));
    } catch (e) {
      dispatch(fetchEventsFail(e));
    }
  };

const fetchEventStart = (id) => ({
  type: FETCH_EVENT_START,
  payload: id,
});

const fetchEventSuccess = (payload) => ({
  type: FETCH_EVENT_SUCCESS,
  payload,
});

const fetchEventFail = (error) => ({
  type: FETCH_EVENT_FAIL,
  payload: error,
});

export const fetchEvent = (id) => async (dispatch) => {
  dispatch(fetchEventStart());
  try {
    const result = await get(`/events/${id}`);

    dispatch(fetchEventSuccess(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchEventFail(e));
  }
};

const createEventStart = () => ({
  type: CREATE_EVENT_START,
});

const createEventSuccess = (payload) => ({
  type: CREATE_EVENT_SUCCESS,
  payload,
});

const createEventFail = (error) => ({
  type: CREATE_EVENT_FAIL,
  payload: error,
});

export const createEvent = (event, translations, setNewItemProcess) => async (dispatch) => {
  dispatch(createEventStart());
  try {
    const result = await post('/events', event);
    if (result?.data) {
      dispatch(createEventSuccess(result?.data));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    } else {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    setNewItemProcess((prev) => prev.filter((elId) => elId !== event.identifier));
  } catch (e) {
    dispatch(createEventFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const updateEventsPosition = (payload) => ({
  type: UPDATE_EVENTS_POSITION,
  payload,
});

export const updateEvent = (event) => ({
  type: UPDATE_EVENT,
  payload: event,
});

const deleteEventStart = () => ({
  type: DELETE_EVENT_START,
});

const deleteEventSuccess = (payload) => ({
  type: DELETE_EVENT_SUCCESS,
  payload,
});

const deleteEventFail = (error) => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
});

export const deleteEvent = (id, translations) => async (dispatch) => {
  dispatch(deleteEventStart());
  try {
    await remove(`/events/${id}`);
    dispatch(deleteEventSuccess(id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(deleteEventFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};
