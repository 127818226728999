import {
  OPERATION_ORDER_STATUSES_START,
  FETCH_ORDER_STATUSES_SUCCESS,
  DELETE_ORDER_STATUS_SUCCESS,
  OPERATION_ORDER_STATUS_SUCCESS,
  OPERATION_ORDER_STATUS_FAIL,
} from './action';

const initialState = {
  items: [],
  isLoading: false,
  isInitial: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case OPERATION_ORDER_STATUSES_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_ORDER_STATUSES_SUCCESS:
    return {
      items: [...payload],
      isLoading: false,
      isInitial: true,
      error: null
    };

  case DELETE_ORDER_STATUS_SUCCESS:
    return {
      items: state.items.filter((item) => item.id !== payload),
      isLoading: false,
      isInitial: true,
      error: null,
    };

  case OPERATION_ORDER_STATUS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
      error: null,
    };

  case OPERATION_ORDER_STATUS_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
      error: payload,
    };
  default:
    return { ...state };
  }
};
