import {
  post, get, put, remove
} from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';

export const FETCH_ORDER_STATUSES_SUCCESS = 'FETCH_ORDER_STATUSES_SUCCESS';
export const OPERATION_ORDER_STATUSES_START = 'OPERATION_ORDER_STATUSES_START';
export const OPERATION_ORDER_STATUS_SUCCESS = 'OPERATION_ORDER_STATUS_SUCCESS';
export const OPERATION_ORDER_STATUS_FAIL = 'OPERATION_ORDER_STATUS_SUCCESS';

export const DELETE_ORDER_STATUS_SUCCESS = 'DELETE_ORDER_STATUS_SUCCESS';

export const fetchOrderStatusesSuccess = (data) => ({
  type: FETCH_ORDER_STATUSES_SUCCESS,
  payload: data
});

const operationOrderStatusesFail = (err) => ({
  type: OPERATION_ORDER_STATUS_FAIL,
  payload: err
});

const operationOrderStatusesStart = () => ({
  type: OPERATION_ORDER_STATUSES_START,
});

const operationOrderStatusesSuccess = () => ({
  type: OPERATION_ORDER_STATUS_SUCCESS
});

const deleteOrderStatusSuccess = (id) => ({
  type: DELETE_ORDER_STATUS_SUCCESS,
  payload: id
});

export const fetchOrderStatuses = ({ storageId, limit, offset, lang }) => async (dispatch) => {
  try {
    dispatch(operationOrderStatusesStart());
    const result = await get(`/orders-storage/${storageId}/order-statuses?limit=${limit}&offset=${offset}&langCode=${lang}`);
    dispatch(fetchOrderStatusesSuccess(result.data));
  } catch (e) {
    dispatch(operationOrderStatusesFail(e));
  }
};

export const addOrderStatus = ({ storageId, orderStatus, lang }, translations, setNewItemProcess) =>
  async (dispatch) => {
    try {
      dispatch(operationOrderStatusesStart());
      await post(`/orders-storage/${storageId}/order-statuses`, orderStatus);
      dispatch(fetchOrderStatuses({ storageId, limit: 300, offset: 0, lang }));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      dispatch(operationOrderStatusesSuccess());
      setNewItemProcess((prev) => prev.filter((elId) => elId !== orderStatus.identifier));
    } catch (e) {
      console.log(e);
      dispatch(operationOrderStatusesFail(e));
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
  };

export const deleteOrderStatus = ({ storageId, id }, translations, setDeleteProcess) => async (dispatch) => {
  try {
    dispatch(operationOrderStatusesStart());
    await remove(`/orders-storage/${storageId}/order-statuses/${id}`);
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
    dispatch(deleteOrderStatusSuccess(id));
  } catch (e) {
    dispatch(operationOrderStatusesFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const updateOrderStatus = ({ storageId, orderStatus, lang }, translations) => async (dispatch) => {
  try {
    dispatch(operationOrderStatusesStart());
    await put(`/orders-storage/${storageId}/order-statuses/${orderStatus.id}`, orderStatus);
    dispatch(operationOrderStatusesSuccess());
    dispatch(fetchOrderStatuses({ storageId, limit: 300, offset: 0, lang }));
    return true;
  } catch (e) {
    console.log(e);
    dispatch(operationOrderStatusesFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const getOrderStatusPage = (storageId, id) => async () => {
  try {
    const result = await get(`/orders-storage/${storageId}/order-statuses/${id}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};
