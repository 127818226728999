import {
  get,
  put,
} from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '@constants/notification-settings';

/**
 * Настройки системы, модулей и админов
 *
 */

export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_COMMON_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAIL = 'FETCH_COMMON_SETTINGS_FAIL';

export const SET_LANGUAGE_START = 'SET_LANGUAGE_START';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_FAIL = 'SET_LANGUAGE_FAIL';

export const SET_THEME_START = 'SET_THEME_START';
export const SET_THEME_SUCCESS = 'SET_THEME_SUCCESS';
export const SET_THEME_FAIL = 'SET_THEME_FAIL';

export const UPDATE_DISK_USED_VALUE = 'UPDATE_DISK_USED_VALUE';

const fetchSettingsStart = () => ({
  type: FETCH_SETTINGS_START
});

const setLanguageStart = () => ({
  type: SET_LANGUAGE_START
});

export const setLanguageSuccess = (payload) => ({
  type: SET_LANGUAGE_SUCCESS,
  payload
});

const setLanguageFail = (err) => ({
  type: SET_LANGUAGE_FAIL,
  payload: err
});

const fetchSettingsSuccess = (payload) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload
});

const fetchSettingsFail = (payload) => ({
  type: FETCH_SETTINGS_FAIL,
  payload
});

const setThemeStart = () => ({
  type: SET_THEME_START
});

export const setThemeSuccess = (payload) => ({
  type: SET_THEME_SUCCESS,
  payload
});

export const updateDiskUsedValue = (payload) => ({
  type: UPDATE_DISK_USED_VALUE,
  payload
});

const setThemeFail = (payload) => ({
  type: SET_THEME_FAIL,
  payload
});

export const getSettings = () => async (dispatch) => {
  dispatch(fetchSettingsStart());
  try {
    const result = await get('/settings-general');
    const settings = {
      ...result?.data
    };
    dispatch(fetchSettingsSuccess(settings || {}));
  } catch (err) {
    dispatch(fetchSettingsFail(err));
  }
};

export const updateSettings = (payload, translations) => async (dispatch) => {
  try {
    await put('/settings-general', payload);
    dispatch(fetchSettingsSuccess(payload));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(fetchSettingsFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const setLanguage = (lang) => async (dispatch, getState) => {
  dispatch(setLanguageStart());
  const currentLoggedUser = getState().authReducer?.data;
  const { email, login, id } = currentLoggedUser;
  const data = {
    login,
    email,
    selectedLanguage: lang
  };
  try {
    await put(`/admins/${id}`, data);
    dispatch(setLanguageSuccess(lang));
  } catch (err) {
    dispatch(setLanguageFail(err));
  }
};

export const setContentLanguage = (lang) => async (dispatch, getState) => {
  dispatch(setLanguageStart());
  const currentLoggedUser = getState().authReducer?.data;
  const { email, login, id } = currentLoggedUser;
  const data = {
    login,
    email,
    selectedContentLanguage: lang
  };
  try {
    await put(`/admins/${id}`, data);
  } catch (err) {
    dispatch(setLanguageFail(err));
  }
};

export const setTheme = (theme) => async (dispatch, getState) => {
  dispatch(setThemeStart());
  const currentLoggedUser = getState().authReducer?.data;
  const { email, login, id } = currentLoggedUser;
  const data = {
    login,
    email,
    selectedUITheme: theme
  };
  try {
    localStorage.setItem('activeTheme', theme);
    await put(`/admins/${id}`, data);
    dispatch(setThemeSuccess(theme));
  } catch (err) {
    dispatch(setThemeFail(err));
  }
};
