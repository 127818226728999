import { get } from '@api';
import { JOURNAL_LIMIT } from '../../constants/journal.constants';

export const FETCH_JOURNAL_START = 'FETCH_JOURNAL_START';
export const FETCH_JOURNAL_SUCCESS = 'FETCH_JOURNAL_SUCCESS';
export const FETCH_JOURNAL_FAIL = 'FETCH_JOURNAL_FAIL';

const fetchJournalStart = () => ({
  type: FETCH_JOURNAL_START,
});

export const fetchJournalSuccess = (payload) => ({
  type: FETCH_JOURNAL_SUCCESS,
  payload,
});

const fetchJournalFail = (error) => ({
  type: FETCH_JOURNAL_FAIL,
  payload: error,
});

const paramsToSearch = ({
  page,
  moduleName,
  moduleEntryId,
  action,
  result,
  adminId,
  from,
  to,
  sortByCondition,
  sortByDirection,
}) => {
  const offset = (page || 1) * JOURNAL_LIMIT - JOURNAL_LIMIT;

  const qp = new URLSearchParams();
  qp.set('limit', JOURNAL_LIMIT);
  qp.set('offset', offset);

  if (moduleName) {
    qp.set('moduleName', moduleName);
  }
  if (moduleEntryId) {
    qp.set('moduleEntryId', moduleEntryId);
  }
  if (action) {
    qp.set('action', action);
  }
  if (result) {
    qp.set('result', result);
  }
  if (adminId) {
    qp.set('adminId', adminId);
  }
  if (from) {
    qp.set('createdAt[from]', from);
  }
  if (to) {
    qp.set('createdAt[to]', to);
  }
  if (sortByCondition && sortByDirection) {
    qp.set('sortByCondition', sortByCondition);
    qp.set('sortByDirection', sortByDirection);
  }

  const searchArr = [];
  qp.forEach((value, key) => {
    searchArr.push(`${key}=${value}`);
  });

  return searchArr.join('&');
};

export const fetchJournal = (params) => async (dispatch) => {
  dispatch(fetchJournalStart());
  try {
    const search = paramsToSearch(params);
    const response = await get(`/journal?${search}`);
    dispatch(fetchJournalSuccess({
      data: response.data,
      params,
    }));
  } catch (e) {
    console.log(e);
    dispatch(fetchJournalFail(e));
  }
};
