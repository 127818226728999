/* eslint-disable no-case-declarations */
import {
  CREATE_USERS_AUTH_PROVIDER_FAIL,
  CREATE_USERS_AUTH_PROVIDER_START,
  CREATE_USERS_AUTH_PROVIDER_SUCCESS,
  DELETE_USERS_AUTH_PROVIDER_FAIL,
  DELETE_USERS_AUTH_PROVIDER_START,
  DELETE_USERS_AUTH_PROVIDER_SUCCESS,
  FETCH_USERS_AUTH_PROVIDER_LIST_FAIL,
  FETCH_USERS_AUTH_PROVIDER_LIST_START,
  FETCH_USERS_AUTH_PROVIDER_LIST_SUCCESS,
  UPDATE_USERS_AUTH_PROVIDER_FAIL,
  UPDATE_USERS_AUTH_PROVIDER_START,
  UPDATE_USERS_AUTH_PROVIDER_SUCCESS,
} from './action';

const initialState = {
  data: [],
  isLoading: true,
  isInitial: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_USERS_AUTH_PROVIDER_LIST_START:
  case UPDATE_USERS_AUTH_PROVIDER_START:
  case CREATE_USERS_AUTH_PROVIDER_START:
  case DELETE_USERS_AUTH_PROVIDER_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_USERS_AUTH_PROVIDER_LIST_SUCCESS:
    return {
      ...state,
      data: payload,
      isLoading: false,
      isInitial: true,
    };
  case CREATE_USERS_AUTH_PROVIDER_SUCCESS:
    return {
      ...state,
      data: [
        ...state.data,
        payload
      ],
      isLoading: false,
      isInitial: true,
    };
  case DELETE_USERS_AUTH_PROVIDER_SUCCESS:
    return {
      ...state,
      data: state.data.filter((item) => item.id !== payload),
      isLoading: false,
      isInitial: true,
    };
  case UPDATE_USERS_AUTH_PROVIDER_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  case UPDATE_USERS_AUTH_PROVIDER_FAIL:
  case CREATE_USERS_AUTH_PROVIDER_FAIL:
  case FETCH_USERS_AUTH_PROVIDER_LIST_FAIL:
  case DELETE_USERS_AUTH_PROVIDER_FAIL:
    return {
      ...state,
      isLoading: false,
      isInitial: true,
    };
  default:
    return { ...state };
  }
};
