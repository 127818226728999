import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';
import { get, post, remove } from '@api';

/**
 * Товары, полученные из файла
 */
export const IMPORT_CATALOG_SET_PRODUCTS = 'IMPORT_CATALOG_SET_PRODUCTS';
/**
 * Группы товаров, полученные из файла
 */
export const IMPORT_CATALOG_SET_PRODUCT_GROUPS = 'IMPORT_CATALOG_SET_PRODUCT_GROUPS';
/**
 * Информация об ид выбранной группе
 */
export const IMPORT_CATALOG_SET_SELECTED_GROUP_ID = 'IMPORT_CATALOG_SET_SELECTED_GROUP_ID';
/**
 * Группы товаров, выбранные для импорта
 */
export const IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUPS = 'IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUPS';
/**
 * Информация об ид выбранной группе для товаров
 */
export const IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUP_ID = 'IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUP_ID';
/**
 * Данные, в т.ч. атрибуты, сформированные пользователем для импорта товаров
 */
export const IMPORT_CATALOG_SET_IMPORT_PRODUCT_SELECTED_DATA = 'IMPORT_CATALOG_SET_PRODUCT_SELECTED_DATA';
/**
 * Настройки импорта
 */
export const IMPORT_CATALOG_SET_IMPORT_CONFIG_DATA = 'IMPORT_CATALOG_SET_IMPORT_CONFIG_DATA';
/**
 * Данные сущности где мы ищем свойства справочников
 */
export const IMPORT_CATALOG_SET_IMPORT_PRODUCT_PROPERTIES = 'IMPORT_CATALOG_SET_IMPORT_PRODUCT_PROPERTIES';
/**
 * Инициализация
 */
export const IMPORT_CATALOG_CLEAR = 'IMPORT_CATALOG_CLEAR';
/**
 * Информация о текущем шаге
 */
export const IMPORT_CATALOG_SET_CURRENT_STEP = 'IMPORT_CATALOG_SET_CURRENT_STEP';
/**
 * Информация о категории, назначенной пользователем
 */
export const IMPORT_CATALOG_SET_USER_CATEGORY = 'IMPORT_CATALOG_SET_USER_CATEGORY';
/**
 * Информация о проверке выбранных категорий на сервере
 */
export const IMPORT_CATALOG_SET_SELECTED_CATEGORIES_CHECKED = 'IMPORT_CATALOG_SET_SELECTED_CATEGORIES_CHECKED';
/**
 * Информация о том, что юзер в степпере пошел назад
 */
export const IMPORT_CATALOG_SET_DETECT_BACK_STEP = 'IMPORT_CATALOG_SET_DETECT_BACK_STEP';
/**
 * Информация о результатх предварительной обработке
 */
export const IMPORT_CATALOG_SET_PROCESSING_INFO = 'IMPORT_CATALOG_SET_PROCESSING_INFO';
/**
 * Информация о завершении импорта
 */
export const IMPORT_CATALOG_SET_FINISH_IMPORT = 'IMPORT_CATALOG_SET_FINISH_IMPORT';
/**
 * Информация об отключении импорта картинок
 */
export const IMPORT_IMAGES_SET_DISABLED = 'IMPORT_IMAGES_SET_DISABLED';
/**
  Шаблоны импорта
 */
export const IMPORT_CATALOG_FETCH_ALL_TEMPLATES_START = 'IMPORT_CATALOG_FETCH_ALL_TEMPLATES_START';
export const IMPORT_CATALOG_FETCH_ALL_TEMPLATES_SUCCESS = 'IMPORT_CATALOG_FETCH_ALL_TEMPLATES_SUCCESS';
export const IMPORT_CATALOG_TEMPLATE_OPERATION_FAIL = 'IMPORT_CATALOG_TEMPLATE_OPERATION_FAIL';
export const IMPORT_CATALOG_SET_SELECTED_TEMPLATE = 'IMPORT_CATALOG_SET_SELECTED_TEMPLATE';
export const IMPORT_CATALOG_DELETE_TEMPLATE_SUCCESS = 'IMPORT_CATALOG_DELETE_TEMPLATE_SUCCESS';

export const importCatalogImagesDisabledStatus = (status) => ({
  type: IMPORT_IMAGES_SET_DISABLED,
  payload: status
});

export const importCatalogSetProducts = (data) => ({
  type: IMPORT_CATALOG_SET_PRODUCTS,
  payload: data
});

export const importCatalogSetProductGroups = (data) => ({
  type: IMPORT_CATALOG_SET_PRODUCT_GROUPS,
  payload: data
});

export const importCatalogSetSelectedProductGroups = (data) => ({
  type: IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUPS,
  payload: data
});

export const importCatalogSetProductSelectedData = (data) => ({
  type: IMPORT_CATALOG_SET_IMPORT_PRODUCT_SELECTED_DATA,
  payload: data
});

export const importCatalogSetProperties = (data) => ({
  type: IMPORT_CATALOG_SET_IMPORT_PRODUCT_PROPERTIES,
  payload: data
});

export const importCatalogSetConfigData = (data) => ({
  type: IMPORT_CATALOG_SET_IMPORT_CONFIG_DATA,
  payload: data
});

export const importCatalogCLear = () => ({
  type: IMPORT_CATALOG_CLEAR,
  payload: null
});

export const importCatalogSetCurrentStep = (step) => ({
  type: IMPORT_CATALOG_SET_CURRENT_STEP,
  payload: step
});

export const importCatalogSetUserCategory = (category) => ({
  type: IMPORT_CATALOG_SET_USER_CATEGORY,
  payload: category
});

export const importCatalogSetSelectedGroupId = (id) => ({
  type: IMPORT_CATALOG_SET_SELECTED_GROUP_ID,
  payload: id
});

export const importCatalogSetSelectedProductGroupId = (id) => ({
  type: IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUP_ID,
  payload: id
});

export const importCatalogSetSelectedCategoriesChecked = (status) => ({
  type: IMPORT_CATALOG_SET_SELECTED_CATEGORIES_CHECKED,
  payload: status
});

export const importCatalogSetDetectBackStep = (status) => ({
  type: IMPORT_CATALOG_SET_DETECT_BACK_STEP,
  payload: status
});

export const importCatalogSetProcessingInfo = (data) => ({
  type: IMPORT_CATALOG_SET_PROCESSING_INFO,
  payload: data
});

export const importCatalogSetFinishImport = () => ({
  type: IMPORT_CATALOG_SET_FINISH_IMPORT
});

export const importCatalogFetchTemplatesStart = () => ({
  type: IMPORT_CATALOG_FETCH_ALL_TEMPLATES_START
});

export const importCatalogFetchTemplatesSuccess = (data) => ({
  type: IMPORT_CATALOG_FETCH_ALL_TEMPLATES_SUCCESS,
  payload: data
});

export const importCatalogTemplateOperationFail = (error) => ({
  type: IMPORT_CATALOG_TEMPLATE_OPERATION_FAIL,
  payload: error
});

export const importCatalogSetSelectedTemplate = (data) => ({
  type: IMPORT_CATALOG_SET_SELECTED_TEMPLATE,
  payload: data
});

export const importCatalogDeleteTemplateSuccess = () => ({
  type: IMPORT_CATALOG_DELETE_TEMPLATE_SUCCESS
});

export const importCatalogFetchTemplates = () => async (dispatch) => {
  try {
    dispatch(importCatalogFetchTemplatesStart());
    const result = await get('/import/catalog-templates');
    dispatch(importCatalogFetchTemplatesSuccess(result?.data?.items || []));
  } catch (e) {
    dispatch(importCatalogTemplateOperationFail(e));
  }
};

export const importCatalogAddTemplate = (data, translations) => async (dispatch) => {
  try {
    await post('/import/catalog-templates', data);
    dispatch(importCatalogFetchTemplates());
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    dispatch(importCatalogTemplateOperationFail(e));
    return false;
  }
};

export const importCatalogDeleteTemplate = (id, translations) => async (dispatch) => {
  try {
    await remove(`/import/catalog-templates/${id}`);
    dispatch(importCatalogFetchTemplates());
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
    dispatch(importCatalogTemplateOperationFail(e));
  }
};
