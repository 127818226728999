// import { translations } from '../../locale/common/ru';
import { typeImageValue, typeListValue, typeRadioValue } from '@constants/xml-import.constants';
import { AttributeTypeImportId } from '@constants/types/attribute-type.constants';
import { isObjectNotEmpty } from './system.utils';

/**
 * Сборка типов для приложения
 */
export const setTypeDescriptions = (types = [], excludeEmpty = true, translations) => {
  const mapTypes = types.map((item) => (
    {
      ...item,
      description: translations ? translations[item.type] : ''
    }));
  if (excludeEmpty) {
    return mapTypes.filter((t) => !!t.description);
  }
  return mapTypes;
};

/* eslint-disable no-tabs */
/**
 *  Дополнительная сборка данных для типа 'attributes'
 *  мы должны добавить данные, которые будут использоваться
 *  при импорте товаров:
 *  importId - 	индентификатор типа, по которому атрибуты могу классифицироваться,
 *  						например: простые, сложные, списки и т.п. (добавлен для обратной совместимости)
 *  types		 -  индентификаторы типов из xml-import.constants.js, по
 *  						которому мы можем группировать атрибуты, например:
 *  						простые, сложные, списки и т.п.
 *  importId -  жесткая привязка к ид (нужно для импорта)
 *  preview  -	функция, реализующая предпросмотр значения атрибута
 */
export const setTypeAdditionalData = (types = []) => {
  return types.map((item) => {
    switch (item?.type) {
    case 'string':
      return ({
        ...item, types: [91, 93],	preview: (v) => (v ? v.toString() : ''), importId: AttributeTypeImportId.string
      });
    case 'integer':
      return ({
        ...item, types: [91], preview: (v) => +v, importId: AttributeTypeImportId.integer
      });
    case 'float':
      return ({
        ...item, types: [91],	preview: (v) => (v ? parseFloat(v.toString()).toFixed(2) : 0), importId: AttributeTypeImportId.fixedPointNumber
      });
    case 'real':
      return ({
        ...item, types: [91],	preview: (v) => (v ? parseFloat(v.toString()) : 0), importId: AttributeTypeImportId.realNumber
      });
    case 'dateTime':
      return ({
        ...item, types: [91],	preview: (v) => (v ? Date.parse(v.toString()) : ''), importId: AttributeTypeImportId.dateAndTime
      });
    case 'time':
      return ({
        ...item, types: [91],	preview: (v) => (v ? new Date(v).getTime() : ''), importId: AttributeTypeImportId.time
      });
    case 'date':
      return ({
        ...item, types: [91],	preview: (v) => (v ? new Date(v).getDate() : ''), importId: AttributeTypeImportId.date
      });
    case 'text':
      return ({
        ...item, types: [91, 93],	preview: (v) => (v ? v.toString() : ''), importId: AttributeTypeImportId.text
      });
    case 'textWithHeader':
      return ({
        ...item, types: [91], preview: null, importId: AttributeTypeImportId.textWithHeader
      });
    case 'file':
      return ({
        ...item, types: [95], preview: null, importId: AttributeTypeImportId.file
      });
    case 'image':
      return ({
        ...item, types: [95], preview: null, importId: AttributeTypeImportId.image
      });
    case 'groupOfImages':
      return ({
        ...item, types: [95], preview: null, importId: AttributeTypeImportId.groupOfImages
      });
    case 'list':
      return ({
        ...item, types: [92],	preview: null, importId: AttributeTypeImportId.list
      });
    case 'checkbox':
      return ({
        ...item, types: [94],	preview: null, importId: AttributeTypeImportId.checkbox
      });
    case 'radioButton':
      return ({
        ...item, types: [92],	preview: null, importId: AttributeTypeImportId.radioButton
      });
    default:
      return ({
        ...item, types: [], preview: null, importId: undefined
      });
    }
  });
};

/**
 * Возвращает описание типа
 */
export const getTypeDescription = (types = [], typeId) => {
  if (!types?.length) {
    return '';
  }
  return types.find((t) => t.id === typeId || t.type === typeId)?.description || '';
};

/**
 * Возвращает значение importId по типу
 */
export const getTypeImportIdByType = (type) => {
  if (!type) {
    return undefined;
  }
  switch (type) {
  case typeImageValue:
    return AttributeTypeImportId.groupOfImages;
  case typeListValue:
    return AttributeTypeImportId.list;
  case typeRadioValue:
    return AttributeTypeImportId.radioButton;
  default:
    return AttributeTypeImportId.string;
  }
};

/**
 * Извлекает идентификатор набора атрибутов из
 * предоставленных данных
 * @deprecated
 */
export const getAttributeSetId = (attributesSets) => {
  if (!isObjectNotEmpty(attributesSets)) {
    return null;
  }
  const index = Object.keys(attributesSets).find((k) => k.startsWith('id'));
  return index in attributesSets ? attributesSets[index] : null;
};
