import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-datetime/css/react-datetime.css';
import './assets/scss/style.scss';
import { configureStore } from './redux/Store';
import { Preloader } from './components/shared/Preloader/Preloader';

const App = lazy(() => import('./app'));

const Root = () => (
  <Suspense fallback={<Preloader />}>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </Suspense>
);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);
