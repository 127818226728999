import { store } from 'react-notifications-component';
import { get, post, remove, put } from '@api';
import {
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL
} from '@constants/notification-settings';
import { PAYMENTS_LIMIT } from '@constants/payments.constants';

export const FETCH_PAYMENTS_CONNECTED_START = 'FETCH_PAYMENTS_CONNECTED_START';
export const FETCH_PAYMENTS_CONNECTED_SUCCESS = 'FETCH_PAYMENTS_CONNECTED_SUCCESS';
export const FETCH_PAYMENTS_CONNECTED_FAIL = 'FETCH_PAYMENTS_CONNECTED_FAIL';
export const FETCH_DELETE_STRIPE_START = 'FETCH_DELETE_STRIPE_START';
export const FETCH_DELETE_STRIPE_SUCCESS = 'FETCH_DELETE_STRIPE_SUCCESS';
export const FETCH_DELETE_STRIPE_FAIL = 'FETCH_DELETE_STRIPE_FAIL';
export const FETCH_DELETE_PAYPAL_START = 'FETCH_DELETE_PAYPAL_START';
export const FETCH_DELETE_PAYPAL_SUCCESS = 'FETCH_DELETE_PAYPAL_SUCCESS';
export const FETCH_DELETE_PAYPAL_FAIL = 'FETCH_DELETE_PAYPAL_FAIL';
export const FETCH_PAYMENTS_SESSIONS_START = 'FETCH_PAYMENTS_SESSIONS_START';
export const FETCH_PAYMENTS_SESSIONS_SUCCESS = 'FETCH_PAYMENTS_SESSIONS_SUCCESS';
export const FETCH_PAYMENTS_SESSIONS_FAIL = 'FETCH_PAYMENTS_SESSIONS_FAIL';
export const FETCH_PAYMENTS_ACCOUNTS_START = 'FETCH_PAYMENTS_ACCOUNTS_START';
export const FETCH_PAYMENTS_ACCOUNTS_SUCCESS = 'FETCH_PAYMENTS_ACCOUNTS_SUCCESS';
export const FETCH_PAYMENTS_ACCOUNTS_FAIL = 'FETCH_PAYMENTS_ACCOUNTS_FAIL';
export const CREATE_PAYMENT_ACCOUNT_START = 'CREATE_PAYMENT_ACCOUNT_START';
export const CREATE_PAYMENT_ACCOUNT_SUCCESS = 'CREATE_PAYMENT_ACCOUNT_SUCCESS';
export const CREATE_PAYMENT_ACCOUNT_FAIL = 'CREATE_PAYMENT_ACCOUNT_FAIL';
export const DELETE_PAYMENT_ACCOUNT_START = 'DELETE_PAYMENT_ACCOUNT_START';
export const DELETE_PAYMENT_ACCOUNT_SUCCESS = 'DELETE_PAYMENT_ACCOUNT_SUCCESS';
export const DELETE_PAYMENT_ACCOUNT_FAIL = 'DELETE_PAYMENT_ACCOUNT_FAIL';
export const UPDATE_PAYMENT_ACCOUNT_START = 'UPDATE_PAYMENT_ACCOUNT_START';
export const UPDATE_PAYMENT_ACCOUNT_SUCCESS = 'UPDATE_PAYMENT_ACCOUNT_SUCCESS';
export const UPDATE_PAYMENT_ACCOUNT_FAIL = 'UPDATE_PAYMENT_ACCOUNT_FAIL';
export const FETCH_PAYMENTS_ACCOUNTS_ITEM_START = 'FETCH_PAYMENTS_ACCOUNTS_ITEM_START';
export const FETCH_PAYMENTS_ACCOUNTS_ITEM_SUCCESS = 'FETCH_PAYMENTS_ACCOUNTS_ITEM_SUCCESS';
export const FETCH_PAYMENTS_ACCOUNTS_ITEM_FAIL = 'FETCH_PAYMENTS_ACCOUNTS_ITEM_FAIL';
export const FETCH_STRIPE_TAX_RATES_START = 'FETCH_STRIPE_TAX_RATES_START';
export const FETCH_STRIPE_TAX_RATES_SUCCESS = 'FETCH_STRIPE_TAX_RATES_SUCCESS';
export const FETCH_STRIPE_TAX_RATES_FAIL = 'FETCH_STRIPE_TAX_RATES_FAIL';
export const UPDATE_STRIPE_TAX_RATES_START = 'UPDATE_STRIPE_TAX_RATES_START';
export const UPDATE_STRIPE_TAX_RATES_SUCCESS = 'UPDATE_STRIPE_TAX_RATES_SUCCESS';
export const UPDATE_STRIPE_TAX_RATES_FAIL = 'UPDATE_STRIPE_TAX_RATES_FAIL';
export const FETCH_PAYMENT_STATUS_MAP_START = 'FETCH_PAYMENT_STATUS_MAP_START';
export const FETCH_PAYMENT_STATUS_MAP_SUCCESS = 'FETCH_PAYMENT_STATUS_MAP_SUCCESS';
export const FETCH_PAYMENT_STATUS_MAP_FAIL = 'FETCH_PAYMENT_STATUS_MAP_FAIL';
export const UPDATE_PAYMENT_STATUS_MAP_START = 'UPDATE_PAYMENT_STATUS_MAP_START';
export const UPDATE_PAYMENT_STATUS_MAP_SUCCESS = 'UPDATE_PAYMENT_STATUS_MAP_SUCCESS';
export const UPDATE_PAYMENT_STATUS_MAP_FAIL = 'UPDATE_PAYMENT_STATUS_MAP_FAIL';

const fetchPaymentsConnectedStart = () => ({
  type: FETCH_PAYMENTS_CONNECTED_START,
});

const fetchPaymentsConnectedSuccess = (payload) => ({
  type: FETCH_PAYMENTS_CONNECTED_SUCCESS,
  payload,
});

const fetchPaymentsConnectedFail = (error) => ({
  type: FETCH_PAYMENTS_CONNECTED_FAIL,
  payload: error,
});

export const fetchPaymentsConnected = () => async (dispatch) => {
  dispatch(fetchPaymentsConnectedStart());
  try {
    const response = await get('/payments/connected');
    dispatch(fetchPaymentsConnectedSuccess(response.data));
  } catch (e) {
    dispatch(fetchPaymentsConnectedFail(e));
  }
};

const fetchDeleteStripeStart = () => ({
  type: FETCH_DELETE_STRIPE_START,
});

const fetchDeleteStripeSuccess = (payload) => ({
  type: FETCH_DELETE_STRIPE_SUCCESS,
  payload,
});

const fetchDeleteStripeFail = (error) => ({
  type: FETCH_DELETE_STRIPE_FAIL,
  payload: error,
});

export const fetchDeleteStripe = (translations) => async (dispatch) => {
  dispatch(fetchDeleteStripeStart());
  try {
    const response = await remove('/payments/stripe');
    dispatch(fetchDeleteStripeSuccess(response.data));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(fetchDeleteStripeFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

const fetchDeletePaypalStart = () => ({
  type: FETCH_DELETE_PAYPAL_START,
});

const fetchDeletePaypalSuccess = (payload) => ({
  type: FETCH_DELETE_PAYPAL_SUCCESS,
  payload,
});

const fetchDeletePaypalFail = (error) => ({
  type: FETCH_DELETE_PAYPAL_FAIL,
  payload: error,
});

export const fetchDeletePaypal = (translations) => async (dispatch) => {
  dispatch(fetchDeletePaypalStart());
  try {
    const response = await remove('/payments/paypal');
    dispatch(fetchDeletePaypalSuccess(response.data));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(fetchDeletePaypalFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

const fetchPaymentsSessionsStart = () => ({
  type: FETCH_PAYMENTS_SESSIONS_START,
});

export const fetchPaymentsSessionsSuccess = (payload) => ({
  type: FETCH_PAYMENTS_SESSIONS_SUCCESS,
  payload,
});

const fetchPaymentsSessionsFail = (error) => ({
  type: FETCH_PAYMENTS_SESSIONS_FAIL,
  payload: error,
});

const paramsToSearch = ({
  page,
}) => {
  const offset = (page || 1) * PAYMENTS_LIMIT - PAYMENTS_LIMIT;

  const qp = new URLSearchParams();
  qp.set('limit', PAYMENTS_LIMIT);
  qp.set('offset', offset);

  const searchArr = [];
  qp.forEach((value, key) => {
    searchArr.push(`${key}=${value}`);
  });

  return searchArr.join('&');
};

export const fetchPaymentsSessions = (params) => async (dispatch) => {
  dispatch(fetchPaymentsSessionsStart());
  try {
    const search = paramsToSearch(params);
    const response = await get(`/payments/sessions?${search}`);
    dispatch(fetchPaymentsSessionsSuccess({
      data: response.data,
      params,
    }));
  } catch (e) {
    dispatch(fetchPaymentsSessionsFail(e));
  }
};

const fetchPaymentsAccountsStart = () => ({
  type: FETCH_PAYMENTS_ACCOUNTS_START,
});

const fetchPaymentsAccountsSuccess = (payload) => ({
  type: FETCH_PAYMENTS_ACCOUNTS_SUCCESS,
  payload,
});

const fetchPaymentsAccountsFail = (error) => ({
  type: FETCH_PAYMENTS_ACCOUNTS_FAIL,
  payload: error,
});

export const fetchPaymentsAccounts = ({ lang }) => async (dispatch) => {
  dispatch(fetchPaymentsAccountsStart());
  try {
    const response = await get(`/payments/accounts?langCode=${lang}`);
    dispatch(fetchPaymentsAccountsSuccess({
      data: {
        items: response.data
      }
    }));
  } catch (e) {
    dispatch(fetchPaymentsAccountsFail(e));
  }
};

const createPaymentAccountStart = () => ({
  type: CREATE_PAYMENT_ACCOUNT_START,
});

const createPaymentAccountSuccess = (payload) => ({
  type: CREATE_PAYMENT_ACCOUNT_SUCCESS,
  payload,
});

const createPaymentAccountFail = (error) => ({
  type: CREATE_PAYMENT_ACCOUNT_FAIL,
  payload: error,
});

export const createPaymentAccount = (data, translations, setNewItemProcess) => async (dispatch) => {
  dispatch(createPaymentAccountStart());
  try {
    const result = await post('/payments/accounts', data);
    if (result?.data) {
      dispatch(createPaymentAccountSuccess(result?.data));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    } else {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    } setNewItemProcess((prev) => prev.filter((elId) => elId !== data.identifier));
  } catch (e) {
    dispatch(createPaymentAccountFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

const deletePaymentAccountStart = () => ({
  type: DELETE_PAYMENT_ACCOUNT_START,
});

const deletePaymentAccountSuccess = (payload) => ({
  type: DELETE_PAYMENT_ACCOUNT_SUCCESS,
  payload,
});

const deletePaymentAccountFail = (error) => ({
  type: DELETE_PAYMENT_ACCOUNT_FAIL,
  payload: error,
});

export const deletePaymentAccount = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deletePaymentAccountStart());
  try {
    await remove(`/payments/accounts/${id}`);
    dispatch(deletePaymentAccountSuccess(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(deletePaymentAccountFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

const updatePaymentAccountStart = () => ({
  type: UPDATE_PAYMENT_ACCOUNT_START,
});

const updatePaymentAccountSuccess = (payload) => ({
  type: UPDATE_PAYMENT_ACCOUNT_SUCCESS,
  payload,
});

const updatePaymentAccountFail = (error) => ({
  type: UPDATE_PAYMENT_ACCOUNT_FAIL,
  payload: error,
});

export const updatePaymentAccount = (data, lang, translations, silent = true) => async (dispatch) => {
  dispatch(updatePaymentAccountStart());
  try {
    const result = await put(`/payments/accounts/${data.id}?langCode=${lang}`, data);
    dispatch(updatePaymentAccountSuccess(result.data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    dispatch(updatePaymentAccountFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

const fetchPaymentsAccountsItemStart = () => ({
  type: FETCH_PAYMENTS_ACCOUNTS_ITEM_START,
});

const fetchPaymentsAccountsItemSuccess = (payload) => ({
  type: FETCH_PAYMENTS_ACCOUNTS_ITEM_SUCCESS,
  payload,
});

const fetchPaymentsAccountsItemFail = (error) => ({
  type: FETCH_PAYMENTS_ACCOUNTS_ITEM_FAIL,
  payload: error,
});

export const fetchPaymentsAccountsItem = (id) => async (dispatch) => {
  dispatch(fetchPaymentsAccountsItemStart());
  try {
    const response = await get(`/payments/accounts/${id}`);
    dispatch(fetchPaymentsAccountsItemSuccess(response.data));
  } catch (e) {
    dispatch(fetchPaymentsAccountsItemFail(e));
  }
};

const fetchStripeTaxRatesStart = () => ({
  type: FETCH_STRIPE_TAX_RATES_START,
});

const fetchStripeTaxRatesSuccess = (payload) => ({
  type: FETCH_STRIPE_TAX_RATES_SUCCESS,
  payload,
});

const fetchStripeTaxRatesFail = (error) => ({
  type: FETCH_STRIPE_TAX_RATES_FAIL,
  payload: error,
});

export const fetchStripeTaxRates = () => async (dispatch) => {
  dispatch(fetchStripeTaxRatesStart());
  try {
    const response = await get('/payments/stripe/tax-rates');
    dispatch(fetchStripeTaxRatesSuccess({
      data: {
        items: response.data
      }
    }));
  } catch (e) {
    dispatch(fetchStripeTaxRatesFail(e));
  }
};

const updateStripeTaxRatesStart = () => ({
  type: UPDATE_STRIPE_TAX_RATES_START,
});

const updateStripeTaxRatesSuccess = (payload) => ({
  type: UPDATE_STRIPE_TAX_RATES_SUCCESS,
  payload,
});

const updateStripeTaxRatesFail = (error) => ({
  type: UPDATE_STRIPE_TAX_RATES_FAIL,
  payload: error,
});

export const updateStripeTaxRates = (data, translations) => async (dispatch) => {
  dispatch(updateStripeTaxRatesStart());
  try {
    await put('/payments/stripe/tax-rates', data);
    dispatch(updateStripeTaxRatesSuccess(data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(updateStripeTaxRatesFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

const fetchPaymentStatusMapStart = () => ({
  type: FETCH_PAYMENT_STATUS_MAP_START,
});

const fetchPaymentStatusMapSuccess = (payload) => ({
  type: FETCH_PAYMENT_STATUS_MAP_SUCCESS,
  payload,
});

const fetchPaymentStatusMapFail = (error) => ({
  type: FETCH_PAYMENT_STATUS_MAP_FAIL,
  payload: error,
});

export const fetchPaymentStatusMap = (orderStorageId) => async (dispatch) => {
  dispatch(fetchPaymentStatusMapStart());
  try {
    const response = await get(`/payments/status-maps/${orderStorageId}`);
    dispatch(fetchPaymentStatusMapSuccess(response.data));
  } catch (e) {
    dispatch(fetchPaymentStatusMapFail(e));
  }
};

const updatePaymentStatusMapStart = () => ({
  type: UPDATE_PAYMENT_STATUS_MAP_START,
});

const updatePaymentStatusMapSuccess = (payload) => ({
  type: UPDATE_PAYMENT_STATUS_MAP_SUCCESS,
  payload,
});

const updatePaymentStatusMapFail = (error) => ({
  type: UPDATE_PAYMENT_STATUS_MAP_FAIL,
  payload: error,
});

export const updatePaymentStatusMap = (data, translations) => async (dispatch) => {
  dispatch(updatePaymentStatusMapStart());
  try {
    await put('/payments/status-maps', data);
    dispatch(updatePaymentStatusMapSuccess(data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(updatePaymentStatusMapFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};
