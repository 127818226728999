import { uniqBy } from 'lodash';
import {
  FETCH_PAYMENTS_CONNECTED_START,
  FETCH_PAYMENTS_CONNECTED_SUCCESS,
  FETCH_PAYMENTS_CONNECTED_FAIL,
  FETCH_DELETE_PAYPAL_SUCCESS,
  FETCH_DELETE_STRIPE_SUCCESS,
  FETCH_PAYMENTS_SESSIONS_START,
  FETCH_PAYMENTS_SESSIONS_SUCCESS,
  FETCH_PAYMENTS_SESSIONS_FAIL,
  FETCH_PAYMENTS_ACCOUNTS_START,
  FETCH_PAYMENTS_ACCOUNTS_SUCCESS,
  FETCH_PAYMENTS_ACCOUNTS_FAIL,
  CREATE_PAYMENT_ACCOUNT_SUCCESS,
  DELETE_PAYMENT_ACCOUNT_SUCCESS,
  UPDATE_PAYMENT_ACCOUNT_SUCCESS,
  FETCH_PAYMENTS_ACCOUNTS_ITEM_START,
  FETCH_PAYMENTS_ACCOUNTS_ITEM_SUCCESS,
  FETCH_PAYMENTS_ACCOUNTS_ITEM_FAIL,
  FETCH_STRIPE_TAX_RATES_START,
  FETCH_STRIPE_TAX_RATES_SUCCESS,
  FETCH_STRIPE_TAX_RATES_FAIL,
  UPDATE_STRIPE_TAX_RATES_SUCCESS,
  FETCH_PAYMENT_STATUS_MAP_START,
  FETCH_PAYMENT_STATUS_MAP_SUCCESS,
  FETCH_PAYMENT_STATUS_MAP_FAIL,
  UPDATE_PAYMENT_STATUS_MAP_SUCCESS,
} from './action';

const initialState = {
  connected: {
    isLoading: true,
    isInitial: false,
    accounts: {},
  },
  sessions: {
    items: [],
    err: null,
    isLoading: true,
    isInitial: false,
  },
  accounts: {
    account: null,
    items: [],
    err: null,
    isLoading: false,
    isInitial: false
  },
  stripeTaxRates: {
    items: [],
    err: null,
    isLoading: false,
    isInitial: false
  },
  statusMap: {
    isLoading: false,
    data: null,
    err: null,
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_PAYMENTS_CONNECTED_START:
    return {
      ...state,
      connected: {
        ...state.connected,
        isLoading: true,
      },
    };
  case FETCH_PAYMENTS_CONNECTED_SUCCESS:
  {
    return {
      ...state,
      connected: {
        accounts: payload,
        isLoading: false,
        isInitial: true,
      },
    };
  }
  case FETCH_PAYMENTS_CONNECTED_FAIL:
    return {
      ...state,
      connected: {
        error: payload,
        isLoading: false,
        isInitial: true,
      },
    };
  case FETCH_DELETE_PAYPAL_SUCCESS:
    return {
      ...state,
      connected: {
        ...state.connected,
        accounts: {
          ...(state.connected.accounts || {}),
          paypalAccountId: null
        }
      }
    };
  case FETCH_DELETE_STRIPE_SUCCESS:
    return {
      ...state,
      connected: {
        ...state.connected,
        accounts: {
          ...(state.connected.accounts || {}),
          stripeAccountId: null
        }
      }
    };
  case FETCH_PAYMENTS_SESSIONS_START:
    return {
      ...state,
      sessions: {
        ...state.sessions,
        isLoading: true,
      },
    };
  case FETCH_PAYMENTS_SESSIONS_SUCCESS:
  {
    let items = [];
    if (payload.params?.page > 1) {
      items = uniqBy([...state.sessions.items, ...payload.data.items], 'id');
    } else {
      items = payload.data.items;
    }
    return {
      ...state,
      sessions: {
        ...state.sessions,
        items,
        total: payload.data.total,
        isLoading: false,
        isInitial: true,
      },
    };
  }
  case FETCH_PAYMENTS_SESSIONS_FAIL:
    return {
      ...state,
      sessions: {
        ...state.sessions,
        err: payload,
        isLoading: false,
        isInitial: true,
      },
    };

  case FETCH_PAYMENTS_ACCOUNTS_START:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        isLoading: true,
      },
    };
  case FETCH_PAYMENTS_ACCOUNTS_SUCCESS:
  {
    let items = [];
    if (payload.params?.page > 1) {
      items = uniqBy([...state.accounts.items, ...payload.data.items], 'id');
    } else {
      items = payload.data.items;
    }
    return {
      ...state,
      accounts: {
        ...state.accounts,
        items,
        total: payload.data.total,
        isLoading: false,
        isInitial: true,
      },
    };
  }
  case FETCH_PAYMENTS_ACCOUNTS_FAIL:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        err: payload,
        isLoading: false,
        isInitial: true,
      },
    };
  case CREATE_PAYMENT_ACCOUNT_SUCCESS:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        items: [...state.accounts.items, { ...payload }],
        isLoading: false,
      },
    };
  case UPDATE_PAYMENT_ACCOUNT_SUCCESS:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        items: state.accounts.items.map((a) => {
          if (a.id === payload.id) {
            return {
              ...a,
              ...payload
            };
          }
          return a;
        }),
        isLoading: false,
      },
    };
  case DELETE_PAYMENT_ACCOUNT_SUCCESS:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        items: state.accounts.items.filter((a) => a.id !== payload),
        isLoading: false,
      },
    };
  case FETCH_PAYMENTS_ACCOUNTS_ITEM_START:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        isLoading: true,
      },
    };
  case FETCH_PAYMENTS_ACCOUNTS_ITEM_SUCCESS:
  {
    return {
      ...state,
      accounts: {
        ...state.accounts,
        isLoading: false,
        account: { ...payload }
      },
    };
  }
  case FETCH_PAYMENTS_ACCOUNTS_ITEM_FAIL:
    return {
      ...state,
      accounts: {
        ...state.accounts,
        err: payload,
        isLoading: false,
      },
    };
  case FETCH_STRIPE_TAX_RATES_START:
    return {
      ...state,
      stripeTaxRates: {
        ...state.stripeTaxRates,
        isLoading: true,
      },
    };
  case FETCH_STRIPE_TAX_RATES_FAIL:
    return {
      ...state,
      stripeTaxRates: {
        ...state.stripeTaxRates,
        err: payload,
        isLoading: false,
      },
    };
  case FETCH_STRIPE_TAX_RATES_SUCCESS:
  {
    let items = [];
    if (payload.params?.page > 1) {
      items = uniqBy([...state.stripeTaxRates.items, ...payload.data.items], 'id');
    } else {
      items = payload.data.items;
    }
    return {
      ...state,
      stripeTaxRates: {
        ...state.stripeTaxRates,
        items,
        total: payload.data.total,
        isLoading: false,
        isInitial: true,
      },
    };
  }
  case UPDATE_STRIPE_TAX_RATES_SUCCESS:
    return {
      ...state,
      stripeTaxRates: {
        ...state.stripeTaxRates,
        items: state.stripeTaxRates.items.map((a) => {
          if (a.attributeSet === payload.attributeSet && a.attributeMarker === payload.attributeMarker) {
            return {
              ...a,
              ...payload
            };
          }
          return a;
        }),
        isLoading: false,
        isInitial: true,
      },
    };
  case FETCH_PAYMENT_STATUS_MAP_START:
    return {
      ...state,
      statusMap: {
        ...state.statusMap,
        isLoading: true,
      },
    };
  case FETCH_PAYMENT_STATUS_MAP_FAIL:
    return {
      ...state,
      statusMap: {
        ...state.statusMap,
        err: payload,
        isLoading: false,
      },
    };
  case FETCH_PAYMENT_STATUS_MAP_SUCCESS:
    return {
      ...state,
      statusMap: {
        ...state.statusMap,
        data: payload,
        isLoading: false,
      },
    };
  case UPDATE_PAYMENT_STATUS_MAP_SUCCESS:
    return {
      ...state,
      statusMap: {
        ...state.statusMap,
        data: payload,
        isLoading: false,
      },
    };
  default:
    return { ...state };
  }
};
