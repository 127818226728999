import { get, post, put, remove } from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';

export const FETCH_MARKERS_START = 'FETCH_MARKERS_START';
export const FETCH_MARKERS_SUCCESS = 'FETCH_MARKERS_SUCCESS';
export const FETCH_MARKERS_FAIL = 'FETCH_MARKERS_FAIL';

export const CREATE_MARKER_START = 'CREATE_MARKER_START';
export const CREATE_MARKER_SUCCESS = 'CREATE_MARKER_SUCCESS';
export const CREATE_MARKER_FAIL = 'CREATE_MARKER_FAIL';

export const UPDATE_MARKER_START = 'UPDATE_MARKER_START';
export const UPDATE_MARKER_SUCCESS = 'UPDATE_MARKER_SUCCESS';
export const UPDATE_MARKER_FAIL = 'UPDATE_MARKER_FAIL';

export const DELETE_MARKER_START = 'DELETE_MARKER_START';
export const DELETE_MARKER_SUCCESS = 'DELETE_MARKER_SUCCESS';
export const DELETE_MARKER_FAIL = 'DELETE_MARKER_FAIL';
export const UPDATE_MARKER_POSITION = 'UPDATE_MARKER_POSITION';

const fetchMarkersStart = () => ({
  type: FETCH_MARKERS_START,
});

export const fetchMarkersSuccess = (payload) => ({
  type: FETCH_MARKERS_SUCCESS,
  payload,
});

const fetchMarkersFail = (payload) => ({
  type: FETCH_MARKERS_FAIL,
  payload,
});

export const fetchMarkers = ({ limit, offset, lang }) =>
  async (dispatch) => {
    dispatch(fetchMarkersStart());
    try {
      const result = await get(`/markers?limit=${limit}&offset=${offset}&langCode=${lang}`);
      result.data.items.sort((a, b) => a.position - b.position);
      dispatch(fetchMarkersSuccess(result.data));
    } catch (e) {
      dispatch(fetchMarkersFail(e));
    }
  };

const createMarkerStart = () => ({
  type: CREATE_MARKER_START,
});

export const createMarkerSuccess = (payload) => ({
  type: CREATE_MARKER_SUCCESS,
  payload,
});

const createMarkerFail = (payload) => ({
  type: CREATE_MARKER_FAIL,
  payload,
});

export const createMarker = (marker, lang, translations) => async (dispatch) => {
  dispatch(createMarkerStart());
  try {
    const result = await post('/markers', marker);
    dispatch(createMarkerSuccess(result));
    dispatch(fetchMarkers({ limit: 100, offset: 0, lang }));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (e) {
    console.log(e);
    dispatch(createMarkerFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

const updateMarkerStart = () => ({
  type: UPDATE_MARKER_START,
});

export const updateMarkerSuccess = (payload) => ({
  type: UPDATE_MARKER_SUCCESS,
  payload,
});

const updateMarkerFail = (payload) => ({
  type: UPDATE_MARKER_FAIL,
  payload,
});

export const updateMarker = (marker, lang, translations) => async (dispatch) => {
  dispatch(updateMarkerStart());
  try {
    const result = await put(`/markers/${marker.id}`, marker);
    dispatch(updateMarkerSuccess(result.data));
    dispatch(fetchMarkers({ limit: 100, offset: 0, lang }));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (e) {
    dispatch(updateMarkerFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

const deleteMarkerStart = () => ({
  type: DELETE_MARKER_START,
});

export const deleteMarkerSuccess = (payload) => ({
  type: DELETE_MARKER_SUCCESS,
  payload,
});

const deleteMarkerFail = (payload) => ({
  type: DELETE_MARKER_FAIL,
  payload,
});

export const deleteMarker = (id, translations) => async (dispatch) => {
  dispatch(deleteMarkerStart());
  try {
    await remove(`/markers/${id}`);
    dispatch(deleteMarkerSuccess(id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    console.log(e);
    dispatch(deleteMarkerFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const updateMarkerPosition = (payload) => ({
  type: UPDATE_MARKER_POSITION,
  payload,
});
