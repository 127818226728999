/**
 * Для простоты обработки держим типы как константы
 * (строгое соответствие API)
 */
export const AttributeTypes = {
  button: 'button',
  string: 'string',
  integer: 'integer',
  fixedPointNumber: 'float',
  realNumber: 'real',
  dateAndTime: 'dateTime',
  time: 'time',
  date: 'date',
  text: 'text',
  textWithHeader: 'textWithHeader',
  file: 'file',
  image: 'image',
  groupOfImages: 'groupOfImages',
  list: 'list',
  checkbox: 'checkbox',
  radioButton: 'radioButton',
  spam: 'spam',
  entity: 'entity',
  timeInterval: 'timeInterval'
};

export const AttributeTypeImportId = {
  string: 1,
  integer: 2,
  fixedPointNumber: 3,
  realNumber: 4,
  dateAndTime: 5,
  time: 6,
  date: 7,
  text: 8,
  textWithHeader: 9,
  file: 10,
  image: 11,
  groupOfImages: 12,
  list: 14,
  radioButton: 13,
  checkbox: 15,
  button: 16,
  entity: 17,
  timeInterval: 18
};
