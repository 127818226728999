/* eslint-disable no-case-declarations */
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';
import {
  FETCH_PAGES_START,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGES_FAIL,
  FETCH_CHILDRENPAGES_START,
  FETCH_CHILDRENPAGES_SUCCESS,
  FETCH_CHILDRENPAGES_FAIL,
  DELETE_PAGE_START,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_FAIL,
  WRITE_PAGE_START,
  WRITE_PAGE_SUCCESS,
  WRITE_PAGE_FAIL,
  CHANGE_PAGE_START,
  CHANGE_PAGE_SUCCESS,
  CHANGE_PAGES_POSITIONS,
  UPDATE_PAGE_SUCCESS,
  FETCH_COPY_PAGE_FAIL,
  FETCH_COPY_PAGE_SUCCESS,
  CHANGE_PAGE_STATUS,
  CLEAR_PAGES_ERROR,
  UPDATE_PAGE_POSITION_SUCCESS,
  UPDATE_MODE_PAGE,
  FETCH_PAGE_SUCCESS,
  SUCCESS_UPDATE_PAGES,
  UPDATE_PAGE_PRODUCT_COUNTER,
  SET_PAGE_ID_FOR_DELETION_PRODUCTS,
  CLEAR_PAGE_IDS_FOR_DELETION_PRODUCTS,
  SET_OPENED_PAGE_ID,
  SET_PAGE_VALIDATION_STATUS,
  FETCH_COPY_PAGE_START
} from './action';

const initialState = {
  list: [],
  page: {},
  // выбранные id для множественного удаления товаров
  deletedIds: [],
  // страница, которую мы выбрали для удаления
  pageIdAsDeleted: null,
  isLoading: true,
  isInitial: false,
  // если мы валидируем данные page через апи, то используем
  // этот статус
  validationStatus: undefined,
  err: null,
  mode: 'create',
  // страницы с товарами, которые раскрыты
  openedPagesWithProductsIds: [],
};

let modifiedData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case DELETE_PAGE_START:
    return {
      ...state,
      pageIdAsDeleted: payload,
    };

  case FETCH_PAGES_START:
  case FETCH_CHILDRENPAGES_START:
  case WRITE_PAGE_START:
    return {
      ...state,
      // isLoading: true,
      err: null,
    };
  case CLEAR_PAGES_ERROR:
    return {
      ...state,
      err: null,
    };

  case FETCH_PAGES_SUCCESS:
    const showChildrenIds = state.list
      .filter((item) => !!item?.showChildren)
      .map((item) => item.id);
    return {
      ...state,
      list: payload.map((item) => ({
        ...item,
        showChildren: showChildrenIds.includes(item.id) || false,
      })),
      deletedIds: [],
      isLoading: false,
      isInitial: true,
    };

  case UPDATE_PAGE_PRODUCT_COUNTER:
    modifiedData = state.list.map((page) => {
      if (payload.amount !== -1 && payload.pageIds.includes(page.id)) {
        const amount = +payload.amount;
        const products = Number.isNaN(page.products) || !page.products ? 0 : +page.products;
        page = {
          ...page,
          products:
              payload.type === 0
                ? products + amount
                : products - amount < 0
                  ? 0
                  : products - amount,
        };
      } else if (payload.amount === -1) {
        page = { ...page, products: 0 };
      }
      return page;
    });
    return {
      ...state,
      list: modifiedData,
    };

  case FETCH_PAGE_SUCCESS:
    modifiedData = state.list.map((page) => (page.id === payload.id ? payload : page));
    return {
      ...state,
      isLoading: false,
      list: modifiedData,
      page: payload,
    };

  case FETCH_CHILDRENPAGES_SUCCESS:
    modifiedData = state.list.map((page) => (page.id === payload.id
      ? {
        ...page,
        showChildren: !page.showChildren,
      }
      : page));
    for (const childrenItem of payload.data) {
      const existingItem = state.list.find(
        (item) => item.id === childrenItem.id
      );
      if (!existingItem) {
        if (childrenItem.childrenCount) {
          modifiedData = [
            ...modifiedData,
            { ...childrenItem, showChildren: false },
          ];
        } else {
          modifiedData = [...modifiedData, { ...childrenItem }];
        }
      }
    }
    return {
      ...state,
      list: modifiedData,
      isLoading: false,
    };

  case DELETE_PAGE_FAIL:
  case FETCH_PAGES_FAIL:
  case FETCH_CHILDRENPAGES_FAIL:
  case WRITE_PAGE_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  case DELETE_PAGE_SUCCESS:
    return {
      ...state,
      isLoading: false,
      list: state.list.filter((page) => !payload?.includes(page.id)),
      pageIdAsDeleted: null,
      page: payload?.includes(state.page?.id) ? {} : state.page,
    };

  case WRITE_PAGE_SUCCESS:
    if (state.list[state.list.length - 1]?.id === payload.id) {
      return { ...state };
    }
    if (!payload.parentId) {
      return {
        ...state,
        list: [...state.list, payload],
        isLoading: false,
      };
    }
    let listCopy = state.list.map((page) => (page.id === payload.parentId
      ? { ...page, childrenCount: page.childrenCount + 1 }
      : page));
    listCopy = [...listCopy, payload];
    return {
      ...state,
      list: [...listCopy],
      isLoading: false,
    };

  case CHANGE_PAGE_START:
    return {
      ...state,
      pageIdAsVisible: payload,
    };

  case CHANGE_PAGE_SUCCESS:
    modifiedData = cloneDeep(state.list);
    modifiedData = modifiedData.map((page) => (page.id === payload.id ? payload : page));
    return {
      ...state,
      list: modifiedData,
      isLoading: false,
      page: state.page && state.page.id === payload.id ? payload : state.page,
      pageIdAsVisible: false,
    };

  case CHANGE_PAGES_POSITIONS:
    return {
      ...state,
      list: [...payload],
      isLoading: false,
    };

  case UPDATE_MODE_PAGE:
    return { ...state, mode: payload };

  case SET_PAGE_VALIDATION_STATUS:
    return { ...state, validationStatus: payload };

  case CHANGE_PAGE_STATUS:
    /* eslint-disable no-case-declarations */
    const page = state.list.find((item) => item.id === payload);
    const clonedPage = cloneDeep(page);
    clonedPage.isEdit = false;
    clonedPage.userEditId = null;
    modifiedData = state.list.map((item) => (item.id === clonedPage.id ? clonedPage : item));
    return {
      ...state,
      list: modifiedData,
    };

  case UPDATE_PAGE_SUCCESS:
    modifiedData = update(state.list, {
      $splice: [
        [payload.dragIndex, 1],
        [payload.hoverIndex, 0, payload.dragCard],
      ],
    });
    return {
      ...state,
      list: modifiedData,
    };

  case FETCH_COPY_PAGE_START:
    return {
      ...state,
      pageIdAsCopy: payload,
    };

  case FETCH_COPY_PAGE_SUCCESS:
    return {
      ...state,
      list: payload,
      pageIdAsCopy: false,
    };

  case FETCH_COPY_PAGE_FAIL:
    return {
      ...state,
      err: payload,
      pageIdAsCopy: false,
    };

    // @deprecated
  case UPDATE_PAGE_POSITION_SUCCESS:
    modifiedData = state.list.map((item) => {
      if (payload[item.id]) {
        const clonedItem = cloneDeep(item);
        clonedItem.position = payload[item.id];
        return clonedItem;
      }
      return item;
    });
    modifiedData.sort((a, b) => a.position - b.position);
    return {
      ...state,
      list: modifiedData,
      isLoading: false,
    };

    // TODO временно для локального обновления вложенности в страницы
  case SUCCESS_UPDATE_PAGES:
    modifiedData = [];
    for (let i = 0; i < state.list.length; i++) {
      modifiedData.push(state.list[i]);
      for (let k = 0; k < payload.length; k++) {
        if (state.list[i].id === payload[k].id) {
          modifiedData[i] = payload[k];
        }
      }
    }
    return {
      ...state,
      list: modifiedData,
    };

  case SET_PAGE_ID_FOR_DELETION_PRODUCTS:
    return {
      ...state,
      deletedIds: payload.operation
        ? state.deletedIds.concat([payload.id])
        : state.deletedIds.filter((id) => id !== payload.id),
    };

  case SET_OPENED_PAGE_ID:
    return {
      ...state,
      openedPagesWithProductsIds: payload.operation
        ? state.openedPagesWithProductsIds.concat([payload.id])
        : state.openedPagesWithProductsIds.filter((id) => id !== payload.id),
    };

  case CLEAR_PAGE_IDS_FOR_DELETION_PRODUCTS:
    return {
      ...state,
      deletedIds: [],
    };

  default:
    return { ...state };
  }
};
