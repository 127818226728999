import {
  FETCH_SETTINGS_START,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAIL,
  SET_LANGUAGE_START,
  SET_LANGUAGE_SUCCESS,
  SET_LANGUAGE_FAIL,
  SET_THEME_START,
  SET_THEME_SUCCESS,
  SET_THEME_FAIL,
  UPDATE_DISK_USED_VALUE,
} from './action';
import { DEFAULT_UI_LANG, DEFAULT_UI_THEME } from '@constants/settings';

const initialState = {
  data: {},
  isLoading: false,
  lang: localStorage.getItem('activeLanguage') || DEFAULT_UI_LANG,
  theme: DEFAULT_UI_THEME,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_SETTINGS_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_SETTINGS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: payload
    };
  case SET_LANGUAGE_START:
    return {
      ...state,
      isLoading: true,
    };
  case SET_LANGUAGE_SUCCESS:
    return {
      ...state,
      isLoading: false,
      lang: payload
    };
  case UPDATE_DISK_USED_VALUE:
    return {
      ...state,
      data: {
        ...state.data,
        fileStorage: {
          ...state.data.fileStorage,
          diskSpaceUsed: payload,
        }
      }
    };
  case SET_LANGUAGE_FAIL:
    return {
      ...state,
      isLoading: false,
    };
  case FETCH_SETTINGS_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false
    };
  case SET_THEME_START:
    return {
      ...state,
      isLoading: true
    };
  case SET_THEME_SUCCESS:
    return {
      ...state,
      isLoading: false,
      theme: payload
    };
  case SET_THEME_FAIL:
    return {
      ...state,
      isLoading: false,
      err: payload
    };
  default:
    return { ...state };
  }
};
