import {
  FETCH_LOCALES_START,
  FETCH_LOCALES_SUCCESS,
  FETCH_LOCALES_FAIL,
  SET_LOCALE_ACTIVE,
  CREATE_LOCALE_START,
  CREATE_LOCALE_SUCCESS,
  CREATE_LOCALE_FAIL,
  UPDATE_LOCALE_START,
  UPDATE_LOCALE_SUCCESS,
} from './action';
import { DEFAULT_CONTENT_LANG } from '@constants/settings';
import { UPDATE_BLOCK_FAIL } from '../blocks';

const initialState = {
  locales: [],
  selectedLocale: DEFAULT_CONTENT_LANG,
  editedLocale: null,
  isLoading: false,
  isInitial: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_LOCALES_START:
    return {
      ...state,
      isLoading: true,
    };

  case FETCH_LOCALES_SUCCESS:
    return {
      ...state,
      locales: payload,
      isLoading: false,
      isInitial: true,
    };

  case SET_LOCALE_ACTIVE:
    return {
      ...state,
      selectedLocale: payload,
    };

  case FETCH_LOCALES_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  case CREATE_LOCALE_START:
  case UPDATE_LOCALE_START:
    return {
      ...state,
      isLoading: true,
    };

  case CREATE_LOCALE_SUCCESS:
    if (payload.isActive) {
      return {
        ...state,
        locales: state.locales.concat([payload])?.sort((a, b) => a.position?.position?.localeCompare(b.position?.position)),
        isLoading: false,
      };
    }
    return {
      ...state,
    };

  case CREATE_LOCALE_FAIL:
  case UPDATE_BLOCK_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };

  case UPDATE_LOCALE_SUCCESS:
    return {
      ...state,
      locales: state.locale.map((item) => (item.id === payload.id ? { ...payload } : item)),
      isLoading: false,
    };

  default:
    return { ...state };
  }
};
