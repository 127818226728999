export const IMPORT_CATALOG_WORKER_INIT = 'IMPORT_CATALOG_WORKER_INIT';
export const IMPORT_CATALOG_WORKER_FINISH = 'IMPORT_CATALOG_WORKER_FINISH';
export const IMPORT_CATALOG_WORKER_RUNNING = 'IMPORT_CATALOG_WORKER_RUNNING';
export const IMPORT_CATALOG_WORKER_SET_DATA = 'IMPORT_CATALOG_WORKER_SET_DATA';
export const IMPORT_CATALOG_WORKER_SET_FILE = 'IMPORT_CATALOG_WORKER_SET_FILE';
export const IMPORT_CATALOG_WORKER_CLEAR_DATA = 'IMPORT_CATALOG_WORKER_CLEAR_DATA';
export const IMPORT_CATALOG_WORKER_SET_PRICE_DATA = 'IMPORT_CATALOG_WORKER_SET_PRICE_DATA';
export const IMPORT_CATALOG_WORKER_SET_PRODUCT_DATA = 'IMPORT_CATALOG_WORKER_SET_PRODUCT_DATA';
export const IMPORT_CATALOG_WORKER_SET_FAILED_DATA = 'IMPORT_CATALOG_WORKER_SET_FAILED_DATA';
export const IMPORT_CATALOG_WORKER_SET_STRUCTURE = 'IMPORT_CATALOG_WORKER_SET_STRUCTURE';
export const IMPORT_CATALOG_WORKER_SET_LOG_DATA = 'IMPORT_CATALOG_WORKER_SET_LOG_DATA';

export const importCatalogWorkerInit = (worker) => ({
  type: IMPORT_CATALOG_WORKER_INIT,
  payload: worker
});

export const importCatalogWorkerClearData = () => ({
  type: IMPORT_CATALOG_WORKER_CLEAR_DATA,
});

export const importCatalogWorkerFinish = () => ({
  type: IMPORT_CATALOG_WORKER_FINISH
});

export const importCatalogWorkerSetData = (data) => ({
  type: IMPORT_CATALOG_WORKER_SET_DATA,
  payload: data
});

export const importCatalogWorkerSetFile = (file) => ({
  type: IMPORT_CATALOG_WORKER_SET_FILE,
  payload: file
});

export const importCatalogWorkerSetFailedData = (data) => ({
  type: IMPORT_CATALOG_WORKER_SET_FAILED_DATA,
  payload: data
});

export const importCatalogWorkerSetStructure = (data) => ({
  type: IMPORT_CATALOG_WORKER_SET_STRUCTURE,
  payload: data
});

export const importCatalogWorkerSetLogData = (data) => ({
  type: IMPORT_CATALOG_WORKER_SET_LOG_DATA,
  payload: data
});

export const importCatalogWorkerSetPriceData = (data) => ({
  type: IMPORT_CATALOG_WORKER_SET_PRICE_DATA,
  payload: data
});

export const importCatalogWorkerRunning = (status) => ({
  type: IMPORT_CATALOG_WORKER_RUNNING,
  payload: status
});
