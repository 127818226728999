import axios from 'axios';
import React from 'react';
// eslint-disable-next-line import/no-cycle
import { get } from '@api';
import { memoize } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { EE } from '../../hooks/useApp';
import noImg from '../../assets/images/icon/no-img.svg';
import { BASE_API_URL, DEFAULT_CONTENT_LANG } from '../../constants/settings';
// eslint-disable-next-line import/no-cycle
import LangNotPresent from '@components/shared/LangNotPresent/LangNotPresent';
import moment from 'moment';

/**
 * Враппер для функций
 */
export const promiseWrapper = (...f) => {
  return () => {
    return new Promise((resolve, reject) => {
      if (f[0]) {
        try {
          resolve(f[0]());
        } catch (e) {
          reject(e);
        }
      } else {
        reject(new Error('We expect a function'));
      }
    });
  };
};

/**
 * Выполнение функции в фоне
 */
export const asyncBackgroundTask = async (f) => {
  await new Promise(() => setTimeout(f, 0));
};

export const logger = (...args) => {
  let messageConfig = '%c%s   ';
  args.forEach((argument) => {
    const type = typeof argument;
    switch (type) {
    case 'bigint':
    case 'number':
    case 'boolean':
      messageConfig += '%d   ';
      break;

    case 'string':
      messageConfig += '%s   ';
      break;

    case 'object':
    case 'undefined':
    default:
      messageConfig += '%o   ';
    }
  });
  console.log(messageConfig, 'color: orange', '[LOGGER]', ...args);
};

/**
 * Генератор уникальных индентификаторов
 */
export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
  // eslint-disable-next-line
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
};

export const isObjectNotEmpty = (o) => o && !!Object.keys(o)?.length;

/**
 * Проверяем есть ли в объекте o свойство p
 */
export const hasOwnProperty = (o, p) => Object.prototype.hasOwnProperty.call(o, p);

/**
 * Запросы с отменой (для живого поиска)
 */
const liveRequestCreator = () => {
  let cancelTokenSource;
  return memoize(async (query) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    cancelTokenSource = axios.CancelToken.source();
    try {
      const res = await get(query, { cancelToken: cancelTokenSource.token });
      return res?.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log('Something went wrong: ', error.message);
      }
    }
  });
};

/**
 * Эмитируем сообщения потребителям
 */
export const emitMessage = (id, value, channel = 'keyup', lang = DEFAULT_CONTENT_LANG, appId = undefined) => {
  if (id) {
    EE.emit(channel, {
      id,
      value,
      lang,
      appId
    });
  }
};

/**
 * Переделка апи URL файла в статическую ссылку
 */
export const transformFileLink = (url) => {
  if (!url) {
    return '';
  }
  const urlObject = new URL(url);
  const urlParams = new URLSearchParams(urlObject.search);
  const type = urlParams.get('type') || '';
  const id = urlParams.get('id') || '';
  const entity = urlParams.get('entity') || '';
  const fileName = urlParams.get('filename') || '';
  const link = `${BASE_API_URL}/cloud-static/files/project/${type}/${id}/${entity}/${fileName}`;
  return (id && entity && type && fileName) ? link : noImg;
};

/**
 * Получение правильного значение для объекта
 * на основе выбранного языка (для объекта)
 */
export const reformatLocalizeInfosObject = (object, lang, key = 'title', localizeInfosKey = 'localizeInfos') => {
  if (!object || !isObjectNotEmpty(object)) {
    return;
  }
  // получаем все доступные языковые коды
  const localizeCodes = object?.[localizeInfosKey] ? Object.keys(object[localizeInfosKey]) : [];
  // получаем языковой код, для которого есть значение key
  const existedLangCode = localizeCodes.length
    ? localizeCodes.find((code) => !!object?.[localizeInfosKey]?.[code]?.[key])
    : DEFAULT_CONTENT_LANG;
  // есть ли значение key для языка lang
  const isLangPresent = localizeCodes.includes(lang) && !!object?.[localizeInfosKey]?.[lang]?.[key];
  return [
    isLangPresent ? object?.[localizeInfosKey]?.[lang]?.[key] : object?.[localizeInfosKey]?.[existedLangCode]?.[key],
    isLangPresent,
    existedLangCode
  ];
};

/**
 * Получение правильного значение для объекта
 * на основе выбранного языка (для массива объектов)
 */
export const reformatLocalizeInfos = (data, lang, key = 'title', isHTMLTitle = false) => {
  if (!Array.isArray(data)) {
    return data;
  }
  return data.map((item) => {
    const [keyValue, isLangPresent, existedLangCode] = reformatLocalizeInfosObject(item, lang, key);
    let title = '';
    if (isHTMLTitle) {
      title = isLangPresent ? (
        <span>{keyValue}</span>
      ) : (
        <span>
          {keyValue}
          {' '}
          <LangNotPresent
            isLangPresent={isLangPresent}
            existedLangCode={existedLangCode}
          />
        </span>
      );
    } else {
      title = isLangPresent ? keyValue : `${keyValue} ${existedLangCode}`;
    }
    item[key] = title;
    return item;
  });
};

// base64 изображение в буфер
export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

/**
 * Фильтрация лишних свойств для фильтра товаров
 */
export const cleanFilterDto = (dto = [], removeEmpty = true) => {
  // свойства фильтра, которые мы сохраняем
  // в соответствии с бэком
  const propertiesToKeep = ['attributeMarker', 'conditionMarker', 'statusMarker', 'conditionValue', 'pageUrls', 'title'];
  if (!dto || !Array.isArray(dto)) {
    return dto;
  }
  const filteredDto = dto.map((obj) => {
    const filteredObj = {};
    propertiesToKeep.forEach((prop) => {
      // eslint-disable-next-line no-prototype-builtins
      if (obj && prop in obj) {
        if ((removeEmpty && obj[prop]) || !removeEmpty) {
          filteredObj[prop] = obj[prop];
        }
      }
    });
    return isObjectNotEmpty(filteredObj) ? filteredObj : null;
  });
  return filteredDto.filter((item) => item !== null);
};

/**
 * Удаление пробелов в строках объекта
 */
export const trimObject = (obj) => {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach((key) =>
      obj[key] = typeof obj[key] === 'string' ? obj[key].trim() : trimObject(obj[key]));
  }
  return obj;
};

export const liveSearch = liveRequestCreator();

/**
 * Удаление html в строке
 */
export const removeHTMLTags = (string) => {
  const regex = /<.*?>/g;
  return string.replace(regex, '')?.trim();
};

/**
 * Проверка на существование картинки
 */
export const checkImageURL = async (src) => {
  try {
    const response = await fetch(src, { method: 'HEAD' });
    return response.ok && response.headers.get('x-origin-code') !== 404;
  } catch (error) {
    return false;
  }
};

  export const createDateWithTime = (hours, minutes) => {
    // Получаем текущую дату без времени
    const currentDate = moment().startOf('day');

    // Устанавливаем указанные часы и минуты
    const customDate = currentDate;
    if (hours) {
      currentDate.hour(hours);
    }
    if (minutes) {
      currentDate.minute(minutes);
    }
    return customDate.toDate();
  };
