import { get, post, put } from '@api';
import { store } from 'react-notifications-component';
import { COMMON_DATA_UPDATE_FAIL, COMMON_DATA_UPDATE_SUCCESS } from '../../constants/notification-settings';

export const FETCH_PRODUCTS_DATA_START = 'FETCH_PRODUCTS_DATA_START';
export const FETCH_PRODUCT_ATTRIBUTES_SUCCESS = 'FETCH_PRODUCT_ATTRIBUTES_SUCCESS';
export const FETCH_PRODUCTS_DATA_FAIL = 'FETCH_PRODUCTS_DATA_FAIL';
export const SET_PRODUCTS_FILTER_DATA = 'SET_PRODUCTS_FILTER_DATA';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';

const fetchProductsDataStart = () => ({
  type: FETCH_PRODUCTS_DATA_START
});

const fetchProductAttributesSuccess = (data) => ({
  type: FETCH_PRODUCT_ATTRIBUTES_SUCCESS,
  payload: data
});

const fetchProductsDataFail = (err) => ({
  type: FETCH_PRODUCTS_DATA_FAIL,
  payload: err,
});

export const setProductsFilterData = (payload) => ({
  type: SET_PRODUCTS_FILTER_DATA,
  payload,
});

export const setSelectedProduct = (payload) => ({
  type: SET_SELECTED_PRODUCT,
  payload,
});

export const fetchAttributes = () => async (dispatch) => {
  dispatch(fetchProductsDataStart());
  try {
    const result = await get('/index-attributes?type=products');
    dispatch(fetchProductAttributesSuccess(result.data));
  } catch (e) {
    dispatch(fetchProductsDataFail(e));
  }
};

export const writeProduct = (newProduct, translations, silent = true) => async () => {
  try {
    const result = await post('/products', newProduct);
    if (!silent && result.data.id) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return result.data.id;
  } catch (e) {
    console.log(e);
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return null;
  }
};

export const changeProduct = (changedProductPage, translations, silent = true) => async () => {
  try {
    const result = await put(`/products/${changedProductPage.id}`, changedProductPage);
    if (!!result === true && !silent) {
      // eslint-disable-next-line no-plusplus
      // dispatch(setSelectedProduct({ ...changedProductPage, version: ++changedProductPage.version }));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    } else if (!silent) {
        store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
      }
    return !!result;
  } catch (e) {
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    return false;
  }
};

export const getProductPage = (id, langCode) => async () => {
  try {
    const result = await get(`/products/${id}?langCode=${langCode}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};
