import {
  IMPORT_CATALOG_WORKER_INIT,
  IMPORT_CATALOG_WORKER_SET_DATA,
  IMPORT_CATALOG_WORKER_SET_STRUCTURE,
  IMPORT_CATALOG_WORKER_SET_FAILED_DATA,
  IMPORT_CATALOG_WORKER_CLEAR_DATA,
  IMPORT_CATALOG_WORKER_SET_PRICE_DATA,
  IMPORT_CATALOG_WORKER_SET_FILE,
  IMPORT_CATALOG_WORKER_SET_LOG_DATA,
  IMPORT_CATALOG_WORKER_RUNNING,
  IMPORT_CATALOG_WORKER_FINISH
} from './action';

const initialState = {
  worker: undefined,
  files: [],
  data: [],
  priceData: [],
  structure: [],
  failedData: [],
  /**
   * Мы будем использовать специальный формат строки для отображения подробной
   * информации о том, что происходит внутри воркера:
   * тип сообщения (error|info), текст сообщения, число обработанных данных, всего данных
   * Примеры:
   *    error, Ошибка обработки атрибутов: не найден тип атрибута, null, null
   *    info,  Обработка категорий, 12, 120
   */
  logData: {
    start: '',
    message: ''
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case IMPORT_CATALOG_WORKER_INIT:
    return {
      ...state,
      worker: payload,
      // progress: 0
    };
  case IMPORT_CATALOG_WORKER_FINISH:
    return {
      ...state,
      isRunning: false,
    };
  case IMPORT_CATALOG_WORKER_RUNNING:
    return (
      state.isRunning === payload
        ? { ...state }
        : {
          ...state,
          isRunning: payload,
          failedData: payload === true ? [] : state.failedData,
          logData: payload === true ? { start: new Date(), message: '' } : state.logData
        }
    );
  case IMPORT_CATALOG_WORKER_SET_DATA:
    return {
      ...state,
      data: payload
    };
  case IMPORT_CATALOG_WORKER_SET_PRICE_DATA:
    return {
      ...state,
      priceData: payload
    };
  case IMPORT_CATALOG_WORKER_SET_FAILED_DATA:
    return {
      ...state,
      failedData: payload,
    };
  case IMPORT_CATALOG_WORKER_SET_STRUCTURE:
    return {
      ...state,
      structure: payload
    };
  case IMPORT_CATALOG_WORKER_SET_LOG_DATA:
    return {
      ...state,
      logData: { ...state.logData, message: payload }
    };
  case IMPORT_CATALOG_WORKER_SET_FILE:
    return {
      ...state,
      files: payload
    };
  case IMPORT_CATALOG_WORKER_CLEAR_DATA:
    return {
      ...initialState
    };
  default:
    return { ...state };
  }
};
