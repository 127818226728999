import {
  IMPORT_CATALOG_SET_PRODUCTS,
  IMPORT_CATALOG_SET_PRODUCT_GROUPS,
  IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUPS,
  IMPORT_CATALOG_SET_IMPORT_PRODUCT_SELECTED_DATA,
  IMPORT_CATALOG_SET_IMPORT_CONFIG_DATA,
  IMPORT_CATALOG_SET_IMPORT_PRODUCT_PROPERTIES,
  IMPORT_CATALOG_CLEAR,
  IMPORT_CATALOG_SET_CURRENT_STEP,
  IMPORT_CATALOG_SET_USER_CATEGORY,
  IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUP_ID,
  IMPORT_CATALOG_SET_SELECTED_GROUP_ID,
  IMPORT_CATALOG_SET_SELECTED_CATEGORIES_CHECKED,
  IMPORT_CATALOG_SET_DETECT_BACK_STEP,
  IMPORT_CATALOG_SET_PROCESSING_INFO,
  IMPORT_CATALOG_SET_FINISH_IMPORT,
  IMPORT_CATALOG_FETCH_ALL_TEMPLATES_START,
  IMPORT_CATALOG_FETCH_ALL_TEMPLATES_SUCCESS,
  IMPORT_CATALOG_SET_SELECTED_TEMPLATE,
  IMPORT_CATALOG_TEMPLATE_OPERATION_FAIL,
  IMPORT_CATALOG_DELETE_TEMPLATE_SUCCESS,
  IMPORT_IMAGES_SET_DISABLED
} from './action';

const initialState = {
  products: [],
  groups: [],
  selectedGroups: [],
  selectedGroupId: '',
  selectedProductGroupId: '',
  selectedData: [],
  isSelectedCategoryChecked: false,
  userCategory: {
    id: null,
    name: '',
    on: false,
    requested: false
  },
  properties: [],
  currentStep: 0,
  config: {
    productsNonExist: 0,
    newCategories: 1,
    emptyCategories: 0,
    dontCreateNewProducts: false,
    hideNewProducts: false,
    skipNewProductsWithoutPrice: false,
    removeEmptyAttributes: true,
    categoryParentId: 0,
    skipProductsWithoutImages: false,
  },
  backStepDetected: false,
  processingInfo: {},
  isFinished: false,
  isLoadingTemplates: false,
  isUploadingImagesDisabled: false,
  templates: [],
  selectedTemplate: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case IMPORT_CATALOG_SET_PRODUCTS:
    return {
      ...state,
      products: payload
    };
  case IMPORT_CATALOG_SET_PRODUCT_GROUPS:
    return {
      ...state,
      groups: payload,
      selectedGroups: [],
      selectedData: [],
      processingInfo: {}
    };
  case IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUPS:
    return {
      ...state,
      selectedGroups: payload
    };
  case IMPORT_CATALOG_SET_IMPORT_PRODUCT_SELECTED_DATA:
    return {
      ...state,
      selectedData: payload
    };
  case IMPORT_CATALOG_SET_IMPORT_PRODUCT_PROPERTIES:
    return {
      ...state,
      properties: payload
    };
  case IMPORT_CATALOG_SET_IMPORT_CONFIG_DATA:
    return {
      ...state,
      config: payload
    };
  case IMPORT_CATALOG_SET_CURRENT_STEP:
    return {
      ...state,
      currentStep: payload
    };
  case IMPORT_CATALOG_SET_USER_CATEGORY:
    return {
      ...state,
      userCategory: payload
    };
  case IMPORT_CATALOG_SET_SELECTED_GROUP_ID:
    return {
      ...state,
      selectedGroups: [],
      groups: [],
      userCategory: {
        ...state.userCategory, id: null, name: '', requested: false
      },
      selectedGroupId: payload
    };
  case IMPORT_CATALOG_SET_SELECTED_PRODUCT_GROUP_ID:
    return {
      ...state,
      products: [],
      selectedProductGroupId: payload
    };
  case IMPORT_CATALOG_SET_SELECTED_CATEGORIES_CHECKED:
    return {
      ...state,
      isSelectedCategoryChecked: payload
    };
  case IMPORT_CATALOG_SET_DETECT_BACK_STEP:
    return {
      ...state,
      backStepDetected: payload
    };
  case IMPORT_CATALOG_CLEAR:
    return {
      ...initialState,
      templates: [...state.templates]
    };
  case IMPORT_CATALOG_SET_PROCESSING_INFO:
    return {
      ...state,
      processingInfo: payload
    };
  case IMPORT_CATALOG_SET_FINISH_IMPORT:
    return {
      ...state,
      isFinished: true
    };
  case IMPORT_CATALOG_FETCH_ALL_TEMPLATES_START:
    return {
      ...state,
      isLoadingTemplates: true
    };
  case IMPORT_CATALOG_FETCH_ALL_TEMPLATES_SUCCESS:
    return {
      ...state,
      isLoadingTemplates: false,
      templates: payload
    };
  case IMPORT_IMAGES_SET_DISABLED:
    return {
      ...state,
      isUploadingImagesDisabled: payload,
    };
  case IMPORT_CATALOG_SET_SELECTED_TEMPLATE:
    return {
      ...state,
      selectedTemplate: { ...payload, data: payload.data ? JSON.parse(payload.data) : {} }
    };
  case IMPORT_CATALOG_TEMPLATE_OPERATION_FAIL:
    return {
      ...state,
      isLoadingTemplates: false
    };
  case IMPORT_CATALOG_DELETE_TEMPLATE_SUCCESS:
    return {
      ...state,
      selectedTemplate: {}
    };
  default:
    return { ...state };
  }
};
