export const JOURNAL_LIMIT = 10;
export const JOURNAL_MIN_COUNT = 0;
export const JOURNAL_MIN_PAGE = 1;

export const JournalAction = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const JournalResult = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

// @see ModuleNameEnum in admin back application
export const JournalModuleName = {
  ADMIN: 'ADMIN',
  // ATTRIBUTE: 'ATTRIBUTE',
  ATTRIBUTESSETS: 'ATTRIBUTESSETS',
  BACKUP: 'BACKUP',
  BLOCKS: 'BLOCK',
  EVENT: 'EVENT',
  // CATEGORY: 'CATEGORY',
  COMMON_SETTING: 'COMMON_SETTING',
  UPLOAD: 'FILE_UPLOAD',
  EDITOR: 'FILE_EDITOR',
  FORM: 'FORM',
  LOCALE: 'LOCALE',
  MARKER: 'MARKER',
  MENU: 'MENU',
  MODULE: 'MODULE',
  PAGE: 'PAGE',
  PRODUCT: 'PRODUCT',
  ORDER: 'ORDER',
  SETTING_GENERAL: 'SETTING_GENERAL',
  PAYMENT: 'PAYMENT',
  PRODUCTSTATUS: 'PRODUCTSTATUS',
  USER: 'USER',
  AUTHPROVIDER: 'USERSAUTHPROVIDER',
  TEMPLATE: 'TEMPLATE',
  TEMPLATEPREVIEW: 'TEMPLATEPREVIEW'
};

export const FieldNames = {
  DATE_FROM: 'from',
  DATE_TO: 'to',
  ACTION: 'action',
  RESULT: 'result',
  MODULE_NAME: 'moduleName',
  ADMIN_ID: 'adminId',
  MODULE_ENTRY_ID: 'moduleEntryId',
  SORT_BY_CONDITION: 'sortByCondition',
  SORT_BY_DIRECTION: 'sortByDirection',
};

export const DISABLED_VALUE = 'disabled';

export const LAST_ELEMENT_INDEX = -1;

export const INDEX_INCREMENT = 1;

export const INITIAL_PAGE = 1;
export const PAGE_INCREMENT = 1;

export const DATE_FROM_HOURS = 0;
export const DATE_FROM_MUNITES = 0;
export const DATE_FROM_SECONDS = 0;

export const DATE_TO_HOURS = 23;
export const DATE_TO_MUNITES = 59;
export const DATE_TO_SECONDS = 59;

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const INITIAL_VALUES = {
  [FieldNames.DATE_FROM]: undefined,
  [FieldNames.DATE_TO]: undefined,
  [FieldNames.ADMIN_ID]: undefined,
  [FieldNames.ACTION]: undefined,
  [FieldNames.RESULT]: undefined,
  [FieldNames.MODULE_NAME]: undefined,
  [FieldNames.MODULE_ENTRY_ID]: undefined,
  [FieldNames.SORT_BY_CONDITION]: undefined,
  [FieldNames.SORT_BY_DIRECTION]: undefined,
};

export const PARAMS_KEYS = [
  FieldNames.ACTION,
  FieldNames.RESULT,
  FieldNames.ADMIN_ID,
  FieldNames.MODULE_NAME,
  FieldNames.MODULE_ENTRY_ID,
  FieldNames.DATE_FROM,
  FieldNames.DATE_TO,
  FieldNames.SORT_BY_CONDITION,
  FieldNames.SORT_BY_DIRECTION,
];

export const COLUMN_KEYS = [
  'action',
  'result',
  'login',
  'moduleName',
  'moduleEntryId',
  'createdAt',
];

export const SORTING_COLUMN_KEYS = [
  'action',
  'result',
  'login',
  'moduleName',
  'moduleEntryId',
  'createdAt',
];
