import { post, get, put, remove } from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';

export const FETCH_CUSTOM_MODULES_START = 'FETCH_CUSTOM_MODULES_START';
export const FETCH_CUSTOM_MODULES_SUCCESS = 'FETCH_CUSTOM_MODULES_SUCCESS';
export const WRITE_CUSTOM_MODULES_SUCCESS = 'WRITE_CUSTOM_MODULES_SUCCESS';
export const FETCH_CUSTOM_MODULES_FAIL = 'FETCH_CUSTOM_MODULES_FAIL';
export const CHANGE_MODULE_SUCCESS = 'CHANGE_MODULE_SUCCESS';
export const UPDATE_MODULE_SET = 'UPDATE_MODULE_SET';
export const FETCH_MODULE_SUCCESS = 'FETCH_MODULE_SUCCESS';
export const REMOVE_CUSTOM_MODULE = 'REMOVE_CUSTOM_MODULE';

export const UPDATE_MODULE_POSITIONS = 'UPDATE_MODULE_POSITIONS';
export const FETCH_MODULE_PERMISSIONS_START = 'FETCH_MODULE_PERMISSIONS_START';
export const FETCH_MODULE_PERMISSIONS_SUCCESS = 'FETCH_MODULE_PERMISSIONS_SUCCESS';
export const FETCH_MODULE_PERMISSIONS_FAIL = 'FETCH_MODULE_PERMISSIONS_FAIL';

export const fetchModulePermissionsStart = () => ({
  type: FETCH_MODULE_PERMISSIONS_START,
});

export const fetchModulePermissionsSuccess = (payload) => ({
  type: FETCH_MODULE_PERMISSIONS_SUCCESS,
  payload,
});

export const fetchModulePermissionsFail = (payload) => ({
  type: FETCH_MODULE_PERMISSIONS_FAIL,
  payload,
});

export const updateModulePositions = (payload) => ({
  type: UPDATE_MODULE_POSITIONS,
  payload,
});

const fetchCustomModulesStart = () => ({
  type: FETCH_CUSTOM_MODULES_START,
});

export const writeCustomModulesSuccess = (data) => ({
  type: WRITE_CUSTOM_MODULES_SUCCESS,
  payload: data,
});

const fetchCustomModulesSuccess = (data) => ({
  type: FETCH_CUSTOM_MODULES_SUCCESS,
  payload: data,
});

export const updateModuleSet = (draggedItem) => ({
  type: UPDATE_MODULE_SET,
  payload: draggedItem,
});

export const fetchModuleSuccess = (data) => ({
  type: FETCH_MODULE_SUCCESS,
  payload: data,
});

export const removeCustomModule = (id) => ({
  type: REMOVE_CUSTOM_MODULE,
  payload: id,
});

const fetchCustomModulesFail = (e) => ({
  type: FETCH_CUSTOM_MODULES_FAIL,
  payload: e,
});

const changeModuleSuccess = (data) => ({
  type: CHANGE_MODULE_SUCCESS,
  payload: data,
});

export const getCustomModules = () => async (dispatch) => {
  dispatch(fetchCustomModulesStart());
  try {
    const { data } = await get('/modules');
    data.sort((a, b) => a.position - b.position);
    dispatch(fetchCustomModulesSuccess(data));
  } catch (e) {
    dispatch(fetchCustomModulesFail(e));
  }
};

export const fetchModulePermissions = (id) => async (dispatch) => {
  dispatch(fetchModulePermissionsStart());
  try {
    const { data } = await get(`modules/${id}/user-permissions`);
    dispatch(fetchModulePermissionsSuccess(data));
  } catch (e) {
    dispatch(fetchModulePermissionsFail(e));
  }
};

export const updateModule = (module, translations) => async (dispatch) => {
  try {
    await put(`/modules/${module.id}`, module);
    dispatch(changeModuleSuccess(module));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (e) {
    dispatch(fetchCustomModulesFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const changeVisible = (module) => async (dispatch) => {
  try {
    const result = await put(`/modules/${module.id}/change-visibility`, {});
    if (result?.data === true) {
      dispatch(changeModuleSuccess({ ...module, isVisible: !module.isVisible }));
    }
    return !!result?.data;
  } catch (e) {
    return false;
  }
};

export const deleteCustomModule = (id, translations, setDeleteProcess) => async (dispatch) => {
  try {
    await remove(`modules/${id}`);
    dispatch(removeCustomModule(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(fetchCustomModulesFail(e));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const createCustomModules = (module, translations) => async (dispatch) => {
  try {
    const { data } = await post('/modules', module);
    dispatch(writeCustomModulesSuccess(data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (e) {
    dispatch(fetchCustomModulesFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};
