import {
  FETCH_PRODUCTS_DATA_START,
  FETCH_PRODUCT_ATTRIBUTES_SUCCESS,
  FETCH_PRODUCTS_DATA_FAIL,
  SET_PRODUCTS_FILTER_DATA,
} from './action';

const initialState = {
  attributes: [],
  isLoading: true,
  isInitial: false,
  error: null,
  filterData: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_PRODUCTS_DATA_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_PRODUCT_ATTRIBUTES_SUCCESS:
    return {
      ...state,
      attributes: [...payload],
      isLoading: false,
      isInitial: true,
    };
  case FETCH_PRODUCTS_DATA_FAIL:
    return {
      ...state,
      error: payload,
      isLoading: false,
      isInitial: true,
    };
  case SET_PRODUCTS_FILTER_DATA:
    return {
      ...state,
      filterData: payload,
    };
  default:
    return { ...state };
  }
};
