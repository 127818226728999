import {
  post, get, put, remove
} from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const FETCH_FORMS_START = 'FETCH_FORMS_START';
export const FETCH_FORMS_SUCCESS = 'FETCH_FORMS_SUCCESS';
export const FETCH_FORMS_FAIL = 'FETCH_FORMS_FAIL';

export const WRITE_FORM_START = 'WRITE_FORM_START';
export const WRITE_FORM_SUCCESS = 'WRITE_FORM_SUCCESS';
export const WRITE_FORM_FAIL = 'WRITE_FORM_FAIL';

export const CHANGE_FORM_START = 'CHANGE_FORM_START';
export const CHANGE_FORM_SUCCESS = 'CHANGE_FORM_SUCCESS';
export const CHANGE_FORM_FAIL = 'CHANGE_FORM_FAIL';

export const UPDATE_FORM_POSITION_SUCCESS = 'UPDATE_FORM_POSITION_SUCCESS';
export const UPDATE_FORM_FIELD_POSITION_SUCCESS = 'UPDATE_FORM_FIELD_POSITION_SUCCESS';
export const UPDATE_FORMS_LIST = 'UPDATE_FORMS_LIST';

export const DELETE_FORM_START = 'DELETE_FORM_START';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_FAIL = 'DELETE_FORM_FAIL';

export const UPDATE_FORMS_SUCCESS = 'UPDATE_FORMS_SUCCESS';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';

const fetchFormsStart = () => ({
  type: FETCH_FORMS_START,
});

export const fetchFormsSuccess = (data) => ({
  type: FETCH_FORMS_SUCCESS,
  payload: data,
});

const fetchFormsFail = (err) => ({
  type: FETCH_FORMS_FAIL,
  payload: err,
});

const writeFormStart = () => ({
  type: WRITE_FORM_START
});

export const writeFormSuccess = (form) => ({
  type: WRITE_FORM_SUCCESS,
  payload: form
});

const writeFormFail = (err) => ({
  type: WRITE_FORM_FAIL,
  payload: err
});

const changeFormStart = () => ({
  type: CHANGE_FORM_START
});

export const changeFormSuccess = (form) => ({
  type: CHANGE_FORM_SUCCESS,
  payload: form
});

export const updateFormsList = (form) => ({
  type: UPDATE_FORMS_LIST,
  payload: form
});

const changeFormFail = (err) => ({
  type: CHANGE_FORM_FAIL,
  payload: err
});

const deleteFormStart = () => ({
  type: DELETE_FORM_START,
});

const deleteFormSuccess = (id) => ({
  type: DELETE_FORM_SUCCESS,
  payload: id,
});

const deleteFormFail = (err) => ({
  type: DELETE_FORM_FAIL,
  payload: err,
});

export const updateFormsSuccess = (payload) => ({
  type: UPDATE_FORMS_SUCCESS,
  payload
});

const fetchFormById = (data) => ({
  type: FETCH_FORM_SUCCESS,
  payload: data,
});

export const fetchForms = ({ limit, offset, lang }) => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const result = await get(`/forms?limit=${limit}&offset=${offset}&langCode=${lang}`);
    dispatch(fetchFormsSuccess(result.data));
  } catch (e) {
    dispatch(fetchFormsFail(e));
  }
};

export const fetchFormsById = (id, lang) => async (dispatch) => {
  dispatch(fetchFormsStart());
  try {
    const result = await get(`/forms/${id}?langCode=${lang}`);
    dispatch(fetchFormById(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchFormsFail(e));
    return false;
  }
};

export const writeForm = (newForm, translations, setNewItemProcess) => async (dispatch) => {
  dispatch(writeFormStart());
  try {
    const result = await post('/forms', { newForm });
    dispatch(writeFormSuccess(result.data));
    setNewItemProcess((prev) => prev.filter((elId) => elId !== newForm.identifier));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return result.data;
  } catch (e) {
    dispatch(writeFormFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const changeForm = (data, translations, silent = true) => async (dispatch) => {
  dispatch(changeFormStart());
  try {
    await put(`/forms/${data.id}`, data);
    dispatch(changeFormSuccess(data));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return data;
  } catch (e) {
    dispatch(changeFormFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const deleteForm = (id, translations) => async (dispatch) => {
  dispatch(deleteFormStart());
  try {
    await remove(`/forms/${id}`);
    dispatch(deleteFormSuccess(id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(deleteFormFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};
