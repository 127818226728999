import {
  CREATE_CERT_FAIL,
  CREATE_CERT_START,
  CREATE_CERT_SUCCESS,
  DELETE_CERT_FAIL,
  DELETE_CERT_START,
  DELETE_CERT_SUCCESS,
  FETCH_CERTS_FAIL,
  FETCH_CERTS_START,
  FETCH_CERTS_SUCCESS
} from './action';

const initialState = {
  data: [],
  isLoading: true,
  isDeletionRun: false,
  isInitial: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_CERTS_START:
  case CREATE_CERT_START:
    return {
      ...state,
      isLoading: true,
    };

  case DELETE_CERT_START:
    return {
      ...state,
      isDeletionRun: true,
    };

  case FETCH_CERTS_SUCCESS:
    return {
      ...state,
      data: payload,
      isLoading: false,
      isInitial: true,
    };

  case FETCH_CERTS_FAIL:
  case CREATE_CERT_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  case DELETE_CERT_FAIL:
    return {
      ...state,
      isDeletionRun: false,
    };

  case CREATE_CERT_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };

  case DELETE_CERT_SUCCESS:
    return {
      ...state,
      data: state.data.filter((item) => item.id !== payload),
      isDeletionRun: false,
    };

  default:
    return { ...state };
  }
};
