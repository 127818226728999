import {
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAIL,
  FETCH_LOGOUT_ALL_SUCCESS,
  FETCH_RECOVERY_PASSWORD_SUCCESS,
  FETCH_NEW_PASSWORD_SUCCESS,
  FETCH_RECOVERY_PASSWORD_FAIL,
  FETCH_RECOVERY_PASSWORD_START,
  FETCH_LOGOUT_START,
  FETCH_LOGOUT_SUCCESS,
  FETCH_LOGOUT_FAIL,
  FETCH_LOGIN_START,
} from './action';

import { FETCH_ADMIN_LANGUAGE_SUCCESS, FETCH_SET_THEME_SUCCESS } from '../admins/action';
import { SET_LANGUAGE_SUCCESS, SET_THEME_SUCCESS } from '../settings/action';
import { DEFAULT_UI_LANG } from '../../constants/settings';

/**
 * Данные авторизированного юзера,
 * которые мы получаем из токена
 */
const initialState = {
  data: {
    lang: DEFAULT_UI_LANG,
    theme: null,
    id: null,
    login: '',
    email: '',
    accessToken: ''
  },
  isLoading: false,
  isGettingData: true,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_LOGIN_START:
    return {
      ...state,
      isLoading: true,
      isGettingData: true,
    };
  case FETCH_LOGIN_SUCCESS:
    return {
      ...state,
      data: {
        ...payload
      },
      isLoading: false,
    };
  case FETCH_LOGIN_FAIL:
    return {
      ...state,
      isLoading: false
    };
  case FETCH_LOGOUT_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_LOGOUT_SUCCESS:
    return {
      ...state,
      data: {},
      isLoading: false
    };
  case FETCH_LOGOUT_FAIL:
    return {
      ...state,
      isLoading: false
    };
  case FETCH_LOGOUT_ALL_SUCCESS:
    return {
      ...state,
      data: {},
      isLoading: false
    };
  case FETCH_RECOVERY_PASSWORD_START:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_RECOVERY_PASSWORD_FAIL:
    return {
      ...state,
      isLoading: false
    };
  case FETCH_RECOVERY_PASSWORD_SUCCESS:
    return {
      ...state,
      data: payload,
      isLoading: false
    };
  case FETCH_NEW_PASSWORD_SUCCESS:
    return {
      ...state,
      isLoading: false
    };
  case FETCH_ADMIN_LANGUAGE_SUCCESS:
  case SET_LANGUAGE_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        lang: payload
      }
    };
  case FETCH_SET_THEME_SUCCESS:
  case SET_THEME_SUCCESS:
    return {
      ...state,
      data: {
        ...state.data,
        theme: payload
      }
    };
  default:
    return { ...state };
  }
};
