import {
  CREATE_TOKEN_FAIL,
  CREATE_TOKEN_START,
  CREATE_TOKEN_SUCCESS,
  DELETE_TOKEN_FAIL,
  DELETE_TOKEN_START,
  DELETE_TOKEN_SUCCESS,
  FETCH_TOKENS_FAIL,
  FETCH_TOKENS_START,
  FETCH_TOKENS_SUCCESS
} from './action';

const initialState = {
  data: [],
  isLoading: true,
  err: null,
  isInitial: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_TOKENS_START:
  case CREATE_TOKEN_START:
  case DELETE_TOKEN_START:
    return {
      ...state,
      isLoading: true,
    };

  case FETCH_TOKENS_SUCCESS:
    return {
      ...state,
      data: payload,
      isLoading: false,
      isInitial: true,
    };

  case FETCH_TOKENS_FAIL:
  case DELETE_TOKEN_FAIL:
  case CREATE_TOKEN_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  case CREATE_TOKEN_SUCCESS:
    return {
      ...state,
      isLoading: false,
    };

  case DELETE_TOKEN_SUCCESS:
    return {
      ...state,
      data: state.data.filter((item) => item.id !== payload),
      isLoading: false,
    };

  default:
    return { ...state };
  }
};
