// eslint-disable-next-line import/no-cycle
import {
  post,
  setToken
} from '../../api';
import { v4 as uuidv4 } from 'uuid';

export const FETCH_LOGIN_START = 'FETCH_LOGIN_START';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';

export const FETCH_LOGOUT_START = 'FETCH_LOGOUT_START';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
export const FETCH_LOGOUT_FAIL = 'FETCH_LOGOUT_FAIL';

export const FETCH_LOGOUT_ALL_START = 'FETCH_LOGOUT_ALL_START';
export const FETCH_LOGOUT_ALL_SUCCESS = 'FETCH_LOGOUT_ALL_SUCCESS';
export const FETCH_LOGOUT_ALL_FAIL = 'FETCH_LOGOUT_ALL_FAIL';

export const FETCH_RECOVERY_PASSWORD_START = 'FETCH_RECOVERY_PASSWORD_START';
export const FETCH_RECOVERY_PASSWORD_SUCCESS = 'FETCH_RECOVERY_PASSWORD_SUCCESS';
export const FETCH_RECOVERY_PASSWORD_FAIL = 'FETCH_RECOVERY_PASSWORD_FAIL';
export const FETCH_NEW_PASSWORD_SUCCESS = 'FETCH_NEW_PASSWORD_SUCCESS';

const fetchLoginStart = () => ({
  type: FETCH_LOGIN_START
});

const fetchRecoveryPasswordStart = () => ({
  type: FETCH_RECOVERY_PASSWORD_START
});

export const fetchGetPasswordCodeSuccess = (data) => ({
  type: FETCH_RECOVERY_PASSWORD_SUCCESS,
  payload: data
});

export const fetchGetPasswordCodeFail = (err) => ({
  type: FETCH_RECOVERY_PASSWORD_FAIL,
  payload: err
});

export const fetchNewPasswordSuccess = () => ({
  type: FETCH_NEW_PASSWORD_SUCCESS,
});

export const fetchLoginSuccess = (data) => {
  setToken(data.accessToken, data.refreshToken);
  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: data
  };
};
const fetchLoginFail = (err) => ({
  type: FETCH_LOGIN_FAIL,
  payload: err
});

const fetchLogoutAllStart = () => ({
  type: FETCH_LOGOUT_ALL_START
});
export const fetchLogoutAllSuccess = (data) => ({
  type: FETCH_LOGOUT_ALL_SUCCESS,
  payload: data
});
const fetchLogoutAllFail = (err) => ({
  type: FETCH_LOGOUT_ALL_FAIL,
  payload: err
});

export const fetchLogoutStart = () => ({
  type: FETCH_LOGOUT_START
});

export const fetchLogoutSuccess = (data) => ({
  type: FETCH_LOGOUT_SUCCESS,
  payload: data
});

export const fetchLogoutFail = (err) => ({
  type: FETCH_LOGOUT_FAIL,
  payload: err
});

export const fetchLogin = (data) => async (dispatch) => {
  dispatch(fetchLoginStart());
  try {
    const result = await post('/auth/login', data);
    const uuid = uuidv4();
    document.cookie = `accessToken=${result.data.accessToken}; path=/`;
    document.cookie = `refreshToken=${result.data.refreshToken}; path=/`;
    document.cookie = `login=${data.login}; path=/`;
    document.cookie = `userId=${result.data.userId}; path=/`;
    document.cookie = `appId=${result.data.userId}::${uuid}; path=/`;
    return {
      data: result.data,
      status: true
    };
  } catch (e) {
    dispatch(fetchLoginFail(e));
    if (e.response.data.message === 'Login or password incorrect') {
      return {
        status: false,
        data: 'incorrectLoginPassword'
      };
    }
    return {
      status: false,
      data: e.response.data.message
    };
  }
};

export const fetchLogoutAll = () => async (dispatch) => {
  dispatch(fetchLogoutAllStart());
  try {
    await post('/auth/logout/all-users');
    dispatch(fetchLogoutAllSuccess());
    document.cookie = 'login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'userId=; path=/';
    document.cookie = 'appId=; path=/';
  } catch (e) {
    dispatch(fetchLogoutAllFail(e));
  }
};

export const fetchGetPasswordCode = (email) => async (dispatch) => {
  dispatch(fetchRecoveryPasswordStart());
  try {
    await post('/auth/send-code', email);
    dispatch(fetchGetPasswordCodeSuccess(email));
    return {
      status: true,
      email
    };
  } catch (e) {
    dispatch(fetchGetPasswordCodeFail(e));
    return {
      status: false,
      message: e.response.data.message
    };
  }
};

export const fetchSendPasswordCode = (data) => async (dispatch) => {
  dispatch(fetchRecoveryPasswordStart());
  try {
    await post('auth/verify-code', data);
    dispatch(fetchGetPasswordCodeSuccess(data));
    return {
      status: true,
      data
    };
  } catch (e) {
    dispatch(fetchGetPasswordCodeFail(e));
    return {
      status: false,
      message: e.response.data.message
    };
  }
};

export const fetchNewPassword = (data) => async (dispatch) => {
  dispatch(fetchRecoveryPasswordStart());
  try {
    const result = await post('auth/change-password', data);
    document.cookie = `accessToken=${result.data.accessToken}; path=/`;
    document.cookie = `refreshToken=${result.data.refreshToken}; path=/`;
    document.cookie = `login=${result.data.login}; path=/`;
    document.cookie = `userId=${result.data.userId}; path=/`;

    dispatch(fetchLoginSuccess({
      ...result.data,
      login: result.data.login
    }));
    return {
      status: true
    };
  } catch (e) {
    dispatch(fetchGetPasswordCodeFail(e));
    return {
      status: false,
      data: e.response.data.message
    };
  }
};

export const fetchLogout = () => async (dispatch, getState) => {
  const auth = getState().authReducer.data;
  dispatch(fetchLogoutStart());
  try {
    post('/auth/logout/', { refreshToken: auth.refreshToken });
    document.cookie = 'login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'userId=; path=/';
    document.cookie = 'appId=; path=/';
    setToken('', '');
    dispatch(fetchLogoutSuccess());
    return {
      status: true
    };
  } catch (err) {
    dispatch(fetchLogoutFail(err));
    return {
      status: false,
      err
    };
  }
};
