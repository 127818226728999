/* eslint-disable no-case-declarations */
import {
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS_FAIL,
  FETCH_USER_PERMISSIONS_START,
  FETCH_USER_PERMISSION_START,
  FETCH_USER_PERMISSION_SUCCESS,
  FETCH_USER_PERMISSION_FAIL,
  WRITE_USER_PERMISSION_START,
  WRITE_USER_PERMISSION_FAIL,
  WRITE_USER_PERMISSION_SUCCESS,
  CHANGE_USER_PERMISSION_SUCCESS
} from './action';
import { cloneDeep } from 'lodash';

const initialState = {
  list: [],
  selected: {},
  isLoading: false,
  err: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_USER_PERMISSIONS_START:
    return {
      ...state,
      isLoading: true,
      err: null,
    };

  case FETCH_USER_PERMISSION_START:
  case WRITE_USER_PERMISSION_START:
    return {
      ...state,
      err: null,
    };

  case FETCH_USER_PERMISSIONS_SUCCESS:
    return {
      ...state,
      list: payload,
      isLoading: false,
    };

  case FETCH_USER_PERMISSION_SUCCESS:
    return {
      ...state,
      selected: payload,
      isLoading: false,
    };

  case FETCH_USER_PERMISSION_FAIL:
  case FETCH_USER_PERMISSIONS_FAIL:
  case WRITE_USER_PERMISSION_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
    };

  case CHANGE_USER_PERMISSION_SUCCESS:
    let modifiedData = cloneDeep(state.list);
    modifiedData = modifiedData.map((p) => (p.id === payload.id ? payload : p));
    return {
      ...state,
      list: modifiedData,
      isLoading: false,
      selected: state.selected && state.selected.id === payload.id ? payload : state.selected
    };

  case WRITE_USER_PERMISSION_SUCCESS:
    return {
      ...state,
      list: [...state.list, payload],
      isLoading: false,
    };

  default:
    return { ...state };
  }
};
