import { store } from 'react-notifications-component';
import {
  get,
  post,
  put,
  remove,
} from '@api';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '@constants/notification-settings';

export const FETCH_MENUS_START = 'FETCH_MENUS_START';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_FAIL = 'FETCH_MENUS_FAIL';
export const CREATE_MENU_START = 'CREATE_MENU_START';
export const CREATE_MENU_SUCCESS = 'CREATE_MENU_SUCCESS';
export const CREATE_MENU_FAIL = 'CREATE_MENU_FAIL';
export const DELETE_MENU_START = 'DELETE_MENU_START';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAIL = 'DELETE_MENU_FAIL';
export const FETCH_MENU_ITEM_START = 'FETCH_MENU_ITEM_START';
export const FETCH_MENU_ITEM_SUCCESS = 'FETCH_MENU_ITEM_SUCCESS';
export const FETCH_MENU_ITEM_FAIL = 'FETCH_MENU_ITEM_FAIL';
export const UPDATE_MENU_TITLE_START = 'UPDATE_MENU_TITLE_START';
export const UPDATE_MENU_TITLE_SUCCESS = 'UPDATE_MENU_TITLE_SUCCESS';
export const UPDATE_MENU_TITLE_FAIL = 'UPDATE_MENU_TITLE_FAIL';
export const UPDATE_MENU_SELECTION_START = 'UPDATE_MENU_SELECTION_START';
export const UPDATE_MENU_SELECTION_SUCCESS = 'UPDATE_MENU_SELECTION_SUCCESS';
export const UPDATE_MENU_SELECTION_FAIL = 'UPDATE_MENU_SELECTION_FAIL';

export const UPDATE_MENUS_POSITIONS = 'UPDATE_MENUS_POSITIONS';
export const SET_INCLUDED_MENU_ITEMS = 'SET_INCLUDED_MENU_ITEMS';
export const SET_INCLUDED_MENU_ITEMS_BY_MENU_ID = 'SET_INCLUDED_MENU_ITEMS_BY_MENU_ID';

export const updateMenusPositions = (payload) => ({
  type: UPDATE_MENUS_POSITIONS,
  payload
});

export const setIncludedMenuItems = (payload) => ({
  type: SET_INCLUDED_MENU_ITEMS,
  payload: Array.isArray(payload) ? payload : []
});

export const setIncludedMenuItemsByMenuId = (payload) => ({
  type: SET_INCLUDED_MENU_ITEMS_BY_MENU_ID,
  payload
});

const fetchMenusStart = () => ({
  type: FETCH_MENUS_START,
});

export const fetchMenusSuccess = (payload) => ({
  type: FETCH_MENUS_SUCCESS,
  payload,
});

const fetchMenusFail = (error) => ({
  type: FETCH_MENUS_FAIL,
  payload: error,
});

const createMenuStart = () => ({
  type: CREATE_MENU_START,
});

export const createMenuSuccess = (payload) => ({
  type: CREATE_MENU_SUCCESS,
  payload,
});

const createMenuFail = (error) => ({
  type: CREATE_MENU_FAIL,
  payload: error,
});

const deleteMenuStart = () => ({
  type: DELETE_MENU_START,
});

export const deleteMenuSuccess = (payload) => ({
  type: DELETE_MENU_SUCCESS,
  payload,
});

const deleteMenuFail = (error) => ({
  type: DELETE_MENU_FAIL,
  payload: error,
});

const fetchMenuItemStart = () => ({
  type: FETCH_MENU_ITEM_START,
});

const fetchMenuItemSuccess = (payload) => ({
  type: FETCH_MENU_ITEM_SUCCESS,
  payload,
});

const fetchMenuItemFail = (error) => ({
  type: FETCH_MENU_ITEM_FAIL,
  payload: error,
});

const updateMenuTitleStart = () => ({
  type: UPDATE_MENU_TITLE_START,
});

const updateMenuTitleSuccess = (payload) => ({
  type: UPDATE_MENU_TITLE_SUCCESS,
  payload,
});

const updateMenuTitleFail = (error) => ({
  type: UPDATE_MENU_TITLE_FAIL,
  payload: error,
});

const updateMenuSelectionStart = () => ({
  type: UPDATE_MENU_SELECTION_START,
});

const updateMenuSelectionSuccess = (payload) => ({
  type: UPDATE_MENU_SELECTION_SUCCESS,
  payload,
});

const updateMenuSelectionFail = (error) => ({
  type: UPDATE_MENU_SELECTION_FAIL,
  payload: error,
});

export const fetchMenus = () => async (dispatch) => {
  dispatch(fetchMenusStart());
  try {
    const response = await get('/menus');
    dispatch(fetchMenusSuccess({
      data: response.data,
    }));
  } catch (e) {
    dispatch(fetchMenusFail(e));
  }
};

export const createMenu = (body, translations, setNewItemProcess) => async (dispatch) => {
  dispatch(createMenuStart());
  try {
    const response = await post('/menus', body);
    dispatch(createMenuSuccess({
      data: response.data,
    }));
    setNewItemProcess((prev) => prev.filter((elId) => elId !== body.identifier));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(createMenuFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const deleteMenu = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteMenuStart());
  try {
    await remove(`/menus/${id}`);
    dispatch(deleteMenuSuccess(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(deleteMenuFail(e));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
    return false;
  }
};

export const fetchMenuItem = (id) => async (dispatch) => {
  dispatch(fetchMenuItemStart());
  try {
    const response = await get(`/menus/${id}`);
    dispatch(fetchMenuItemSuccess({
      data: response.data,
    }));
  } catch (e) {
    dispatch(fetchMenuItemFail(e));
  }
};

export const fetchMenuIncludedItems = (id) => async (dispatch) => {
  try {
    const response = await get(`/menus/${id}/included`);
    dispatch(setIncludedMenuItems(response.data));
  } catch (e) {
    dispatch(fetchMenuItemFail(e));
  }
};

export const updateMenuTitle = (id, body, translations) => async (dispatch) => {
  dispatch(updateMenuTitleStart());
  try {
    await put(`/menus/${id}`, body);
    dispatch(updateMenuTitleSuccess({
      id,
      localizeInfos: body.localizeInfos,
      pagesIds: body.pagesIds,
    }));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(updateMenuTitleFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const updateMenuSelection = (id, body, translations) => async (dispatch) => {
  dispatch(updateMenuSelectionStart());
  try {
    await put(`/menus/${id}`, body);
    dispatch(updateMenuSelectionSuccess({
      id,
      localizeInfos: body.localizeInfos,
      pagesIds: body.pagesIds,
    }));
    dispatch(fetchMenuIncludedItems(id));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(updateMenuSelectionFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};
