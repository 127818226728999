import { get, post, put } from '@api';
import { store } from 'react-notifications-component';
import { COMMON_DATA_UPDATE_FAIL, COMMON_DATA_UPDATE_SUCCESS } from '../../constants/notification-settings';

export const FETCH_LOCALES_START = 'FETCH_LOCALES_START';
export const FETCH_LOCALES_SUCCESS = 'FETCH_LOCALES_SUCCESS';
export const FETCH_LOCALES_FAIL = 'FETCH_LOCALES_FAIL';

export const CREATE_LOCALE_START = 'CREATE_LOCALE_START';
export const CREATE_LOCALE_SUCCESS = 'CREATE_LOCALE_SUCCESS';
export const CREATE_LOCALE_FAIL = 'CREATE_LOCALE_FAIL';

export const UPDATE_LOCALE_START = 'UPDATE_LOCALE_START';
export const UPDATE_LOCALE_SUCCESS = 'UPDATE_LOCALE_SUCCESS';
export const UPDATE_LOCALE_FAIL = 'UPDATE_LOCALE_FAIL';

export const SET_LOCALE_ACTIVE = 'SET_LOCALE_ACTIVE';

const createLocaleStart = () => ({
  type: CREATE_LOCALE_START,
});

const createLocaleSuccess = (payload) => ({
  type: CREATE_LOCALE_SUCCESS,
  payload,
});

const createLocaleFail = (error) => ({
  type: CREATE_LOCALE_FAIL,
  payload: error,
});

const updateLocaleStart = () => ({
  type: UPDATE_LOCALE_START,
});

const updateLocaleFail = (error) => ({
  type: UPDATE_LOCALE_FAIL,
  payload: error,
});

const fetchLocalesStart = () => ({
  type: FETCH_LOCALES_START,
});

export const fetchLocalesSuccess = (payload) => ({
  type: FETCH_LOCALES_SUCCESS,
  payload,
});

export const setLocaleActive = (payload) => ({
  type: SET_LOCALE_ACTIVE,
  payload,
});

const fetchLocalesFail = (payload) => ({
  type: FETCH_LOCALES_FAIL,
  payload,
});

export const fetchActiveLocales = () => async (dispatch) => {
  dispatch(fetchLocalesStart());
  try {
    const result = await get('/locales/active/all');
    dispatch(fetchLocalesSuccess(result?.data || []));
  } catch (e) {
    dispatch(fetchLocalesFail(e));
  }
};

export const createLocale = (locale, translations) => async (dispatch) => {
  dispatch(createLocaleStart());
  try {
    const result = await post('/locales/', locale);
    if (result?.data?.id) {
      await dispatch(createLocaleSuccess(result?.data));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      return true;
    }
    dispatch(createLocaleFail(result?.data));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  } catch (e) {
    dispatch(createLocaleFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const updateLocale = (locale, translations, silent = true) => async (dispatch) => {
  dispatch(updateLocaleStart());
  try {
    const result = await put(`/locales/${locale.id}`, locale);
    if (!silent && !!result?.data) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    console.log(e);
    dispatch(updateLocaleFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const getLocalePage = (id) => async () => {
  try {
    const result = await get(`/locales/${id}`);
    return result.data;
  } catch (e) {
    console.log(e);
  }
};
