import { get, post, remove } from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const FETCH_TOKENS_START = 'FETCH_TOKENS_START';
export const FETCH_TOKENS_SUCCESS = 'FETCH_TOKENS_SUCCESS';
export const FETCH_TOKENS_FAIL = 'FETCH_TOKENS_FAIL';

export const CREATE_TOKEN_START = 'CREATE_TOKEN_START';
export const CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
export const CREATE_TOKEN_FAIL = 'CREATE_TOKEN_FAIL';

export const DELETE_TOKEN_START = 'DELETE_TOKEN_START';
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS';
export const DELETE_TOKEN_FAIL = 'DELETE_TOKEN_FAIL';

const createTokenStart = () => ({
  type: CREATE_TOKEN_START,
});

const createTokenSuccess = (payload) => ({
  type: CREATE_TOKEN_SUCCESS,
  payload,
});

const createTokenFail = (error) => ({
  type: CREATE_TOKEN_FAIL,
  payload: error,
});

const deleteTokenStart = () => ({
  type: DELETE_TOKEN_START,
});

const deleteTokenSuccess = (payload) => ({
  type: DELETE_TOKEN_SUCCESS,
  payload,
});

const deleteTokenFail = (error) => ({
  type: DELETE_TOKEN_FAIL,
  payload: error,
});

const fetchTokensStart = () => ({
  type: FETCH_TOKENS_START,
});

export const fetchTokensSuccess = (payload) => ({
  type: FETCH_TOKENS_SUCCESS,
  payload,
});

const fetchTokensFail = (payload) => ({
  type: FETCH_TOKENS_FAIL,
  payload,
});

export const createToken = (payload, translations, setNewItemProcess) => async (dispatch) => {
  dispatch(createTokenStart());
  try {
    const result = await post('/system/tokens/generate', {
      name: payload.name,
      expiredDate: payload?.expiredDate,
    });
    if (!!result?.data === true) {
      dispatch(createTokenSuccess());
      dispatch(fetchAllTokens());
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      setNewItemProcess((prev) => prev.filter((elId) => elId !== payload.name));
      return true;
    }
    dispatch(createTokenFail(result?.data));
    setNewItemProcess((prev) => prev.filter((elId) => elId !== payload.name));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  } catch (e) {
    dispatch(createTokenFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const deleteToken = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteTokenStart());
  try {
    const result = await remove(`/system/tokens/${id}`);
    if (!!result?.data === true) {
      dispatch(deleteTokenSuccess(id));
      store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
      return true;
    }
    dispatch(deleteTokenFail(result?.data));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  } catch (e) {
    dispatch(deleteTokenFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

export const fetchAllTokens = () => async (dispatch) => {
  dispatch(fetchTokensStart());
  try {
    const result = await get('/system/tokens');
    dispatch(fetchTokensSuccess(result?.data || []));
  } catch (e) {
    dispatch(fetchTokensFail(e));
  }
};
