import {
  get,
} from '../../api';

export const FETCH_PERMISSIONS_START = 'FETCH_GET_PERMISSIONS';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL';

const fetchGetPermissionsStart = () => ({
  type: FETCH_PERMISSIONS_START
});

const fetchGetPermissionsSuccess = (data) => ({
  type: FETCH_PERMISSIONS_SUCCESS,
  payload: data
});

const fetchGetPermissionsFail = (err) => ({
  type: FETCH_PERMISSIONS_FAIL,
  payload: err
});

export const fetchGetPermissions = () => async (dispatch) => {
  dispatch(fetchGetPermissionsStart());
  try {
    const result = await get('/admins/permissions/all');
    dispatch(fetchGetPermissionsSuccess(result.data));
  } catch (e) {
    dispatch(fetchGetPermissionsFail(e));
  }
};
