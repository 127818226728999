/* eslint-disable camelcase */
import { Spinner } from 'reactstrap';
import React from 'react';
import { useApp } from '@hooks/useApp';

const Preloader = ({ height = '82vh' }) => {
  const { translations } = useApp();
  return (
    <div
      className="main-preloader"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height,
      }}
    >
      <Spinner color="secondary" />
      <div className="mt-2">{translations?.step1_fallbackSpinner}</div>
    </div>
  );
};

export { Preloader };
