/* eslint-disable no-case-declarations */
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';
import {
  FETCH_FORMS_START,
  FETCH_FORMS_SUCCESS,
  FETCH_FORMS_FAIL,
  WRITE_FORM_START,
  WRITE_FORM_SUCCESS,
  WRITE_FORM_FAIL,
  CHANGE_FORM_START,
  CHANGE_FORM_SUCCESS,
  CHANGE_FORM_FAIL,
  DELETE_FORM_START,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAIL,
  UPDATE_FORMS_SUCCESS,
  FETCH_FORM_SUCCESS,
  UPDATE_FORM_POSITION_SUCCESS,
  UPDATE_FORM_FIELD_POSITION_SUCCESS,
  UPDATE_FORMS_LIST,
} from './action';

const initialState = {
  forms: [],
  form: null,
  isLoading: true,
  isInitial: false,
  err: null,
};

let modifiedData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_FORMS_START:
  case WRITE_FORM_START:
  case CHANGE_FORM_START:
  case DELETE_FORM_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_FORMS_SUCCESS:
    return {
      ...state,
      forms: [...payload],
      isLoading: false,
      isInitial: true,
    };
  case FETCH_FORM_SUCCESS:
    return {
      ...state,
      form: payload,
      isLoading: false,
      isInitial: true,
    };
  case WRITE_FORM_SUCCESS:
    if (state.forms[state.forms.length - 1]?.id === payload.id) {
      return { ...state };
    }
    return {
      ...state,
      forms: [...state.forms, payload],
      isLoading: false
    };
  case CHANGE_FORM_SUCCESS:
    return {
      ...state,
      forms: state.forms.map((item) => (item.id === payload.id ? { ...payload } : item)),
      form: { ...payload },
      isLoading: false
    };
  case UPDATE_FORMS_LIST:
    return {
      ...state,
      forms: state.forms.map((item) => (+item.id === +payload.id ? { ...payload } : item)),
    };
  case DELETE_FORM_SUCCESS:
    return {
      ...state,
      forms: state.forms.filter((item) => item.id !== payload),
      isLoading: false
    };
  case UPDATE_FORMS_SUCCESS:
    modifiedData = update(state.forms, {
      $splice: [
        [payload.dragIndex, 1],
        [payload.hoverIndex, 0, payload.dragCard],
      ],
    });
    return {
      ...state,
      forms: modifiedData,
    };
  case FETCH_FORMS_FAIL:
  case WRITE_FORM_FAIL:
  case CHANGE_FORM_FAIL:
  case DELETE_FORM_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false
    };
  case UPDATE_FORM_POSITION_SUCCESS:
    modifiedData = state.forms.map((item) => {
      if (payload[item.id]) {
        const clonedItem = cloneDeep(item);
        clonedItem.position = payload[item.id];
        return clonedItem;
      }

      return item;
    });
    modifiedData.sort((a, b) => a.position - b.position);
    return {
      ...state,
      forms: modifiedData,
      isLoading: false
    };
  case UPDATE_FORM_FIELD_POSITION_SUCCESS:
    const currentForm = state.forms.find((item) => item.id === payload.parentId);
    const clonedForm = cloneDeep(currentForm);
    modifiedData = currentForm.fields.map((item) => {
      if (payload.positions[item.id]) {
        item.position = payload.positions[item.id];
      }

      return item;
    });
    modifiedData.sort((a, b) => a.position - b.position);
    clonedForm.fields = modifiedData;

    const updatedForms = state.forms.map((item) => {
      return item.id === payload.parentId ? clonedForm : item;
    });

    return {
      ...state,
      forms: updatedForms,
      form: clonedForm,
      isLoading: false
    };
  default:
    return { ...state };
  }
};
