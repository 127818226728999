/* eslint-disable no-case-declarations */
import update from 'immutability-helper';
import {
  CHANGE_MODULE_SUCCESS,
  FETCH_CUSTOM_MODULES_FAIL,
  FETCH_CUSTOM_MODULES_START,
  FETCH_CUSTOM_MODULES_SUCCESS,
  WRITE_CUSTOM_MODULES_SUCCESS,
  UPDATE_MODULE_SET,
  FETCH_MODULE_SUCCESS,
  REMOVE_CUSTOM_MODULE,
  UPDATE_MODULE_POSITIONS,
  FETCH_MODULE_PERMISSIONS_SUCCESS,
  FETCH_MODULE_PERMISSIONS_FAIL,
} from './action';

/**
 * @todo: обычные модули
 */
const initialState = {
  customModules: [],
  isLoading: true,
  isInitial: false,
  selectedModulePermissions: [],
  err: null,
};

let modifiedData;

export default (state = initialState, { type, payload }) => {
  switch (type) {
  case FETCH_CUSTOM_MODULES_START:
    return {
      ...state,
      isLoading: true,
    };

  case WRITE_CUSTOM_MODULES_SUCCESS:
    return {
      ...state,
      customModules: payload,
      isLoading: false,
    };

  case FETCH_CUSTOM_MODULES_SUCCESS: {
    return {
      ...state,
      customModules: payload,
      isLoading: false,
      isInitial: true,
    };
  }

  case FETCH_MODULE_PERMISSIONS_SUCCESS: {
    return {
      ...state,
      selectedModulePermissions: payload,
    };
  }

  case FETCH_MODULE_PERMISSIONS_FAIL: {
    return {
      ...state,
      selectedModulePermissions: [],
    };
  }

  case UPDATE_MODULE_POSITIONS: {
    return {
      ...state,
      customModules: state.customModules
        .map((item) => (item.id === payload.id ? ({ ...item, position: payload.position }) : item))
        .sort((a, b) => b.position.localeCompare(a.position)),
    };
  }

  case CHANGE_MODULE_SUCCESS: {
    return {
      ...state,
      customModules: state.customModules.map((item) => (item.id === payload.id ? payload : item)),
      isLoading: false,
    };
  }

  case FETCH_MODULE_SUCCESS:
    return {
      ...state,
      customModules: [...payload.items],
      isLoading: false,
    };

  case UPDATE_MODULE_SET:
    modifiedData = update(state.customModules, {
      $splice: [
        [payload.dragIndex, 1],
        [payload.hoverIndex, 0, payload.dragCard],
      ],
    });
    return {
      ...state,
      customModules: modifiedData
    };

  case FETCH_CUSTOM_MODULES_FAIL:
    return {
      ...state,
      err: payload,
      isLoading: false,
      isInitial: true,
    };

  case REMOVE_CUSTOM_MODULE:
    const filteredModule = state.customModules.filter((el) => el.id !== payload).sort((a, b) => a.position - b.position);
    return {
      ...state,
      customModules: filteredModule,
      isLoading: false,
    };

  default:
    return { ...state };
  }
};
