import {
  FETCH_MENUS_START,
  FETCH_MENUS_SUCCESS,
  FETCH_MENUS_FAIL,
  CREATE_MENU_START,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_FAIL,
  DELETE_MENU_START,
  DELETE_MENU_SUCCESS,
  DELETE_MENU_FAIL,
  FETCH_MENU_ITEM_START,
  FETCH_MENU_ITEM_SUCCESS,
  FETCH_MENU_ITEM_FAIL,
  UPDATE_MENU_TITLE_START,
  UPDATE_MENU_TITLE_SUCCESS,
  UPDATE_MENU_TITLE_FAIL,
  UPDATE_MENU_SELECTION_START,
  UPDATE_MENU_SELECTION_SUCCESS,
  UPDATE_MENU_SELECTION_FAIL,
  UPDATE_MENUS_POSITIONS,
  SET_INCLUDED_MENU_ITEMS,
  SET_INCLUDED_MENU_ITEMS_BY_MENU_ID,
} from './action';

const initialState = {
  loadingMenusErr: null,
  createErr: null,
  deleteErr: null,
  updateTitleErr: null,
  updateSelectionErr: null,
  loadingMenuErr: null,
  isLoading: false,
  isCreating: false,
  isDeleting: false,
  isInitial: false,
  isTitleUpdating: false,
  isSelectionUpdating: false,
  isMenuLoading: false,
  includedItems: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
  /**
   * Мы должны перенести значения showChildren из стейта в новые данные
   */
  case SET_INCLUDED_MENU_ITEMS:
    return {
      ...state,
      includedItems: payload,
    };
  case SET_INCLUDED_MENU_ITEMS_BY_MENU_ID:
    return {
      ...state,
      includedItems: payload?.menuId === state.menu?.id ? payload.includedItems : state.includedItems
    };
  case UPDATE_MENUS_POSITIONS:
    return {
      ...state,
      includedItems: payload
    };
  case FETCH_MENUS_START:
    return {
      ...state,
      isLoading: true,
    };
  case FETCH_MENUS_SUCCESS:
  {
    return {
      ...state,
      menus: [...payload.data].map((menu) => ({
        ...menu,
        pagesIds: menu.menuPages.map((page) => page.pageId),
      })),
      isLoading: false,
      isInitial: true,
    };
  }
  case FETCH_MENUS_FAIL:
    return {
      ...state,
      loadingMenusErr: payload,
      isLoading: false,
      isInitial: true,
    };
  case CREATE_MENU_START:
    return {
      ...state,
      isCreating: true,
    };
  case CREATE_MENU_SUCCESS:
  {
    if (state.menus.find((menu) => menu.id === payload.data.id)) {
      return {
        ...state,
      };
    }
    return {
      ...state,
      menus: [
        ...state.menus,
        payload.data,
      ],
      isCreating: false,
    };
  }
  case CREATE_MENU_FAIL:
    return {
      ...state,
      createErr: payload,
      isCreating: false,
    };
  case DELETE_MENU_START:
    return {
      ...state,
      isDeleting: true,
    };
  case DELETE_MENU_SUCCESS:
  {
    return {
      ...state,
      menus: state.menus?.filter((item) => item.id !== payload),
      isDeleting: false,
    };
  }
  case DELETE_MENU_FAIL:
    return {
      ...state,
      deleteErr: payload,
      isDeleting: false,
    };
  case FETCH_MENU_ITEM_START:
    return {
      ...state,
      isMenuLoading: true,
    };
  case FETCH_MENU_ITEM_SUCCESS:
  {
    return {
      ...state,
      menu: {
        ...payload.data,
        pagesIds: payload.data.menuPages.map((page) => page.pageId),
      },
      isMenuLoading: false,
      isInitial: true,
    };
  }
  case FETCH_MENU_ITEM_FAIL:
    return {
      ...state,
      menuErr: payload,
      isMenuLoading: false,
      isInitial: true,
    };
  case UPDATE_MENU_TITLE_START:
    return {
      ...state,
      isTitleUpdating: true,
    };
  case UPDATE_MENU_TITLE_SUCCESS:
  {
    let menu = state.menu ?? null;
    if (menu && menu.id === payload.id) {
      menu = { ...menu, localizeInfos: payload.localizeInfos };
    }
    return {
      ...state,
      menus: [...(state.menus || [])].map((el) => {
        if (el.id === payload.id) {
          return {
            ...el,
            localizeInfos: payload.localizeInfos
          };
        }
        return el;
      }),
      menu,
      isTitleUpdating: false,
    };
  }
  case UPDATE_MENU_TITLE_FAIL:
    return {
      ...state,
      updateTitleErr: payload,
      isTitleUpdating: false,
    };

  case UPDATE_MENU_SELECTION_START:
    return {
      ...state,
      isSelectionUpdating: true,
    };
  case UPDATE_MENU_SELECTION_SUCCESS:
  {
    let menu = state.menu ?? null;
    if (menu && menu.id === payload.id) {
      menu = { ...menu, pagesIds: payload.pagesIds };
    }
    return {
      ...state,
      menus: [...(state.menus || [])].map((el) => {
        if (el.id === payload.id) {
          return {
            ...el,
            pagesIds: payload.pagesIds,
          };
        }
        return el;
      }),
      menu,
      isSelectionUpdating: false,
    };
  }
  case UPDATE_MENU_SELECTION_FAIL:
    return {
      ...state,
      updateSelectionErr: payload,
      isSelectionUpdating: false,
    };
  default:
    return { ...state };
  }
};
