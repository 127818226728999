import {
  post, get, put, remove
} from '@api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';
import { getSettings } from '../settings/action';

export const FETCH_ATTRIBUTES_SETS_START = 'FETCH_ATTRIBUTES_SETS_START';
export const FETCH_ATTRIBUTES_SETS_SUCCESS = 'FETCH_ATTRIBUTES_SETS_SUCCESS';
export const FETCH_ATTRIBUTES_SETS_FAIL = 'FETCH_ATTRIBUTES_SETS_FAIL';
export const WRITE_ATTRIBUTES_SET_START = 'WRITE_ATTRIBUTES_SET_START';
export const WRITE_ATTRIBUTES_SET_SUCCESS = 'WRITE_ATTRIBUTES_SET_SUCCESS';
export const WRITE_ATTRIBUTES_SET_FAIL = 'WRITE_ATTRIBUTES_SET_FAIL';
export const DELETE_ATTRIBUTES_SET_START = 'DELETE_ATTRIBUTES_SET_START';
export const DELETE_ATTRIBUTES_SET_SUCCESS = 'DELETE_ATTRIBUTES_SET_SUCCESS';
export const DELETE_ATTRIBUTES_SET_FAIL = 'DELETE_ATTRIBUTES_SET_FAIL';
export const CHANGE_ATTRIBUTES_SET_SUCCESS = 'CHANGE_ATTRIBUTES_SET_SUCCESS';
export const UPDATE_ATTRIBUTES_SET_SUCCESS = 'UPDATE_ATTRIBUTES_SET_SUCCESS';
export const UPDATE_ATTRIBUTES_POSITION = 'UPDATE_ATTRIBUTES_POSITION';
export const UPDATE_ATTRIBUTES_SCHEMA_WITH_POSITIONS = 'UPDATE_ATTRIBUTES_SCHEMA_WITH_POSITIONS';

const fetchAttributesSetsStart = () => ({
  type: FETCH_ATTRIBUTES_SETS_START
});

export const fetchAttributesSetsSuccess = (data) => ({
  type: FETCH_ATTRIBUTES_SETS_SUCCESS,
  payload: data
});

const fetchAttributesSetsFail = (err) => ({
  type: FETCH_ATTRIBUTES_SETS_FAIL,
  payload: err
});

const writeAttributesSetStart = () => ({
  type: WRITE_ATTRIBUTES_SET_START
});

export const writeAttributesSetSuccess = (newAttr) => ({
  type: WRITE_ATTRIBUTES_SET_SUCCESS,
  payload: newAttr
});

const writeAttributesSetFail = (err) => ({
  type: WRITE_ATTRIBUTES_SET_FAIL,
  payload: err
});

const deleteAttributesSetStart = () => ({
  type: DELETE_ATTRIBUTES_SET_START
});

export const deleteAttributesSetSuccess = (id) => ({
  type: DELETE_ATTRIBUTES_SET_SUCCESS,
  payload: id
});

const deleteAttributesSetFail = (err) => ({
  type: DELETE_ATTRIBUTES_SET_FAIL,
  payload: err
});

const changeAttributesSetSuccess = (changedSet) => ({
  type: CHANGE_ATTRIBUTES_SET_SUCCESS,
  payload: changedSet,
});

export const updateAttributesSetSuccess = (draggedItem) => ({
  type: UPDATE_ATTRIBUTES_SET_SUCCESS,
  payload: draggedItem
});

export const updateAttributesPosition = (positions) => ({
  type: UPDATE_ATTRIBUTES_POSITION,
  payload: positions
});

export const updateAttributesSchemaWithPositions = (payload) => ({
  type: UPDATE_ATTRIBUTES_SCHEMA_WITH_POSITIONS,
  payload
});

export const fetchAttributesSets = ({ limit, offset }) => async (dispatch) => {
  dispatch(fetchAttributesSetsStart());
  try {
    const result = await get(`/attributes-sets?limit=${limit}&offset=${offset}`);
    result.data.items.sort((a, b) => a.position - b.position);
    dispatch(fetchAttributesSetsSuccess(result.data));
  } catch (e) {
    dispatch(fetchAttributesSetsFail(e));
  }
};

export const writeAttributesSet = (newSet, translations) => async (dispatch) => {
  dispatch(writeAttributesSetStart());
  try {
    const result = await post('/attributes-sets', newSet);
    dispatch(writeAttributesSetSuccess(result.data));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(writeAttributesSetFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

export const deleteAttributesSet = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteAttributesSetStart());
  try {
    const result = await remove(`/attributes-sets/${id}`);
    if (result?.data?.result) {
      dispatch(deleteAttributesSetSuccess(id));
      store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
      setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    } else {
      store.addNotification(COMMON_DATA_DELETE_FAIL(translations, result?.data?.message));
      setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
      dispatch(deleteAttributesSetFail());
    }
  } catch (e) {
    dispatch(deleteAttributesSetFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};

/**
 * @deprecated or should be rewritten
 */
export const updatePositionAttributes = (attributes, translations) => async (dispatch) => {
  dispatch(writeAttributesSetStart());
  try {
    const positions = await put('/attributes-sets/position', attributes);
    dispatch(updateAttributesPosition(positions.data.payload));
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    return true;
  } catch (e) {
    dispatch(writeAttributesSetFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const changeAttributesSet = (changedSet, translations, deleted, deletedId, callback) => async (dispatch) => {
  dispatch(writeAttributesSetStart());
  try {
    const { type, ...cleanedSet } = changedSet;
    await put(`/attributes-sets/${changedSet.id}`, {
      ...cleanedSet, deleted, deletedId
    });
    dispatch(changeAttributesSetSuccess(changedSet));
    dispatch(getSettings());
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    if (callback) {
      callback();
    }
    return true;
  } catch (e) {
    dispatch(writeAttributesSetFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

export const changeAttributesSetSchema = (id, schema) => async (dispatch) => {
  try {
    await put(`/attributes-sets/${id}/schema`, schema);
    dispatch(updateAttributesSchemaWithPositions({ id: +id, schema }));
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const changeVisible = (attributes) => async (dispatch) => {
  try {
    const result = await put(`/attributes-sets/${attributes.id}/change-visibility`, {});
    if (result?.data === true) {
      dispatch(changeAttributesSetSuccess({ ...attributes, isVisible: !attributes.isVisible }));
    }
    return !!result?.data;
  } catch (e) {
    return false;
  }
};
