import { get, post, put, remove } from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS,
} from '../../constants/notification-settings';

export const FETCH_BLOCKS_START = 'FETCH_BLOCKS_START';
export const FETCH_BLOCKS_SUCCESS = 'FETCH_BLOCKS_SUCCESS';
export const FETCH_BLOCKS_FAIL = 'FETCH_BLOCKS_FAIL';

export const FETCH_BLOCK_START = 'FETCH_BLOCK_START';
export const FETCH_BLOCK_SUCCESS = 'FETCH_BLOCK_SUCCESS';
export const FETCH_BLOCK_FAIL = 'FETCH_BLOCK_FAIL';

export const CREATE_BLOCK_START = 'CREATE_BLOCK_START';
export const CREATE_BLOCK_SUCCESS = 'CREATE_BLOCK_SUCCESS';
export const CREATE_BLOCK_FAIL = 'CREATE_BLOCK_FAIL';

export const UPDATE_BLOCK_START = 'UPDATE_BLOCK_START';
export const UPDATE_BLOCK_SUCCESS = 'UPDATE_BLOCK_SUCCESS';
export const UPDATE_BLOCK_FAIL = 'UPDATE_BLOCK_FAIL';

export const UPDATE_BLOCK_IMAGE_START = 'UPDATE_BLOCK_IMAGE_START';
export const UPDATE_BLOCK_IMAGE_SUCCESS = 'UPDATE_BLOCK_IMAGE_SUCCESS';
export const UPDATE_BLOCK_IMAGE_FAIL = 'UPDATE_BLOCK_IMAGE_FAIL';

export const DELETE_BLOCK_START = 'DELETE_BLOCK_START';
export const DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS';
export const DELETE_BLOCK_FAIL = 'DELETE_BLOCK_FAIL';

export const UPDATE_BLOCKS_POSITION = 'UPDATE_BLOCKS_POSITION';

const fetchBlocksStart = () => ({
  type: FETCH_BLOCKS_START,
});

export const fetchBlocksSuccess = (payload) => ({
  type: FETCH_BLOCKS_SUCCESS,
  payload,
});

const fetchBlocksFail = (error) => ({
  type: FETCH_BLOCKS_FAIL,
  payload: error,
});

export const fetchBlocks = (props) =>
  async (dispatch) => {
    dispatch(fetchBlocksStart());
    try {
        const params = new URLSearchParams();
        // eslint-disable-next-line array-callback-return
        Object.keys(props).map((key) => {
          // eslint-disable-next-line no-unused-expressions
          props[key] && params.append(key, props[key]);
        });
      const result = await get(
        `/blocks?${params}`,
      );
      dispatch(fetchBlocksSuccess(result.data.items));
      return result.data;
    } catch (e) {
      dispatch(fetchBlocksFail(e));
      return e;
    }
  };

const fetchBlockStart = (id) => ({
  type: FETCH_BLOCK_START,
  payload: id,
});

const fetchBlockSuccess = (payload) => ({
  type: FETCH_BLOCK_SUCCESS,
  payload,
});

const fetchBlockFail = (error) => ({
  type: FETCH_BLOCK_FAIL,
  payload: error,
});

export const fetchBlock = (id) => async (dispatch) => {
  dispatch(fetchBlockStart());
  try {
    const result = await get(`/blocks/${id}`);
    dispatch(fetchBlockSuccess(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchBlockFail(e));
  }
};

const createBlockStart = () => ({
  type: CREATE_BLOCK_START,
});

const createBlockSuccess = (payload) => ({
  type: CREATE_BLOCK_SUCCESS,
  payload,
});

const createBlockFail = (error) => ({
  type: CREATE_BLOCK_FAIL,
  payload: error,
});

export const createBlock = (block, translations) => async (dispatch) => {
  dispatch(createBlockStart());
  try {
    const result = await post('/blocks', block);
    if (result?.data) {
      dispatch(createBlockSuccess(result?.data));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      return result.data;
    }
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  } catch (e) {
    dispatch(createBlockFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

const updateBlockStart = () => ({
  type: UPDATE_BLOCK_START,
});

const updateBlockSuccess = (payload) => ({
  type: UPDATE_BLOCK_SUCCESS,
  payload,
});

export const updateBlocksPosition = (payload) => ({
  type: UPDATE_BLOCKS_POSITION,
  payload,
});

const updateBlockFail = (error) => ({
  type: UPDATE_BLOCK_FAIL,
  payload: error,
});

export const updateBlock = (block, lang, translations, silent = true) =>
  async (dispatch) => {
    dispatch(updateBlockStart());
    try {
      const result = await put(`/blocks/${block.id}?langCode=${lang}`, block);
      dispatch(updateBlockSuccess(result));
      if (!silent) {
        store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
      }
      return true;
    } catch (e) {
      dispatch(updateBlockFail(e));
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
      return false;
    }
  };

export const changeVisible = (block) => async (dispatch) => {
  try {
    const result = await put(`/blocks/${block.id}/change-visibility`, {});
    if (result?.data === true) {
      dispatch(updateBlockSuccess({ ...block, isVisible: !block.isVisible }));
    }
    return !!result?.data;
  } catch (e) {
    return false;
  }
};

const updateBlockImageStart = () => ({
  type: UPDATE_BLOCK_IMAGE_START,
});

const updateBlockImageSuccess = (payload) => ({
  type: UPDATE_BLOCK_IMAGE_SUCCESS,
  payload,
});

const updateBlockImageFail = (error) => ({
  type: UPDATE_BLOCK_IMAGE_FAIL,
  payload: error,
});

export const updateBlockImage = (blockId, image, translations) => async (dispatch) => {
  dispatch(updateBlockImageStart());
  try {
    await put(`/blocks/image/${blockId}`, image);
    dispatch(updateBlockImageSuccess());
    dispatch(fetchBlocks());
    store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
  } catch (e) {
    dispatch(updateBlockImageFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

const deleteBlockStart = () => ({
  type: DELETE_BLOCK_START,
});

const deleteBlockSuccess = (payload) => ({
  type: DELETE_BLOCK_SUCCESS,
  payload,
});

const deleteBlockFail = (error) => ({
  type: DELETE_BLOCK_FAIL,
  payload: error,
});

export const deleteBlock = (id, translations) => async (dispatch) => {
  dispatch(deleteBlockStart());
  try {
    await remove(`/blocks/${id}`);
    dispatch(deleteBlockSuccess(id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(deleteBlockFail(e));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};
