import { combineReducers } from 'redux';

// ------------------------------------------
// DNK CMS Reducers:
// ------------------------------------------
import pagesReducer from './pages/reducer';
import attributesReducer from './attributes-sets/reducer';
import templatesReducer from './templates/reducer';
import templatePreviewsReducer from './templatePreviews/reducer';
import formsReducer from './forms/reducer';
import productsReducer from './products/reducer';
import guideReducer from './guide/reducer';
import authReducer from './auth/reducer';
import adminsReducer from './admins/reducer';
import customModulesReducer from './custom-modules/reducer';
import workerReducer from './workers/reducer';
import catalogReducer from './catalog/reducer';
import blocksReducer from './blocks/reducer';
import ordersReducer from './orders/reducer';
import orderStatusesReducer from './order-statuses/reducer';
import markersReducer from './markers/reducer';
import settingsReducer from './settings/reducer';
import permissionsReducer from './permissions/reducer';
import authPermissionsReducer from './auth-permissions/reducer';
import journalReducer from './journal/reducer';
import productStatusesReducer from './product-statuses/reducer';
import typesReducer from './types/reducer';
import menusReducer from './menus/reducer';
import blockersReducer from './blockers/reducer';
import usersReducer from './users/reducer';
import userGroupsReducer from './user-groups/reducer';
import usersPermissionReducer from './user-group-permissions/reducer';
import localesReducer from './locales/reducer';
import certsReducer from './certs/reducer';
import tokensReducer from './tokens/reducer';
import paymentsReducer from './payments/reducer';
import eventsReducer from './events/reducer';
import usersAuthProviderReducer from './users-auth-providers/reducer';

const Reducers = combineReducers({
  catalogReducer,
  workerReducer,
  pagesReducer,
  attributesReducer,
  templatesReducer,
  templatePreviewsReducer,
  formsReducer,
  productsReducer,
  guideReducer,
  authReducer,
  adminsReducer,
  customModulesReducer,
  blocksReducer,
  markersReducer,
  settingsReducer,
  permissionsReducer,
  authPermissionsReducer,
  journalReducer,
  productStatusesReducer,
  typesReducer,
  menusReducer,
  blockersReducer,
  usersReducer,
  localesReducer,
  certsReducer,
  tokensReducer,
  userGroupsReducer,
  paymentsReducer,
  usersPermissionReducer,
  eventsReducer,
  ordersReducer,
  orderStatusesReducer,
  usersAuthProviderReducer,
});

export default Reducers;
