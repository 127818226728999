import {
  get, post, put, remove
} from '../../api';
import { store } from 'react-notifications-component';
import {
  COMMON_DATA_DELETE_FAIL,
  COMMON_DATA_DELETE_SUCCESS,
  COMMON_DATA_UPDATE_FAIL,
  COMMON_DATA_UPDATE_SUCCESS
} from '../../constants/notification-settings';

export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

export const FETCH_ORDER_START = 'FETCH_ORDER_START';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAIL = 'FETCH_ORDER_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const UPDATE_ORDER_START = 'UPDATE_ORDER_START';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';

export const DELETE_ORDER_START = 'DELETE_ORDER_START';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL';

const fetchOrdersStart = () => ({
  type: FETCH_ORDERS_START,
});

export const fetchOrdersSuccess = (payload) => ({
  type: FETCH_ORDERS_SUCCESS,
  payload,
});

const fetchOrdersFail = (error) => ({
  type: FETCH_ORDERS_FAIL,
  payload: error,
});

export const fetchOrders = ({ limit, offset, lang }) => async (dispatch) => {
  dispatch(fetchOrdersStart());
  try {
    const result = await get(`/orders-storage?limit=${limit}&offset=${offset}&langCode=${lang}`);
    dispatch(fetchOrdersSuccess(result.data));
  } catch (e) {
    dispatch(fetchOrdersFail(e));
  }
};

const fetchOrderStart = (id) => ({
  type: FETCH_ORDERS_START,
  payload: id,
});

const fetchOrderSuccess = (payload) => ({
  type: FETCH_ORDER_SUCCESS,
  payload,
});

const fetchOrderFail = (error) => ({
  type: FETCH_ORDER_FAIL,
  payload: error,
});

export const fetchOrder = (id) => async (dispatch) => {
  dispatch(fetchOrderStart());
  try {
    const result = await get(`/orders-storage/${id}`);
    dispatch(fetchOrderSuccess(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchOrderFail(e));
  }
};

const createOrderStart = () => ({
  type: CREATE_ORDER_START,
});

export const createOrderSuccess = (payload) => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

const createOrderFail = (error) => ({
  type: CREATE_ORDER_FAIL,
  payload: error,
});

export const createOrder = (data, translations, setNewItemProcess) => async (dispatch) => {
  dispatch(createOrderStart());
  try {
    const result = await post('/orders-storage', data);
    if (result?.data) {
      dispatch(createOrderSuccess(result?.data));
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    } else {
      store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    }
    setNewItemProcess((prev) => prev.filter((elId) => elId !== data.identifier));
  } catch (e) {
    dispatch(createOrderFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
  }
};

const updateOrderStart = () => ({
  type: UPDATE_ORDER_START,
});

const updateOrderSuccess = (payload) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload,
});

const updateOrderFail = (error) => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
});

export const updateOrder = (data, lang, translations, silent = true) => async (dispatch) => {
  dispatch(updateOrderStart());
  try {
    const result = await put(`/orders-storage/${data.id}?langCode=${lang}`, data);
    dispatch(updateOrderSuccess(result));
    if (!silent) {
      store.addNotification(COMMON_DATA_UPDATE_SUCCESS(translations));
    }
    return true;
  } catch (e) {
    dispatch(updateOrderFail(e));
    store.addNotification(COMMON_DATA_UPDATE_FAIL(translations));
    return false;
  }
};

const deleteOrderStart = () => ({
  type: DELETE_ORDER_START,
});

const deleteOrderSuccess = (payload) => ({
  type: DELETE_ORDER_SUCCESS,
  payload,
});

const deleteOrderFail = (error) => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
});

export const deleteOrder = (id, translations, setDeleteProcess) => async (dispatch) => {
  dispatch(deleteOrderStart());
  try {
    await remove(`/orders-storage/${id}`);
    dispatch(deleteOrderSuccess(id));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_SUCCESS(translations));
  } catch (e) {
    dispatch(deleteOrderFail(e));
    setDeleteProcess((prev) => prev.filter((elId) => elId !== id));
    store.addNotification(COMMON_DATA_DELETE_FAIL(translations));
  }
};
