/* eslint camelcase: 0 */
export const DEFAULT_NOTIFICATION_SUCCESS = {
  type: 'default',
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 2500,
    showIcon: true,
  },
};

/**
 * Catalog sections
 */
export const CATALOG_IMPORT_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.catalog_import_success || 'Операция завершилась успешно',
  };
};

export const CATALOG_IMPORT_FAILED = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    message: translations?.catalog_import_error || 'Операция завершилась с ошибкой',
    type: 'danger',
  };
};

export const CATALOG_IMPORT_GROUPS_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.catalog_import_groupes_success || 'Операция завершилась успешно',
  };
};

export const CATALOG_IMPORT_GROUPS_FAIL = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    type: 'danger',
    message: translations?.catalog_import_groups_fail || 'Операция завершилась с ошибкой',
  };
};

export const CATALOG_PAGE_CONFIG_UPDATE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.settings_save_success || 'Операция завершилась успешно',
  };
};

export const CATALOG_PAGE_CONFIG_UPDATE_FAIL = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    type: 'danger',
    message: translations?.settings_save_error || 'Операция завершилась с ошибкой',
  };
};

/**
 * Product sections
 */
export const PRODUCT_SAVE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.product_save_success || 'Операция завершилась успешно',
  };
};

export const PRODUCT_DELETE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.product_delete_success || 'Операция завершилась успешно',
  };
};

export const PRODUCT_COPY_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.product_copy_success || 'Операция завершилась успешно',
  };
};

export const PRODUCT_SELECTED_DELETE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.products_delete_success || 'Операция завершилась успешно',
  };
};

export const PRODUCT_DELETE_FAILED = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    message: translations?.product_delete_error || 'Операция завершилась с ошибкой',
    type: 'danger',
  };
};

export const PRODUCT_COPY_FAILED = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    message: translations?.product_copy_fail || 'Операция завершилась с ошибкой',
    type: 'danger',
  };
};

export const PRODUCT_SELECTED_MOVE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.product_moves_success || 'Операция завершилась успешно',
  };
};

export const PRODUCT_SELECTED_MOVE_FAILED = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    message: translations?.product_moves_error || 'Операция завершилась с ошибкой',
    type: 'danger',
  };
};

export const PRODUCT_SELECTED_DELETE_FAILED = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    message: translations?.products_delete_error || 'Операция завершилась с ошибкой',
    type: 'danger',
  };
};

export const PRODUCT_SAVE_FAILED = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    message: translations?.product_save_error || 'Операция завершилась с ошибкой',
    type: 'danger',
  };
};

/**
 * Индексация товаров
 */
export const PRODUCTS_INDEX_NOTIFICATION_ERROR = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message:
      translations?.products_index_error
      || 'Обработка изменений наборов атрибутов завершилась с ошибкой',
    type: 'danger',
  };
};

/**
 * Индексация данных
 */
export const DATA_INDEX_NOTIFICATION_ERROR = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message:
      translations?.products_index_error
      || 'Индексация данных завершилась с ошибкой',
    type: 'danger',
  };
};

/**
 * Общее
 */
export const COMMON_DATA_UPDATE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.common_success_message || 'Данные успешно сохранены',
  };
};

export const COMMON_DATA_DELETE_SUCCESS = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_successfully || 'Успешно',
    message: translations?.common_delete_success || 'Операция завершилась успешно',
  };
};

export const COMMON_DATA_UPDATE_FAIL = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    type: 'danger',
    message: translations?.common_update_error || 'Операция завершилась с ошибкой',
  };
};

export const COMMON_DATA_DELETE_FAIL = (translations, key) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    title: translations?.formManagement_failed || 'Упсс..',
    type: 'danger',
    message: key ? translations[key] : translations?.common_delete_error || 'Операция завершилась с ошибкой',
  };
};

/**
 * Предупреждения
 */
export const DISK_SPACE_NO_LEFT_WARNING = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    id: 'disk_space_no_left',
    dismiss: {
      duration: 0,
      showIcon: true,
    },
    title: translations?.common_notification_warning_title || 'Упсс..',
    type: 'warning',
    message: translations?.disk_space_no_left_warning || 'Загрузка файлов невозможна - превышен дисковый лимит',
  };
};

export const RECORD_EXCEEDS_WARNING = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    id: 'record_exceeds',
    dismiss: {
      duration: 0,
      showIcon: true,
    },
    title: translations?.common_notification_warning_title || 'Упсс..',
    type: 'warning',
    message: translations?.records_exceeds_warning,
  };
};

export const START_CERT_DELETION_WARNING = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    id: 'cert_deletion',
    title: translations?.common_notification_warning_title || 'Упсс..',
    type: 'warning',
    message: translations?.deletion_certs_warning || 'Запущено удаление сертификатов',
  };
};

export const ADMIN_EXCEEDS_WARNING = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    id: 'admin_exceeds',
    dismiss: {
      duration: 0,
      showIcon: true,
    },
    title: translations?.common_notification_warning_title || 'Упсс..',
    type: 'warning',
    message: translations?.admins_exceeds_warning,
  };
};

export const UPLOAD_IMAGE_SIZE_EXCEEDED_WARNING = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    id: 'files_exceeds',
    dismiss: {
      duration: 0,
      showIcon: true,
    },
    title: translations?.common_notification_warning_title || 'Упсс..',
    type: 'warning',
    message: translations?.upload_image_size_exceeded_warning || 'Объем загружаемых файлов превышает допустимый лимит. Загрузка изображений будет отключена',
  };
};

export const FORBIDDEN_RESOURCE_WARNING = (translations) => {
  return {
    ...DEFAULT_NOTIFICATION_SUCCESS,
    id: 'forbidden_resource_warning',
    dismiss: {
      duration: 0,
      showIcon: true,
    },
    title: translations?.common_notification_warning_title || 'Warning',
    type: 'warning',
    message: translations?.forbidden_resource_warning,
  };
};
